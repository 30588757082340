<template>
    <div id="upcoming-events">
        <div class="inner">
            <section class="match-sched content-section">
                <div class="swiper-holder">
                    <div id="matches"
                        class="upcoming-swiper-container overflow-swipe row row-2 swiper-initialized swiper-horizontal swiper-pointer-events">
                        <ul ref="carousel" class="swiper-wrapper carousel-container">
                            <li ref="items" :key="index" class="swiper-slide" v-for="(match, index) in state.items"
                                style="margin-right: 16px;">
                                <div class="mcard match-sched-card 9347714 market-T product-msports track-events">
                                    <div class="row row-1 row-flex m-b-16">
                                        <div class="col col-1">

                                            <span class="odds-league" style="display: inline-block;">{{
                                match.divisionName }}</span>
                                            <span class="odds-date">{{
                                convertToTimeZoneAndFormat(match.eventAt) }}</span>
                                            <span class="tag sm red">{{ $t('hot') }}</span>
                                        </div>
                                    </div>
                                    <div class="row row-1 g-8 left">
                                        <div class="font-correct-score">
                                            
                                            {{ $t('total_volume') }}
                                        </div>

                                    </div>
                                    <div class="volume-text correct-score-card">
                                        <img :src="match.homeTeamImage">
                                        {{ match.animatedVolume }}
                                        <img :src="match.awayTeamImage">
                                    </div>
                                    <div v-if="globalState.isChinese" class="odds-fig row row-2 row-flex FTHDP d-flex">
                                        <span>{{ match.chineseHomeTeamName }} &nbsp;</span> VS
                                        <span> &nbsp;{{ match.chineseAwayTeamName }}</span>
                                    </div>

                                    <div v-else class="odds-fig row row-2 row-flex FTHDP d-flex">
                                        <span>{{ match.homeTeamName }} &nbsp;</span> VS
                                        <span> &nbsp;{{ match.awayTeamName }}</span>
                                    </div>
                                    <div class="justify-space-between d-flex">
                                        <span class="font-correct-score">{{ $t('correct_score') }} {{
                                match.hotEventOddName }}</span>
                                        <button class="button button-primary bet-button" @click="bet(match)">
                                            {{ $t('bet_now') }}
                                        </button>
                                    </div>
                                </div>
                            </li>

                        </ul>
                        <div class="odds-button-prev nav-arrows xl dark left" v-if="state.currentIndex > 0"
                            tabindex="-1" role="button" aria-label="Previous slide" aria-disabled="true"
                            @click="prevItem">
                        </div>
                        <div class="odds-button-next nav-arrows xl dark right" tabindex="0"
                            v-if="state.items.length > 4" role="button" aria-label="Next slide" aria-disabled="false"
                            @click="nextItem">
                        </div>
                        <span class="swiper-notification" aria-live="assertive" aria-atomic="true">
                        </span>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script setup>
import { inject, ref, reactive, computed, onMounted, defineEmits, onUnmounted } from 'vue';
import apiServices from '../services/apiServices';
import { convertToTimeZoneAndFormat, formatNumber } from '../services/generalFunction';
import { globalState } from '../store/globalState';
import { eventBus } from '../services/eventBus';
import { notificationSymbol } from '../tools/symbol';


const notify = inject(notificationSymbol);
// Reactive state
const state = reactive({
    items: [],
    currentIndex: 0,
});

const emit = defineEmits(['hasItem', 'empty']);

// DOM element references
const carousel = ref(null);

// Computed properties
const isLastItem = computed(() => state.currentIndex === state.items.length - 1);
const isFirstItem = computed(() => state.currentIndex === 0);
const baseUrl = process.env.VUE_APP_API_BASE_URL;
console.log(baseUrl);
// const baseUrl = process.env.VUE_APP_API_BASE_URL;
const sorting = reactive({
    sortBy: 'eventAt',
    sortOrder: 'desc'
});
// Fetch events on mounted
onMounted(async () => {
    try {
        const params = {
        };
        if (sorting.sortBy) {
            params.sort = sorting.sortBy;
            params.order = sorting.sortOrder;
        }
        const response = await apiServices.getHotEvents(params);
        state.items = response.data.hotEvents;

        if (state.items.length > 0) {
            emit('hasItem');
            state.items = state.items.map(item => {
                const reactiveItem = reactive({
                    ...item,
                    animatedVolume: 0.00,
                });

                animateNumber(reactiveItem.fakeAvailableVolume, 2000, reactiveItem, 'animatedVolume');

                return reactiveItem;
            });
        } else {
            emit('empty');
        }
        if (localStorage.getItem('lang') === 'zh-CN' || localStorage.getItem('lang') === 'zh-TW') {
            globalState.isChinese = true;
        } else {
            globalState.isChinese = false;
        }
        const handleLanguageChange = (newLang) => {
            if (newLang === 'zh-CN' || newLang === 'zh-TW' || localStorage.getItem('lang') === 'zh-CN' || localStorage.getItem('lang') === 'zh-TW') {
                globalState.isChinese = true;
            } else {
                globalState.isChinese = false;
            }
        };

        eventBus.on('languageChanged', handleLanguageChange);

        // Clean up the event listener when the component is unmounted
        onUnmounted(() => {
            eventBus.off('languageChanged', handleLanguageChange);
        });

        state.items.forEach(row => {
                if (row.homeTeamImage) {
                    // Update imageUrl property for each row
                    row.homeTeamImage = `${baseUrl}/image/${row.homeTeamImage}`;
                }

                if (row.awayTeamImage) {
                    // Update imageUrl property for each row
                    row.awayTeamImage = `${baseUrl}/image/${row.awayTeamImage}`;
                }
            });
    } catch (error) {
        // Handle error case
        console.error(error);
    }
});



const bet = (match) => {
    if (!globalState.isAuth) {
        notify("login_first", "warning")
        globalState.isLoginShow = true;
        return
    } else {
        globalState.isShowPlaceBet = true;
        globalState.eventOddsDetailSelect = {
            eventName: match.teamName,
            eventOddsId: match.hotEventOddId,
            kickOffDate: match.eventAt,
            oddsName: match.hotEventOddName,
            profit: match.profit
        }
    }
}
// Methods
function scrollItem(width) {
    if (carousel.value) {
        carousel.value.scrollLeft += width;
    }
}

function nextItem() {
    if (!isLastItem.value) {
        state.currentIndex++;
        const itemWidth = carousel.value.children[state.currentIndex].offsetWidth;
        scrollItem(itemWidth * 2);
    } else {
        // Handle last item case, e.g., reset to first
        state.currentIndex = 0;
        scrollToFirstItem();
    }
}

function prevItem() {
    if (!isFirstItem.value) {
        state.currentIndex--;
        const itemWidth = carousel.value.children[state.currentIndex].offsetWidth;
        scrollItem(-itemWidth * 2);
    } else {
        // Handle first item case, e.g., go to last
        state.currentIndex = state.items.length - 1;
        scrollToLastItem();
    }
}

function scrollToFirstItem() {
    if (carousel.value) {
        carousel.value.scrollLeft = 0;
    }
}

function scrollToLastItem() {
    if (carousel.value) {
        carousel.value.scrollLeft = carousel.value.scrollWidth;
    }
}

function animateNumber(finalNumber, duration, targetObject, targetProperty) {
    let currentNumber = 0;
    const stepTime = 50;
    const totalSteps = duration / stepTime;
    const increment = finalNumber / totalSteps;
    const interval = setInterval(() => {
        currentNumber += increment;
        if (currentNumber >= finalNumber) {
            clearInterval(interval);
            targetObject[targetProperty] = formatNumber(finalNumber);
        } else {
            targetObject[targetProperty] = formatNumber(Math.round(currentNumber));
        }
    }, stepTime);
}

</script>

<style>
.correct-score-card{
    display: flex;
    align-items: center;
    justify-content: space-around;

    >img{
        height: 35px;
    }
}
</style>