<template>
    <div class="input-area input-label-left mb-16 mt-16">

        <div class="input-label " id="addPaymentAccount-bankname">
            {{ $t('bank') }}</div>
        <div class="input-wrapper">
            <div class="select-box">

                <div class="select-box__current" tabindex="1">


                    <div class="select-box__value"
                        v-show="paymentChannelName == option.paymentChannelName.replace(/\s+/g, '')"
                        v-for="option in filteredBanks" :key="option.paymentChannelName.replace(/\s+/g, '')">
                        <input class="select-box__input" type="radio"
                            :id="'filteredPaymentChannels_' + option.paymentChannelName.replace(/\s+/g, '')">
                        <p class="select-box__input-text">{{ option.paymentChannelName }}</p>
                    </div>


                </div>

                <div class="select-box__list">
                    <div class="select-box-list-options-container">


                        <label @click="setPaymentChannel(option.paymentChannelName.replace(/\s+/g, ''))"
                            class="select-box__option" v-for="option in filteredBanks"
                            :key="option.paymentChannelName.replace(/\s+/g, '')"
                            :for="'filteredPaymentChannels_' + option.paymentChannelName.replace(/\s+/g, '')"
                            aria-hidden="true">
                            <div class="option-text">
                                <p class="md ml-8">{{ option.paymentChannelName }}</p>
                            </div>
                        </label>


                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue';
import apiServices from '../../services/apiServices';

export default {
    props: {
        value: Number,
        method: Number,
    },
    setup(props, context) {
        const paymentChannelName = ref('');
        const paymentChannels = ref(null);
        const selectedBank = ref('');
        const searchQuery = ref('');

        const setPaymentChannel = (value) => {
            paymentChannelName.value = value;
            context.emit('payment-channel-update', paymentChannelName.value);
        };

        watch(() => props.value, (newValue) => {
            console.log(newValue)
            paymentChannelName.value = newValue;
            let attempts = 0;
            const intervalId = setInterval(() => {
                const optionElement = document.querySelector(`#filteredPaymentChannels_${newValue}`);
                if (optionElement) {
                    clearInterval(intervalId);

                    optionElement.click();
                } else {
                    attempts++;
                    if (attempts >= 10) {
                        clearInterval(intervalId);
                    }
                }
            }, 300);
        }); 

        watch(() => props.method, () => {
           getPaymentChannels();
        });




        const getPaymentChannels = async () => {
            // Clear the paymentChannels.value at the beginning
            paymentChannels.value = [];

            try {
                if (props.method) {
                    // Proceed with API call only if props.method is available
                    const response = await apiServices.getPaymentChannelAccounts({ paymentMethodId: props.method });

                    // Update paymentChannels.value with the response data
                    paymentChannels.value = response.data.paymentChannelAccounts;
                }
            } catch (error) {
                // Log the error if the API call fails
                console.error('error:', error);
            }
        };


        const filteredBanks = computed(() => {
            if (!paymentChannels.value) return [];

            let result;
            const uniqueNames = new Set(); // To keep track of unique paymentChannelNames

            if (searchQuery.value) {
                result = paymentChannels.value.filter(bank => {
                    const name = bank.name.toLowerCase();
                    const paymentChannelName = bank.paymentChannelName;
                    if (name.includes(searchQuery.value.toLowerCase()) && !uniqueNames.has(paymentChannelName)) {
                        uniqueNames.add(paymentChannelName);
                        return true;
                    }
                    return false;
                });
            } else {
                result = paymentChannels.value.filter(bank => {
                    const paymentChannelName = bank.paymentChannelName;
                    if (!uniqueNames.has(paymentChannelName)) {
                        uniqueNames.add(paymentChannelName);
                        return true;
                    }
                    return false;
                });
            }


            // // Further filter by method if it has a value
            // if (props.method) {
            //     result = result.filter(bank => bank.paymentMethodName === props.method);
            // }

            return result;
        });

        onMounted(() => {
            getPaymentChannels();
        });

        return {
            paymentChannelName,
            paymentChannels,
            selectedBank,
            searchQuery,
            setPaymentChannel,
            filteredBanks
        };
    }
};
</script>