<template>
    <MemberNavBar />

    <div class="content-wrapper">
        <div class="tabbed-links">
            <a @click.prevent="setActiveTab('transactions')" :class="{ 'selected': activeTab === 'transactions' }"
                class="tabbed-links__item history-icon button-icon" href="#">
                <span class="history-icon"></span>
                {{ $t('transaction_history') }}
            </a>
            <a @click.prevent="setActiveTab('statement')" class="tabbed-links__item history-icon button-icon"
                :class="{ 'selected': activeTab === 'statement' }">
                <span class="history-icon"></span>
                {{ $t('statement') }}
            </a>
            <a @click.prevent="setActiveTab('result')" class="tabbed-links__item history-icon button-icon"
                :class="{ 'selected': activeTab === 'result' }">
                <span class="history-icon"></span>
                {{ $t('match_result') }}
            </a>
            <a @click.prevent="setActiveTab('betrecord')" class="tabbed-links__item history-icon button-icon"
                :class="{ 'selected': activeTab === 'betrecord' }">
                <span class="history-icon"></span>
                {{ $t('bet_record') }}
            </a>
        </div>

        <TransactionHistory v-show="activeTab === 'transactions'" />
        <RunningBets v-show="activeTab === 'betrecord'" />
        <MatchResult v-show="activeTab === 'result'" />
        <LatestStatement v-show="activeTab === 'statement'" />
    </div>
</template>
<script setup>
import { onMounted, ref } from 'vue';
import '../assets/css/page/transactionHistory.css'
import MemberNavBar from '../components/MemberNavBar.vue';
import TransactionHistory from '../components/TransactionHistory.vue';
import RunningBets from '../components/RunningBets.vue';
import LatestStatement from '../components/LatestStatement.vue';
import MatchResult from '../components/MatchResult.vue';

const activeTab = ref('transactions'); // Default active tab



const getSavedTab = () => {
    return localStorage.getItem('activeTab'); // Retrieve saved tab from session storage
};

const setActiveTab = (tabName) => {
    activeTab.value = tabName;
    localStorage.setItem('activeTab', tabName); // Save active tab to session storage

};

onMounted(() => {
    const savedTab = getSavedTab();
    if (savedTab) {
        activeTab.value = savedTab;
    }
});
</script>




<style scoped>
.input-area .input .icon.icon-calendar {
    background-color: unset !important;
}
</style>