<template>
    <!-- Paste your HTML here -->
    <div class="bet-popup">
        <div class="modal-overlay modal-login active" id="modal-login" v-if="globalState.isShowPlaceBet">
            <div class="modal-content">
                <div class="modal-background"></div>
                <div class="modal-header bet-header">
                    <div class="modal-close" @click="closePopup"></div>
                </div>

                <div class="modal-body">
                    <div id="bet-content" style="">
                        <div class="modal-body-title bet-form-font">
                            {{ globalState.eventOddsDetailSelect.eventName }} ({{
            convertToTimeZoneAndFormat(globalState.eventOddsDetailSelect.kickOffDate) }})
                        </div>

                        <div class="custom-bet">
                            {{ $t('u_are_betting') }} : <span class="important"> {{
            globalState.eventOddsDetailSelect.oddsName }} </span>
                        </div>
                        <div class="modal-body-content">
                            <form id="bet-form" class="modal-form">

                                <div class="input-area dark mb-4 ">
                                    <div class="input-label" id="loginModal-username">{{ $t('bet_amount') }}</div>
                                    <div class="input-wrapper">
                                        <div class="input has-icon-right ">
                                            <input type="text" class="PrimaryLoginCredValidation" name="betAmount"
                                                id="betAmount" autocomplete="off" @input="calculate"
                                                v-model="betData.amount">
                                            <div class="icon icon-right"></div>
                                        </div>
                                        <div class="input-bottom-text"></div>
                                    </div>
                                    <div class="button-container">
                                        <button type="button" class="button lg xl-tablet button-primary number-button"
                                            id="bet500" @click="setAmount(500, false)">

                                            500
                                        </button>
                                        <button type="button" class="button lg xl-tablet button-primary number-button"
                                            id="bet1000" @click="setAmount(1000, false)">

                                            1000
                                        </button>
                                        <button type="button" class="button lg xl-tablet button-primary number-button"
                                            id="bet5000" @click="setAmount(5000, false)">

                                            5000
                                        </button>
                                        <button type="button" class="button lg xl-tablet button-primary number-button"
                                            id="betAll" @click="setAmount(0, true)">

                                            {{ $t('all') }}
                                        </button>
                                    </div>
                                </div>

                                <div class="input-area dark mb-12">
                                    <div class="input-label" id="loginModal-password">{{ $t('profit_amount') }}</div>
                                    <div class="input-wrapper">
                                        <div class="input has-two-icons-right">
                                            <input type="text" class="PrimaryLoginCredValidation" name="profitAmount"
                                                id="profitAmount" autocomplete="off" v-model="betData.profit" disabled>
                                        </div>
                                        <div class="input-bottom-text"></div>
                                    </div>
                                </div>

                                <div class="place-bet">
                                    <button type="button" class="button lg xl-tablet button-primary" id="bet-button"
                                        @click="bet">

                                        {{ $t('bet') }}
                                    </button>
                                </div>

                            </form>


                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { inject, ref } from 'vue';
import { notificationSymbol } from '../tools/symbol';
import apiServices from '../services/apiServices.js';
import { globalState } from '../store/globalState';


import { convertToTimeZoneAndFormat } from '../services/generalFunction';
// Convert data properties to reactive state
const betData = ref({
    amount: '',
    profit: '',
    eventOddId: 0,
    playerId: 0,
});

const notify = inject(notificationSymbol);
const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
        const context = this;
        const args = arguments;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func.apply(context, args), delay);
    }
};

const bet = debounce(async () => {
    betData.value.playerId = globalState.user.id;
    betData.value.eventOddId = globalState.eventOddsDetailSelect.eventOddsId;
    try {
        const response = await apiServices.createMemberBet(betData.value);
        if (response.status === 200) {
            notify('bet_success', 'success');
            closePopup();
        } else {
            console.error('error:', response);
        }

    } catch (error) {
        notify(error.response.data.message, 'error');
    }

}, 1000);



const closePopup = () => {
    betData.value = {
        amount: '',
        profit: '',
        eventOddId: 0,
        playerId: 0,
    };
    globalState.isShowPlaceBet = false;
};



const formatInput = (value) => {
    // Limiting input to 2 decimal places
    if (typeof value !== 'string') {
        value = String(value);
    }
    const newValue = value.replace(/[^0-9.]/g, '');

// If there are multiple decimal points, keep only the first one
const parts = newValue.split('.');
if (parts.length > 2) {
    return parts[0] + '.' + parts.slice(1).join('');
}

return newValue;
};

const calculate = () => {
    // Formatting bet amount to 2 decimal places
    betData.value.amount = formatInput(betData.value.amount);
    if (betData.value.amount) {
        betData.value.profit = (betData.value.amount * globalState.eventOddsDetailSelect.profit / 100).toFixed(2);
    }
};

const setAmount = async (amount, isAll) => {
    if (amount) {
        betData.value.amount = amount.toFixed(2);
    }

    if (isAll) {
        betData.value.amount = globalState.balance.toFixed(2);
    }

    calculate();
}

</script>
<style scoped>
.bet-form-font {
    color: var(--other-1);
    border-bottom: 1px solid var(--other-1);
    padding-bottom: 10px;
    font-size: 12px;
}

.custom-bet {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--other-1);
    margin-bottom: 10px;
    margin-top: -30px;
}

@media(min-width: 767.5px) {
    .modal-overlay.modal-login .modal-content {
        height: unset !important;
    }

    .bet-form-font {
        font-size: 14px;
    }
}

.important {
    color: var(--system-5);
    font-size: 25px;
    padding: 10px;
}

.bet-header {
    padding: 0 30px;
}

.place-bet {
    display: flex;
    justify-content: center;
    width: 100%;

    >button {
        width: 50%;
    }
}

.number-button {
    color: var(--primary-1);
    background-color: var(--secondary-3);
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 40px !important;
    height: 30px !important;
    border: none !important;
    font-size: 13px !important;
}

.button-container {
    display: flex;
    margin: 10px 0;
    gap: 10px;
    width: 100%;
    justify-content: center;
}
</style>