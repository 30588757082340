<template>
    <NotifyWindow ref="notification" />
    <LoadingScreen v-if="this.$globalState.isLoading" />

    <HeaderNav />
    <HeaderMenu />
    <SecondaryMenu />

    <div class="content" @click="globalState.isShowSideMenu = false">
        <router-view />
    </div>
    <FooterNav />
</template>
<script>
import { onMounted, onBeforeUnmount, onUnmounted, inject } from 'vue';
import HeaderNav from '../components/HeaderNav.vue';
import HeaderMenu from '../components/HeaderMenu.vue';
import SecondaryMenu from '../components/SecondaryMenu.vue';
import FooterNav from '../components/FooterNav.vue';
import LoadingScreen from '../Layout/LoadingScreen.vue';
import NotifyWindow from '../components/NotifyWindow.vue';
import { eventBus } from '../services/eventBus';
import { notificationSymbol } from '../tools/symbol';
import { globalState } from '../store/globalState';
export default {
    name: 'MainLayout',
    components: {
        HeaderNav,
        HeaderMenu,
        SecondaryMenu,
        FooterNav,
        LoadingScreen,
        NotifyWindow
    },
    setup() {
        let lastScrollTop = 0;
        const notify = inject(notificationSymbol);
        const handleScroll = () => {
            const currentScroll = window.pageYOffset || document.documentElement.scrollTop;
            if (currentScroll > lastScrollTop) {
                // Scroll down
                document.querySelector('.secondary-menu').classList.add('hidden');
            } else {
                // Scroll up
                document.querySelector('.secondary-menu').classList.remove('hidden');
            }
            lastScrollTop = currentScroll <= 0 ? 0 : currentScroll; // For Mobile or negative scrolling
        };

        // Add class to body when component mounts
        onMounted(() => {
            updateBodyClass();
            window.addEventListener('resize', updateBodyClass);
            window.addEventListener('scroll', handleScroll);
            document.documentElement.setAttribute('data-theme', 'default');
            eventBus.on('session-expired', onSessionExpired);

        });

        // Clean up: remove class from body and event listener when component unmounts
        onBeforeUnmount(() => {
            window.removeEventListener('resize', updateBodyClass);
            window.removeEventListener('scroll', handleScroll);
        });

        function onSessionExpired() {
            notify('session_expired', 'warning');
        }



        onUnmounted(() => {
            // Cleanup our listener when the component is unmounted
            eventBus.off('session-expired', onSessionExpired);
        });

        function updateBodyClass() {
            if (window.matchMedia('(max-width: 767px)').matches) {
                document.body.classList.add('device-mobile');
                document.body.classList.remove('device-desktop');
            } else {
                document.body.classList.add('device-desktop');
                document.body.classList.remove('device-mobile');
            }
        }

        return {globalState};
    }
};
</script>
