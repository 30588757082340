<template>
    <footer class="d-flex background-color-secondary-1 justify-content-center overflow-hidden full-width">
        <div class="footer-content full-width">
            <div
                class="footer-sponsors relative d-flex flex-column align-items-center align-items-flex-start-tablet py-32 px-16 pt-32-tablet py-40-desktop px-24-desktop">
                <div class="partnerships-container full-width">
                    <div class="d-flex flex-row justify-content-center mb-32 mb-40-desktop">
                        <span class="h6 h5-tablet uppercase color-primary-mode-1">
                            A PROUD PARTNER OF
                        </span>

                    </div>
                    <div class="partnerships-list partnerships-list-5 row-gap-32">
                        <div class="partnership d-flex flex-column align-items-center px-4" data-url="/la-liga">
                            <img src="../assets/image/footer/partnership-laliga.svg"
                                class="partner-image h-28 h-56-desktop mb-16 mb-24-desktop default-mode">


                            <img src="../assets/image/footer/partnership-laliga-dark.svg"
                                class="partner-image h-28 h-56-desktop mb-16 mb-24-desktop dark-mode">


                            <span
                                class="bold text xxs xs-tablet md-desktop mb-8 color-primary-mode-1 text-align-center">
                                Official Regional Partner
                            </span>


                            <span class="text xxs sm-desktop color-primary-mode-1 text-align-center">
                                2020 - 2024
                            </span>

                        </div>


                        <div class="partnership d-flex flex-column align-items-center px-4" data-url="/partnership">



                            <img src="../assets/image/footer/partnership-bwf.svg"
                                class="partner-image h-28 h-56-desktop mb-16 mb-24-desktop">


                            <span
                                class="bold text xxs xs-tablet md-desktop mb-8 color-primary-mode-1 text-align-center">
                                Official Betting Partner
                            </span>


                            <span class="text xxs sm-desktop color-primary-mode-1 text-align-center">
                                2022 - 2024
                            </span>

                        </div>


                        <div class="partnership d-flex flex-column align-items-center px-4" data-url="/ac-milan">



                            <img src="../assets/image/footer/partnership-acmilan.svg"
                                class="partner-image h-28 h-56-desktop mb-16 mb-24-desktop">


                            <span
                                class="bold text xxs xs-tablet md-desktop mb-8 color-primary-mode-1 text-align-center">
                                Official Casino Partner
                            </span>


                            <span class="text xxs sm-desktop color-primary-mode-1 text-align-center">
                                2022 - 2024
                            </span>

                        </div>


                        <div class="partnership d-flex flex-column align-items-center px-4" data-url="/maria-ozawa">



                            <img src="../assets/image/footer/partnership-mariaozawa.svg"
                                class="partner-image h-28 h-56-desktop mb-16 mb-24-desktop default-mode">


                            <img src="../assets/image/footer/partnership-mariaozawa-dark.svg"
                                class="partner-image h-28 h-56-desktop mb-16 mb-24-desktop dark-mode">


                            <span
                                class="bold text xxs xs-tablet md-desktop mb-8 color-primary-mode-1 text-align-center">
                                365 Won Celebrity Partner
                            </span>


                            <span class="text xxs sm-desktop color-primary-mode-1 text-align-center">
                                2021 - Present
                            </span>

                        </div>


                        <div class="partnership d-flex flex-column align-items-center px-4" data-url="/manny-pacquiao">



                            <img src="../assets/image/footer/partnership-mp.svg"
                                class="partner-image h-28 h-56-desktop mb-16 mb-24-desktop default-mode">


                            <img src="../assets/image/footer/partnership-mp-dark.svg"
                                class="partner-image h-28 h-56-desktop mb-16 mb-24-desktop dark-mode">


                            <span
                                class="bold text xxs xs-tablet md-desktop mb-8 color-primary-mode-1 text-align-center">
                                365 Won Ambassador
                            </span>


                            <span class="text xxs sm-desktop color-primary-mode-1 text-align-center">
                                2022
                            </span>

                        </div>

                    </div>

                </div>

            </div>


            <div class="footer-mini-banner">




            </div>


            <div
                class="footer-top d-flex flex-column align-items-center align-items-flex-start-tablet py-32 px-16 pt-32-tablet py-40-desktop px-24-desktop">


                <div
                    class="payment-media-container d-flex-tablet align-items-center-tablet full-width-tablet mb-24-tablet">


                    <div
                        class="payment-container d-flex align-items-center flex-column mb-24 align-items-start-tablet mb-0-tablet flex-row-desktop align-items-center-desktop">


                        <div
                            class="payment-title text xs sm-tablet bold color-primary-6 mb-8 my-0-desktop mr-16-desktop ml-0-desktop d-none-desktop">
                            <span class="">
                                Payment Methods
                            </span>

                        </div>
                        <div
                            class="payment-methods d-flex flex-wrap justify-content-center justify-content-flex-start-tablet">


                            <div
                                class="text xs sm-tablet bold color-primary-6 mb-8 my-0-desktop mr-16-desktop ml-0-desktop d-none d-flex-desktop align-items-center">


                                <span class="">
                                    Payment Methods
                                </span>

                            </div>


                            <div class="method-container d-flex align-items-center h-32 mr-16 mr-24-tablet">


                                <img src="../assets/image/footer/payment-methods/jeton.svg"
                                    class="payment-method grey-image h-16">


                                <img src="../assets/image/footer/payment-methods/jeton-color.svg"
                                    class="payment-method color-image h-16 mt-8">

                            </div>


                            <div class="method-container d-flex align-items-center h-32 mr-16 mr-24-tablet">


                                <img src="../assets/image/footer/payment-methods/luxonpay.svg"
                                    class="payment-method grey-image h-20">


                                <img src="../assets/image/footer/payment-methods/luxonpay-color.svg"
                                    class="payment-method color-image h-20 mt-6">

                            </div>


                            <div class="method-container d-flex align-items-center h-32 mr-16 mr-24-tablet">


                                <img src="../assets/image/footer/payment-methods/visa-master.svg"
                                    class="payment-method grey-image h-20">


                                <img src="../assets/image/footer/payment-methods/visa-master-color.svg"
                                    class="payment-method color-image h-20 mt-6">

                            </div>


                            <div class="method-container d-flex align-items-center h-32 mr-16 mr-24-tablet">


                                <img src="../assets/image/footer/payment-methods/tether.svg"
                                    class="payment-method grey-image h-20">


                                <img src="../assets/image/footer/payment-methods/tether-color.svg"
                                    class="payment-method color-image h-20 mt-6">

                            </div>


                            <div class="method-container d-flex align-items-center h-32 mr-16 mr-24-tablet">


                                <img src="../assets/image/footer/payment-methods/bitcoin.svg"
                                    class="payment-method grey-image h-20">


                                <img src="../assets/image/footer/payment-methods/bitcoin-color.svg"
                                    class="payment-method color-image h-20 mt-6">

                            </div>


                            <div class="method-container d-flex align-items-center h-32 mr-16 mr-24-tablet">


                                <img src="../assets/image/footer/payment-methods/ethereum.svg"
                                    class="payment-method grey-image h-24">


                                <img src="../assets/image/footer/payment-methods/ethereum-color.svg"
                                    class="payment-method color-image h-24 mt-4">

                            </div>

                        </div>








                    </div>


                    <div class="ml-auto-tablet">
                        <div class="social-media d-flex mb-24 justify-content-center ml-auto-tablet mb-0-tablet">

                        </div>








                    </div>

                </div>


                <div
                    class="navigation-container d-flex flex-wrap flex-column align-items-center align-items-self-start-tablet full-width-desktop flex-row-desktop gap-24">


                    <div
                        class="navigation-menu d-flex justify-content-center justify-content-flex-start-tablet flex-wrap row-gap-8 column-gap-16 flex-grow">





                        <div class="pointer bold underline color-primary-6 color-other-1-hover white-space-nowrap text sm md-tablet"
                            data-url="/about">



                            <span class="">
                                About 365 Won
                            </span>

                        </div>


                        <div class="pointer bold underline color-primary-6 color-other-1-hover white-space-nowrap text sm md-tablet"
                            data-url="/referafriend">



                            <span class="">
                                Refer Friend
                            </span>

                        </div>


                        <div class="pointer bold underline color-primary-6 color-other-1-hover white-space-nowrap text sm md-tablet new-window"
                            data-url="/vip">



                            <span class="">
                                VIP
                            </span>

                        </div>


                        <div class="pointer bold underline color-primary-6 color-other-1-hover white-space-nowrap text sm md-tablet new-window"
                            data-url="https://www.m88globalpartners.com/">



                            <span class="">
                                Affiliates
                            </span>

                        </div>


                        <div class="pointer bold underline color-primary-6 color-other-1-hover white-space-nowrap text sm md-tablet new-window"
                            data-url="/cashier/bankingoptions" data-window-width="800" data-window-height="800">



                            <span class="">
                                Banking Options
                            </span>

                        </div>


                        <div id="home-footer-responsible-gaming"
                            class="pointer bold underline color-primary-6 color-other-1-hover white-space-nowrap text sm md-tablet new-window"
                            data-url="https://help.m88.com/responsible-gaming/responsibleGaming.en-SA.asp"
                            data-window-width="915" data-window-height="1000">



                            <span class="">
                                Responsible Gaming
                            </span>

                        </div>


                        <div class="pointer bold underline color-primary-6 color-other-1-hover white-space-nowrap text sm md-tablet"
                            data-url="/contact">



                            <span class="">
                                Contact Us
                            </span>

                        </div>


                        <div id="home-footer-faq"
                            class="pointer bold underline color-primary-6 color-other-1-hover white-space-nowrap text sm md-tablet new-window"
                            data-url="https://help.m88.com/faq/general.en-SA.asp" data-window-width="910"
                            data-window-height="1000">



                            <span class="">
                                Help Center
                            </span>

                        </div>





                    </div>


                    <div class="localized-links d-flex flex-wrap">





                        <div class="pointer bold underline color-primary-6 color-other-1-hover white-space-nowrap text sm md-tablet mr-16"
                            data-url="https://www.jpnm88.com/">



                            <span class="">
                                365 Won Japan
                            </span>

                        </div>


                        <div class="pointer bold underline color-primary-6 color-other-1-hover white-space-nowrap text sm md-tablet mr-16"
                            data-url="https://www.momoidan.org/">



                            <span class="">
                                365 Won China
                            </span>

                        </div>


                        <div class="pointer bold underline color-primary-6 color-other-1-hover white-space-nowrap text sm md-tablet mr-16"
                            data-url="https://www.m88kor.com/">



                            <span class="">
                                365 Won Korea
                            </span>

                        </div>


                        <div class="pointer bold underline color-primary-6 color-other-1-hover white-space-nowrap text sm md-tablet mr-16"
                            data-url="https://www.indm88.com/">



                            <span class="">
                                365 Won India
                            </span>

                        </div>


                        <div class="pointer bold underline color-primary-6 color-other-1-hover white-space-nowrap text sm md-tablet mr-16"
                            data-url="https://www.aczbm88.com/">



                            <span class="">
                                365 Won Thailand
                            </span>

                        </div>


                        <div class="pointer bold underline color-primary-6 color-other-1-hover white-space-nowrap text sm md-tablet mr-16"
                            data-url="https://www.m88ndy.com/">



                            <span class="">
                                365 Won Vietnam
                            </span>

                        </div>


                        <div class="pointer bold underline color-primary-6 color-other-1-hover white-space-nowrap text sm md-tablet mr-16"
                            data-url="https://www.m88my2.com/">



                            <span class="">
                                365 Won
                            </span>

                        </div>


                        <div class="pointer bold underline color-primary-6 color-other-1-hover white-space-nowrap text sm md-tablet"
                            data-url="https://www.m88lots.com/">



                            <span class="">
                                Bhs Indonesia
                            </span>

                        </div>


                    </div>

                </div>

            </div>


            <div
                class="footer-center d-flex relative pt-32 pb-40 flex-column align-items-center px-24-desktop py-40-desktop">


                <div class="sponsorships-container d-flex flex-column" style="    width: 100%;">



                    <div class="footer-provider">


                        <div>
                            <img src="../assets/image/footer/1.png" class="footer-image">

                        </div>


                        <div>
                            <img src="../assets/image/footer/2.png" class="footer-image">
                        </div>


                        <div>

                            <img src="../assets/image/footer/3.png" class="footer-image">


                        </div>


                        <div>
                            <img src="../assets/image/footer/4.png" class="footer-image">

                        </div>

                        <div>
                            <img src="../assets/image/footer/5.png" class="footer-image">
                        </div>

                    </div>




                </div>


                <div class="separation-line-wrapper full-width border-box px-16">


                    <div class="separation-line mt-8 full-width background-color-primary-3">

                    </div>

                </div>


                <div class="sponsorships-container d-flex flex-column mt-24 mt-32-tablet">
                    <div
                        class="previous-sponsorship-title d-flex text bold uppercase xs sm-tablet lg-desktop color-primary-7 mb-24 mb-32-tablet mb-40-desktop">


                        <span class="">
                            Our Previous Sponsorships
                        </span>

                    </div>


                    <div class="previous-sponsorship d-flex flex-row">


                        <div class="sponsor d-flex flex-column align-items-center flex-row-desktop mr-24 mr-40-tablet">


                            <img src="../assets/image/footer/previous-sponsorships/bournemouth.svg"
                                class="sponsor-image mb-8 my-0-desktop mr-8-desktop ml-0-desktop h-32 h-40-tablet">


                            <div
                                class="sponsor-info d-flex-desktop flex-column-desktop align-items-start-desktop gap-2-desktop">


                                <div class="sponsor-name text sm bold d-none color-primary-6-tablet d-block-tablet">


                                    <span class="">
                                        AFC Bournemouth
                                    </span>

                                </div>


                                <div class="sponsorship-years color-primary-6 text sm text-align-center">


                                    <span class="">
                                        2015 - 2020
                                    </span>

                                </div>

                            </div>

                        </div>


                        <div class="sponsor d-flex flex-column align-items-center flex-row-desktop mr-24 mr-40-tablet">


                            <img src="../assets/image/footer/previous-sponsorships/crystal-palace.svg"
                                class="sponsor-image mb-8 my-0-desktop mr-8-desktop ml-0-desktop h-32 h-40-tablet">


                            <div
                                class="sponsor-info d-flex-desktop flex-column-desktop align-items-start-desktop gap-2-desktop">


                                <div class="sponsor-name text sm bold d-none color-primary-6-tablet d-block-tablet">


                                    <span class="">
                                        Crystal Palace
                                    </span>

                                </div>


                                <div class="sponsorship-years color-primary-6 text sm text-align-center">


                                    <span class="">
                                        2015 - 2017
                                    </span>

                                </div>

                            </div>

                        </div>


                        <div class="sponsor d-flex flex-column align-items-center flex-row-desktop mr-24 mr-40-tablet">


                            <img src="../assets/image/footer/previous-sponsorships/manchester-city.svg"
                                class="sponsor-image mb-8 my-0-desktop mr-8-desktop ml-0-desktop h-32 h-40-tablet">


                            <div
                                class="sponsor-info d-flex-desktop flex-column-desktop align-items-start-desktop gap-2-desktop">


                                <div class="sponsor-name text sm bold d-none color-primary-6-tablet d-block-tablet">


                                    <span class="">
                                        Manchester City
                                    </span>

                                </div>


                                <div class="sponsorship-years color-primary-6 text sm text-align-center">


                                    <span class="">
                                        2011 - 2012
                                    </span>

                                </div>

                            </div>

                        </div>


                        <div class="sponsor d-flex flex-column align-items-center flex-row-desktop">


                            <img src="../assets/image/footer/previous-sponsorships/tottenham-hotspur.svg"
                                class="sponsor-image mb-8 my-0-desktop mr-8-desktop ml-0-desktop h-32 h-40-tablet">


                            <div
                                class="sponsor-info d-flex-desktop flex-column-desktop align-items-start-desktop gap-2-desktop">


                                <div class="sponsor-name text sm bold d-none color-primary-6-tablet d-block-tablet">


                                    <span class="">
                                        Tottenham Hotspur
                                    </span>

                                </div>


                                <div class="sponsorship-years color-primary-6 text sm text-align-center">


                                    <span class="">
                                        2006 - 2010
                                    </span>

                                </div>

                            </div>

                        </div>

                    </div>





                </div>

            </div>


            <div class="footer-bottom d-flex flex-column px-16 pt-24 pb-40 px-24-desktop">


                <div
                    class="responsible-gambling d-flex align-items-center flex-column color-primary-6 flex-row-tablet full-width-tablet">
                    <div class="text-content d-flex mb-24">
                        <div class="responsible-gambling-text text sm"> 365 Won is committed to supporting&nbsp; </div>
                        <div id="responsible-gambling"
                            data-url="https://help.m88.com/responsible-gaming/responsibleGaming.en-SA.asp"
                            class="text pointer underline bold sm"> Responsible Gaming </div>
                    </div>
                    <div class="responsible-gambling-partners d-flex mb-24 ml-auto-tablet mr-0-tablet">
                        <div class="partners-element mr-16"><a href="/~/static/images/license/vanuatu.png"
                                target="_blank">
                                <img class="partner" src="../assets/image/footer/responsible-gambling/vanuatu.svg"
                                    alt=""></a> </div>
                        <div class="partners-element mr-16"> <img class="partner"
                                src="../assets/image/footer/responsible-gambling/ga.svg" alt=""> </div>
                        <div class="partners-element mr-16"> <img class="partner"
                                src="../assets/image/footer/responsible-gambling/itech-labs.svg" alt=""> </div>
                        <div id="be-age-limit" class="age-limit mr-16"> <img
                                src="../assets/image/footer/responsible-gambling/eighteen.svg" alt="eighteen"> </div>
                        <div class="copyright-element d-flex align-items-center">
                            <div class="text sm bold">&nbsp;365 Won.com&nbsp;</div>
                            <div class="text sm bold">2024</div>
                        </div>
                    </div>
                </div>



                <div
                    class="terms-info d-flex color-primary-6 flex-column align-items-center text-align-center align-items-flex-start-tablet text-align-start-tablet">
                    <div class="terms_p1 text sm mb-8"> *Terms and conditions apply; please refer to the promotions page
                        for
                        more details. <br>
                        <br>
                        This website is operated by Mountain Breeze Limited, a company registered in the Republic of
                        Vanuatu
                        (Co Reg No.: 12405 with its registered office at Law Partners House, Kumul Highway, Port Vila,
                        Vanuatu, under its Interactive Gaming License – IGL25/2013 issued by the Ministry of Finance and
                        Economic Development on 8 July 2013 for a term of 15 years.<br>
                        <br>
                        365 Won.com uses certain browser cookies to ensure you receive the best gaming experience. By
                        accessing
                        and continuing to use the site you accept the use of these cookies. For information on our use
                        of
                        cookies and how to prevent them please refer to M88´s
                        <div class="link text sm underline bold pointer d-inline-block" id="terms-of-use"
                            data-url="https://help.m88.com/terms-of-use/terms-conditions.en-SA.asp">terms of use.</div>
                    </div>


                </div>


            </div>


        </div>
    </footer>
</template>

<style>
.footer-image {
    max-height: 60px;
    margin: 1em;
}

.footer-provider {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    width: 100%;
}

@media (max-width: 768px) {
    .footer-provider {
        display: flex !important;
        flex-wrap: wrap;
        margin: 0 1em;
    }

    .footer-image {
        max-height: 30px !important;
        margin: 10px !important;
    }

}
</style>
