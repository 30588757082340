<template>
    <div class="dynamic-content">
        <div class="content area ">
            
            <div class="content-wrapper">
                <div class="profile-section-title">{{ $t('commission') }}
                </div>
                <div class="content-box full-width border-box">

                    <div class="rewards-cards">
                        <div class="rewards-cards-content">

                            <div class="card radius-left">
                                <div class="text sm title uppercase title-mobile-view" data-qa-type="message"
                                    data-qa-context="LoyaltyCurrentVipLevel">
                                    {{ $t('ur_membership_level_is') }}
                                </div>
                                <div class="text md uppercase bold text-mobile-view">
                                    {{ globalState.user.membershipName }} </div>
                            </div>

                            <div class="card">
                                <div class="text sm title uppercase title-mobile-view" data-qa-type="message"
                                    data-qa-context="LoyaltyTotalVIPPoints">
                                    {{ $t('total_commission_earned') }}
                                </div>
                                <div id="vipPoints" class="text md uppercase bold text-mobile-view">
                                    {{ $t('currency_code') }} &nbsp;{{ formatNumber(totalBetAmount) }}
                                </div>
                            </div>

                            <div class="card radius-right">
                                <div class="text sm title uppercase title-mobile-view" data-qa-type="message"
                                    data-qa-context="LoyaltyRedeemableCashTitle">
                                    {{ $t('total_earned_profit') }}
                                </div>

                                <div id="vipCashable" class="text md uppercase bold uppercase text-mobile-view">
                                    {{ $t('currency_code') }} &nbsp;
                                    {{ formatNumber(totalProfitAmount) }} </div>

                                <div>
                                    <input type="hidden" id="redemption-rate-raw" value="13">
                                </div>
                            </div>

                        </div>
                    </div>
                    <div id="HistoryFundTransferPlaceholder"
                        class="table-placeholder  align-items-center  mt-32 mr-0-desktop ml-0-desktop"
                        style="overflow-x: auto;">
                        <table class="responsive-table full-width ml-0 mr-0">
                            <thead class="color-primary-1 background-color-other-1">
                                <tr>
                                    <th class="table-header background-color-primary-3 color-other-1 bold p-12"
                                        colspan="10">
                                        {{ $t('commission_transaction') }}

                                    </th>
                                </tr>

                            </thead>
                            <tbody>
                                <tr class="hide-on-mobiledynamic-table-row">
                                    <td class="hide-on-mobile first-column"><strong class="p-12"> {{
                                        $t('aff_login_id') }}</strong></td>
                                    <td class="hide-on-mobile"><strong class="p-12">{{ $t('transaction_date') }}</strong></td>
                                    <td class="hide-on-mobile"><strong class="p-12">{{ $t('match') }}</strong></td>
                                    <td class="hide-on-mobile"><strong class="p-12">{{ $t('comm_type') }}</strong></td>
                                    <td class="hide-on-mobile"><strong class="p-12">{{ $t('commission_rate') }}</strong></td>
                                    <td class="hide-on-mobile"><strong class="p-12">{{ $t('amount') }}</strong></td>
                                </tr>

                                <tr class="dynamic-table-row" v-for="item in items" :key="item.id">
                                    <td class="responsive-td-display auto-height d-flex d-none-desktop flex-column align-items-flex-start background-color-primary-11 justify-content-center p-16"
                                        id="date-time-data" data-title="Date/Time">
                                        <div class="responsive-columns d-flex flex-column full-width">
                                            <div class="flex-row-one d-flex align-items-center justify-content-flex-start">
                                                <span class="light-text color-primary-5">{{ $t('transaction_date') }}</span>
                                                <span class="light-text color-primary-5 hide-on-mobile">{{
                                                    $t('aff_login_id')
                                                }}</span>

                                            </div>
                                            <div
                                                class="flex-row-one-data d-flex align-items-center justify-content-flex-start">
                                                <strong>{{ convertToTimeZoneAndFormat(item.createdAt) }}</strong>
                                                <strong class="hide-on-mobile">{{ item.sourceUsername }}</strong>

                                            </div>
                                            <div
                                                class="flex-row-on-mobile up-to-tablet align-items-center justify-content-flex-start mt-16">
                                                <span class="light-text color-primary-5">{{ $t('match') }}</span>
                                            </div>
                                            <div
                                                class="flex-row-on-mobile up-to-tablet align-items-center justify-content-flex-start">
                                                <strong> {{ item.teamName }}</strong>
                                            </div>

                                            <div
                                                class="flex-row-no-gap d-flex align-items-center justify-content-flex-start mt-16">
                                                <span class="light-text color-primary-5">{{ $t('comm_type') }}</span>
                                                <span class="light-text color-primary-5">{{ $t('commission_rate') }}</span>
                                                <span class="light-text color-primary-5">{{ $t('amount') }}</span>
                                            </div>
                                            <div
                                                class="flex-row-no-gap-data d-flex align-items-center justify-content-flex-start">
                                                <strong>{{ getCommissionTypeLabel(item.commissionTypeId) }}</strong>
                                                <strong>
                                                   {{ item.commissionRate }} %
                                                </strong>
                                                <strong> {{ $t('currency_code') }} &nbsp;{{ formatNumber(item.commissionAmount)
                                                }}</strong>
                                               
                                            </div>
                                        </div>
                                    </td>


                                    <td class="hide-on-mobile mobile-details">
                                        <div class="history-datetime-transactionId-td m-12 number">{{ item.sourceUsername }}</div>
                                    </td>
                                    <td class="hide-on-mobile  hide-in-details" id="date-time-data" data-title="Date/Time">
                                        <div class="m-12"><span class="date"><strong>{{
                                            convertToTimeZoneAndFormat(item.createdAt)
                                        }}</strong></span>
                                        </div>
                                    </td>

                                    <td class="hide-on-mobile mobile-details" data-title="Match">
                                        <div class="history-datetime-transactionId-td m-12 number">{{ item.teamName }}
                                        </div>
                                    </td>
                                    <td class="hide-on-mobile mobile-details" data-title="Score">
                                        <div class="history-datetime-transactionId-td m-12 number">{{ getCommissionTypeLabel(item.commissionTypeId )}}
                                        </div>
                                    </td>
                                    <td class="hide-on-mobile mobile-details" data-title="Profit">
                                        <div class="history-datetime-transactionId-td m-12 number">      {{ item.commissionRate }} %
                                        </div>
                                    </td>
                                    <td class="hide-on-mobile mobile-details" data-title="Amount">
                                        <div class="history-datetime-transactionId-td m-12 number"> {{ $t('currency_code')
                                        }}&nbsp;{{ formatNumber(item.commissionAmount) }}
                                        </div>
                                    </td>

                                  
                                </tr>


                                <tr v-if="items.length === 0">
                                    <td data-title="No records" colspan="107" class="text-align-center display-reverted">
                                        {{ $t('no_results') }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>

        </div>
    </div>
</template>
<script setup>
import { onMounted, ref } from 'vue';
import { globalState } from '../store/globalState';
import '../assets/css/page/fundtransfer.css';
import apiServices from '../services/apiServices';
import { getCommissionTypeLabel } from '../enum/commType';
import { convertToTimeZoneAndFormat, formatNumber } from '../services/generalFunction';

import { EventStatusEnum } from '../enum/eventStatus';
const totalBetAmount = ref(0);
const totalProfitAmount = ref(0);
const items = ref([]);
const bets = ref([]);


const animateNumber = (finalNumber, duration, targetProperty) => {
    let currentNumber = 0;
    const stepTime = 50;
    const totalSteps = duration / stepTime;
    const increment = finalNumber / totalSteps;
    const interval = setInterval(() => {
        currentNumber += increment;
        if (currentNumber >= finalNumber) {
            clearInterval(interval);
            targetProperty.value = finalNumber;
        } else {
            targetProperty.value = Math.round(currentNumber);
        }
    }, stepTime);
};

const calculateTotalBetAmount = () => {
    return items.value.reduce((total, item) => total + parseFloat(item.commissionAmount), 0);
};

const calculateTotalEarnedProfit = () => {
    return bets.value.reduce((total, item) => total + parseFloat(item.profitAmount), 0);
};

onMounted(() => {
    getStatement();

    getStatementProfit();
});

const getStatement = async () => {
    const params = {
        id: globalState.user.id,
    };

    const response = await apiServices.getPlayerCommissionListbyId(params);
    items.value = response.data.playerCommissions;

    const totalBet = calculateTotalBetAmount();

    animateNumber(totalBet, 1000, totalBetAmount);
}

const getStatementProfit = async () => {
    const params = {
        playerUsername: globalState.user.username,
        statusId: EventStatusEnum.Completed
    };

    const response = await apiServices.getMemberBets(params);
    bets.value = response.data.playerBets;

    const totalProfit = calculateTotalEarnedProfit();

    animateNumber(totalProfit, 1000, totalProfitAmount);
}
</script>
<style scoped>
.input-area .input .icon.icon-calendar {
    background-color: unset !important;
}

.content .content-wrapper .responsive-table tbody tr td .date,
.content .content-wrapper .responsive-table tbody tr td .number {
    white-space: nowrap;
}

.table-placeholder {
    display: block !important;
}

.content .content-wrapper .responsive-table tbody tr td {
    white-space: nowrap;
}

.content{
    padding-top: 10px;;
}

.match-sched-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 5px 20px;
    margin-bottom: 20px;
    text-align: center;
}

.match-sched-card span {
    margin: 0;
    font-size: 13px;
    float: left;
    padding-top: 5px;
    margin-left: -8px;
}

.total-amount {
    font-size: 26px;
    float: right;
    padding: 10px;
    margin-top: 1.4em;
}

.card-container {
    justify-content: flex-start;
    gap: 20px;
}

.profile-section-title {
    margin-top: 0.8em;
    margin-bottom: 40px;
}

.profile-section-title:after {
    content: "";
    background-color: var(--secondary-3);
    display: flex;
    width: 24px;
    height: 2px;
    margin-top: var(--spacing-12);
    z-index: 9;
}

.content .content-wrapper .content-box,
::v-deep(.select-box__input-text) {
    background-color: var(--other-1)  !important; 
}


</style>