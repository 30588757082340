<template>
    <div class="select-box">

        <div class="select-box__current" tabindex="1">


            <div class="select-box__value" v-for="option in filteredCountryCode" :key="option.id">
                <input class="select-box__input" type="radio" :id="'filteredCountryCode_' + option.id">
                <p class="select-box__input-text">{{ option.name }}</p>
            </div>


        </div>

        <div class="select-box__list">
            <div class="select-box-list-options-container">


                <label @click="setCountryCallingCode(option.id)" class="select-box__option" v-for="option in filteredCountryCode"
                    :key="option.id" :for="'filteredCountryCode_' + option.id" aria-hidden="true">
                    <div class="option-text">
                        <!-- <img class="icon-dropdown icon-left ignore-color"
                        src="/images/icon/flag.en-SA.svg"
                        > -->
                        <p class="md ml-8">{{ option.name }}</p>
                    </div>
                </label>


            </div>
        </div>
    </div>
</template>


<script>
import { ref, computed, onMounted, watch } from 'vue';
import apiServices from '../../services/apiServices';

export default {
    props: {
        value: Number,
    },
    setup(props, context) {
        const countryCallingCodeId = ref(0);
        const items = ref([]);
        const selectedBank = ref('');
        const searchQuery = ref('');

        const setCountryCallingCode = (value) => {
            countryCallingCodeId.value = value;
            context.emit('country-calling-code-update', countryCallingCodeId.value);
        };

        watch(() => props.value, (newValue) => {
            let attempts = 0;
            const intervalId = setInterval(() => {
                const optionElement = document.querySelector(`#filteredCountryCode_${newValue}`);
                if (optionElement) {
                    clearInterval(intervalId);
                    optionElement.click();
                } else {
                    attempts++;
                    if (attempts >= 10) {
                        clearInterval(intervalId);
                    }
                }
            }, 300);
        });





        const getCountryCallingCodeList = async () => {
            try {
                const response = await apiServices.getCountryCallingCode();
                items.value = response.data;

            } catch (error) {
                console.error('error:', error);
            }
        };

        const filteredCountryCode = computed(() => {
            if (!searchQuery.value) return items.value;
            return items.value.filter(item =>
                item.name.toLowerCase().includes(searchQuery.value.toLowerCase()));
        });

        onMounted(() => {
            getCountryCallingCodeList();
        });

        return {
            countryCallingCodeId,
            items,
            selectedBank,
            searchQuery,
            filteredCountryCode,
            setCountryCallingCode,

        };
    }
};
</script>