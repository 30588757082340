<template>
     <div class="input-area d-flex flex-column mr-32-tablet flex-column flex-row-tablet mb-16 mb-0-tablet">
                    <div class="d-flex gap-8 flex-column flex-row-tablet resize">
                        <label class="field-label date-from-label input-label input-label-long" for="IntervalStart"
                            id="transactionHistory-transactionStatus">
                           {{ this.$t('transaction_status')}}:
                        </label>

                        
                        <div class="select-box">

                            <div class="select-box__current" tabindex="1" asp-for="TransactionSelectedType2">
                                <div class="select-box__value">
                                    <input class="select-box__input" type="radio"
                                        id="TransactionStatusDropdown_all" value="all" name="TransactionSelectedType2">
                                    <p class="select-box__input-text"> {{ this.$t('all')}}</p>
                                </div>
                              
                                <div class="select-box__value" v-for="option in transactionStatusOptions" :key="option.id">
                                    <input class="select-box__input" type="radio"
                                        :id="'TransactionStatusDropdown_' + option.label" value="withdrawal"
                                        name="TransactionSelectedType2">
                                    <p class="select-box__input-text">{{ this.$t(option.label.toLowerCase()) }}</p>
                                </div>


                            </div>

                            <div class="select-box__list">
                                <div class="select-box-list-options-container">
                                    <label @click="setTransactionStatus(0)" class="select-box__option" for="TransactionStatusDropdown_all"
                                        aria-hidden="true">
                                        <div class="option-text">
                                            <p class="md ml-8">{{ this.$t('all')}}</p>
                                        </div>
                                    </label>
                                    

                                    <label @click="setTransactionStatus(option.id)" class="select-box__option" v-for="option in transactionStatusOptions"
                                        :key="option.id" :for="'TransactionStatusDropdown_' + option.label"
                                        aria-hidden="true">
                                        <div class="option-text">
                                            <p class="md ml-8">{{ this.$t(option.label.toLowerCase()) }}</p>
                                        </div>
                                    </label>


                                </div>
                            </div>
                        </div>

                    </div>
                </div>
</template>

<script>
import { TransactionStatusEnum } from '../../enum/transactionStatus'
export default {

    data() {
        return {
            transactionStatus : 0
        }
    },
    methods:{
        setTransactionStatus(value){
            this.transactionStatus = value;
        }
    },
    computed: {
        transactionStatusOptions() {
            return Object.entries(TransactionStatusEnum).map(([key, value]) => ({
                id: value,
                label: key,
            }));
        },
    },
    watch: {
        'transactionStatus'(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.$emit('transactionStatusChanged', newVal);
            }
        }
    },
};
</script>