
<template>
  <transition name="slide-in">
    <div v-if="notification.visible" :class="['notification', notification.type]">
      <div class="notification-icon">
        <template v-if="notification.type === 'success'">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor"
            stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-circle">
            <path d="M9 11l3 3L22 4"></path>
            <circle cx="12" cy="12" r="10"></circle>
          </svg>
        </template>
        <template v-if="notification.type === 'error'">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor"
            stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x-circle">
            <circle cx="12" cy="12" r="10"></circle>
            <line x1="15" y1="9" x2="9" y2="15"></line>
            <line x1="9" y1="9" x2="15" y2="15"></line>
          </svg>
        </template>
        <template v-if="notification.type === 'warning'">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor"
            stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-triangle">
            <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3l-8.47-14.14a2 2 0 0 0-3.42 0z"></path>
            <line x1="12" y1="9" x2="12" y2="13"></line>
            <line x1="12" y1="17" x2="12.01" y2="17"></line>
          </svg>
        </template>
      </div>
      <div class="notification-content">
        <h4 class="notification-title">{{ notification.type }}</h4>
        <p class="notification-message">{{ $t(notification.message) }}</p>
      </div>
      <button class="notification-close" @click="hideNotification">✕</button>
    </div>
  </transition>
</template>


<script setup>
import { computed } from 'vue';
import { notificationState, hideNotification } from '../store/notificationStore';


// Computed property for CSS class based on the notification type
const typeClass = computed(() => {
  switch (notificationState.type) {
    case 'success':
      return 'notification-success';
    case 'error':
      return 'notification-error';
    case 'warning':
      return 'notification-warning';
    default:
      return '';
  }
});

notificationState.typeClass = typeClass.value;

const notification = notificationState;
</script>

<style scoped>
.slide-in-enter-active,
.slide-in-leave-active {
  transition: all 0.3s ease;
}

.slide-in-enter-from,
.slide-in-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}

.slide-in-enter-to,
.slide-in-leave-from {
  transform: translateY(0);
  opacity: 1;
}

.notification {
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  min-width: 300px;
  padding: 20px;
  border-radius: 8px;
  color: #484848;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  z-index: 99999999999;
  height: 40px;
  justify-content: center;
  padding-top: 15px;
}

.notification-icon {
  margin-right: 15px;
  flex-shrink: 0;
}

.notification-content {
  flex-grow: 1;
}

.notification-title {
  margin: 0;
  font-size: 1.25rem;
  color: #484848;
  text-transform: capitalize;
}

.notification-message {
  margin: 0;
  font-size: 1rem;
  color: #484848;

}

.notification-close {
  background: none;
  border: none;
  color: #484848;
  cursor: pointer;
  padding-left: 15px;
  font-size: 1rem;
}

.success {
  background-color: #88f5aee8;
}

.error {
  background-color: #f14668;
  color: white!important;
  >.notification-icon{
    color: white;
  }
  >.notification-close{
    color: white;
  }
  > .notification-content .notification-title {
    color: white;
  }

  >.notification-content .notification-message {
    color: white;
  }
}

.notification:has(.error){
  color: white !important;
}

.warning {
  background-color: #ffdd57;
}</style>
