<template>
    <MemberNavBar />

    <div class="content-wrapper">

        <RecentTransaction />

        <div class="content-cashier  d-flex mb-16-desktop">


            <RemainingBalance />

            <div class="cashier-iframe-body ">
                <TransactionNav />

                <div class="content-box full-width border-box mx-0-desktop pr-4">

                    <div class="left-form-layout">

                        <div class="edit-content">


                            <div class="m-t-1-4">
                                <div class="profile-section-title">{{ $t('transfer_step_1') }}</div>


                                <div class="deposit-page-container">
                                   
                                    <div class="input-area input-label-left mb-16">
                                        <div class="input-label" id="edit-username">{{ $t('username') }}</div>
                                        <div class="input-wrapper">
                                            <div class="input" :class="{ 'error': !isValidPlayer, 'valid': isValidPlayer }">
                                                <input type="text" :placeholder="$t('username')" @blur="getPlayerByLoginId" v-model="transData.loginId">
                                                <div class="icon icon-right "></div>
                                            </div>
                                            <div class="input-bottom-text" v-if="!isValidPlayer">
                                                <p class="error">{{ $t('player_not_found') }}</p>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="input-area input-label-left mb-16">
                                        <div class="input-label" id="edit-username">{{ $t('amount') }}</div>
                                        <div class="input-wrapper">
                                            <div class="input" :class="{ 'error': !isValidAmount, 'valid': isValidAmount }">
                                                <input type="text" :placeholder="$t('amount')" @input="checkAmount"
                                                    v-model="transData.amount">
                                                <div class="icon icon-right "></div>
                                            </div>
                                            <div class="input-bottom-text"></div>

                                        </div>
                                    </div>



                                </div>
                                <div class="buttons-wrapper pt-32" style="justify-content: center;">
                                    <button type="button" class="button xl xl-tablet lg-desktop button-primary"
                                        :class="{ 'button-disabled': !isValidAmount || !isValidPlayer }" :disabled="!isValidAmount  || !isValidPlayer"
                                        @click="submitTransction">{{ $t('submit') }}
                                    </button>

                                </div>

                            </div>

                        </div>

                    </div>
                </div>


            </div>
        </div>

    </div>
</template>

<script setup>

import '../assets/css/page/deposit.css'
import { inject, ref, computed } from 'vue';
import { notificationSymbol } from '../tools/symbol';
import MemberNavBar from '../components/MemberNavBar.vue';
import RecentTransaction from '../components/RecentTransaction.vue';
import RemainingBalance from '../components/RemainingBalance.vue';
import TransactionNav from '../components/TransactionNav.vue';
import apiServices from '../services/apiServices';
import { globalState } from '../store/globalState';

const notify = inject(notificationSymbol);

const isValidPlayer = ref(false);
const transData = ref({
    sourcePlayerId: globalState.user.id,
    amount: 0,
    destinationPlayerId: 0,
    loginId: ''
});



const clearForm = () => {

    transData.value = {
    sourcePlayerId: globalState.user.id,
    amount: 0,
    destinationPlayerId: 0,
    loginId: ''
    };
    isValidPlayer.value = false;

};
const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
        const context = this;
        const args = arguments;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func.apply(context, args), delay);
    }
};
const submitTransction = debounce(async () => {
    try {
        const response = await apiServices.createTransferTransaction(transData.value);
        if (response.status === 200) {
            notify('transfer_submitted', 'success');
            clearForm();
        } else {
            console.error('error:', response);
        }

    } catch (error) {
        notify(error.response.data.message, 'error');
    }

}, 1000);



const isValidAmount = computed(() => {
    // Check if the amount is a positive number and not empty
    const amount = parseFloat(transData.value.amount);
    return !isNaN(amount) && amount > 0;
});



function checkAmount(event) {
    const value = event.target.value;

    const positiveNumberRegex = /^[0-9]*\.?[0-9]+$/;

    if (positiveNumberRegex.test(value) || value === '') {
        transData.value.amount = value;
        isValidAmount.value = true;

    } else {
        event.target.value = transData.value.amount;
        isValidAmount.value = false
    }
}

async function getPlayerByLoginId() {
    try {
        const resp = await apiServices.getMembers({ username: transData.value.loginId });

        if(resp.data.count > 0){
           isValidPlayer.value = true;
           transData.value.destinationPlayerId = resp.data.players[0].id;
           return resp.data;
        }else{
            isValidPlayer.value = false;
        }


    } catch (error) {
        notify(error, 'error');
    }
}





</script>
<style scoped>
.profile-section-title {
    text-transform: capitalize;
}

.bank-method-tabber a span {
    margin-top: 0px;
    margin-bottom: 0px;
}

.bank-method-tabber a {

    padding: 10px;
}

.button.button-primary{
    width: 60%;

}
.payment-method:hover {
    text-decoration: unset !important;
    border-bottom: 2px solid #caab72;
}

.bank-method-tabber {
    margin: 0 !important;
}

.deposit-page-container {
    padding: 0px 16px;
}

.m-t-1-4 {
    margin-top: 1.4em;
}

body #view-payment-accounts .payment-account-container {
    border-radius: 4px !important;
    margin: 10px 0;
}

.bank-method-tabber li {
    border-right: 2px solid var(--primary-2);
    padding: 5px 14px 5px 3px;
}

.bank-method-tabber li:last-child,
.bank-method-tabber li:last-of-type {
    border-right: none;
}

.button.button-primary.button-disabled {
    background-color: var(--secondary-5) !important;
}

.input-file-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
}

p.error {
    padding: 0 20px;
    color: var(--system-5);
}

.content .content-wrapper .content-box,
::v-deep(.select-box__input-text) {
    background-color: var(--other-1)  !important; 
}

::v-deep(.select-box) {
    color: var(--primary-3)  !important; 
}
::v-deep(.input-area .input input, .input-area .input select),
::v-deep(.input-label),
.bank-method-tabber .active a span:before,
.bank-method-tabber a,
.profile-section-title {
    color: var(--primary-3)  !important; 
}
</style>