<template>
    <div class="content-wrapper">
      <div class="referral-link-container background-color-primary-2 color-other-1 content-box">
        <div class="left">
          <div class="link-title">
            {{ $t('invite_via_link') }}
          </div>
          <div class="divider"></div>
          <div class="link-container color-other-1 background-color-primary-1">
            <div class="referral-link" ref="referralLink">
                {{ referralLink }}
            </div>
            <div class="icon-hover" @click="copyReferralLink"
            style="mask: url('/images/icon/icon-copy.svg') no-repeat center; mask-size: 20px; min-width: 20px; height: 20px;background-color:var(--other-1)">
            </div>
          </div>
        </div>
        <div class="referral-image-container">
          <img src="/images/referralbg.png" alt="Referral Image" class="referral-img">
        </div>
        
      </div>
      <div class="qr-code-container content-box background-color-other-1">
        <div class="qr-code-wrapper">
            <div class="qr-code">
                <img :src="qrDataUrl" alt="QR Code" />
            </div>
            <div class="action-container">
              <div class="action icon-hover" @click="shareQRCode">
                <div class="" 
                style="mask: url('/images/icon/icon-upload.svg') no-repeat center; mask-size: 20px; width: 20px; height: 20px;background-color:black">
                </div>
                <div class="icon-txt">
                  {{ $t('share_my_code') }}
                </div>
              </div>
              <div class="action icon-hover" @click="downloadQRCode">
                <div class="" 
                style="mask: url('/images/icon/icon-download.svg') no-repeat center; mask-size: 20px; width: 20px; height: 20px;background-color:black">
                </div>
                <div class="icon-txt">
                  {{ $t('save_photo') }}
                </div>
              </div>
              
            </div>
        </div>
        <div class="stats-container">
          <div class="stat-wrapper">
            <div class="stat background-color-primary-2 left">
              <div class="stats-title color-secondary-3">{{ $t('direct_affiliate') }}</div>
              <div class="stats-value color-other-1">{{ directAffiliate }}</div>
            </div>
            <div class="stat background-color-primary-4 right">
              <div class="stats-title color-secondary-3">{{ $t('total_group_size') }}</div>
              <div class="stats-value color-other-1">{{ totalGroupSize }}</div>
            </div>
          </div>
      </div>

      </div>
    </div>
</template>
<script setup>
import { inject,ref, onMounted } from 'vue';
import QRCode from 'qrcode';
import { globalState } from '@/store/globalState';
import apiServices from '../services/apiServices';
import { notificationSymbol } from '../tools/symbol';

const domain = window.location.origin;
const referralLink = domain+'/referral/'+globalState.user.referralCode;
const qrDataUrl = ref(null);
const referralInfo = ref([]);
const directAffiliate = ref(0);
const totalGroupSize = ref(0);
const notify = inject(notificationSymbol);

async function copyReferralLink() {
  if (globalState.user.referralCode){
    await navigator.clipboard.writeText(referralLink);
    notify('referral_link_copied', 'success');
  }
  
}

const fetchPlayerReferralInfo = async () => {
      if (globalState.user.id) {
        try {
          const response = await apiServices.getPlayerReferralInfoById({ id: globalState.user.id });
          referralInfo.value = [response.data]; 
          if (referralInfo.value.length > 0) {
            directAffiliate.value = referralInfo.value[0].directDownline;
            totalGroupSize.value = referralInfo.value[0].groupSize;
          } else {
            console.log('The referralInfo array is empty.');
          }
        } catch (error) {
          console.error(error.response.data.message);
        }
      }
    }

async function generateQRCode() {
  try {
    if (globalState.user.referralCode) {
      const generatedQRDataUrl  = await QRCode.toDataURL(referralLink);
      qrDataUrl.value = generatedQRDataUrl ;
    }
  } catch (error) {
    console.error('Error generating QR code:', error);
  }
}

async function downloadQRCode() {
  try {
    if (qrDataUrl.value) {
      const link = document.createElement('a');
      link.href = qrDataUrl.value;
      link.download = 'referralQR.png';      
      link.click();
    } else {
      console.error('No QR code URL to download');
    }
  } catch (error) {
    console.error('Error downloading QR code:', error);
  }
}

function shareQRCode() {
  navigator.clipboard.writeText(qrDataUrl.value).then(() => {
    notify('qr_url_copied', 'success');
  }).catch((error) => {
    console.error('Error copying QR code URL:', error);
  });
}

onMounted(() => {
    fetchPlayerReferralInfo();
    generateQRCode();
});
</script>
<style scoped>
.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.referral-link-container,.qr-code-container{
  margin-top: 40px;
  margin-bottom: 0px !important;
  width: 100% !important;
  border-radius: 8px !important;
  flex-direction: row !important;
}

.referral-link-container{
  justify-content: space-between;
}

.referral-link-container .divider{
  border: 1px solid var(--secondary-3);
  width: 30px;
  margin-bottom: 15px;
}

.referral-image-container{
  display: flex;
  justify-content: center;
}

.link-title {
  font-size: 1rem;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.link-container {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 15px;
  border-radius: 5px;
  border: 2px dashed;
}

.qr-code-container {
  display: flex;
}


.qr-code-container img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: 200px;
}

.qr-code-container .qr-code-wrapper{
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.qr-code-container .stats-container{
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}


.action-container{
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}


.action {
  display: flex;
}

.stats-container {
  display: flex;
  justify-content: space-around;
}

.stat-wrapper{
  display: flex;
}

.stat {
  padding: 20px;
}

.stat.left {
  border-radius: 8px 0 0 8px;
}

.stat.right {
  border-radius: 0 8px 8px 0;
}

.stats-title {
  margin-bottom: 10px;
  font-size: 14px;
}

.stats-value {
  font-size: 20px;
  font-weight: bold;
}

.icon-txt{
  padding-left: 5px;
}

.referral-link{
  max-width: 100%;
  overflow: hidden;
}

.icon-hover:hover{
  cursor: pointer;
}

@media (max-width: 1023px) {
  .content .content-wrapper {
    width: 96%;
}
  
}
@media (max-width: 768px) {
  .action-container{
    flex-direction: column;
    margin-top: 0px;
  }

  .action{
    flex-grow: 1;
    justify-content: center;
    margin-bottom: 10px;
  }

}

@media (max-width: 600px) {
  .qr-code-container {
    flex-direction: column !important;
  }

  .qr-code-container .qr-code-wrapper{
    width: 100%;
  }

  .action-container{
    flex-direction: row;
    margin-top: 0px;
  }
  
}
@media (max-width: 729px){
  .referral-link-container{
    flex-direction: column !important;
  }
  .referral-image-container img {
    margin-top: 20px;
    margin-bottom: -57px;
  }
}
@media (min-width: 729.5px){
  .referral-image-container img {
    margin-bottom: -54px;
    margin-top: -59px;
    margin-right: -13px;
  }
  .referral-link-container .left{
    max-width: 50%;
  }
}

@media (min-width: 767.5px){
  .referral-image-container img {
    margin-right: -23px;
  }
}
</style>