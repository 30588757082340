<template>
    <div>
        <MemberNavBar />

        <div class="content-wrapper">

            <MemberSecondaryNavBar />

            <div class="content-box full-width border-box" v-show="!isShowAddBank">
                <div id="view-payment-accounts">

                    <div class="profile-section-title">{{ $t('update_bank_details') }}</div>

                    <div class="payment-account-header mb-40">
                        <div class="input-area input-label-left mb-32">
                            <div class="input-label mb-4" id="viewPaymentAccounts-holdername">{{
                                $t('bank_account_holder_name') }}</div>
                            <div class="input-wrapper">
                                <div class="input disabled">
                                    <input type="text" :placeholder="$t('bank_account_holder_name')"
                                        v-model="userData.name">
                                    <div class="icon icon-right icon-lock"></div>
                                </div>
                                <div class="input-bottom-text"></div>
                            </div>
                        </div>

                        <div class="input-area">
                            <a id="add-payment-account-button" class="btn btn-secondary icon-plus" @click="addPlayerBank">
                                <button class="button xl xl-tablet lg-desktop button-primary">{{ $t('add_bank_account')
                                }}</button>
                            </a>
                        </div>

                    </div>

                    <div class="payment-account-container px-16 py-16 px-20-desktop py-20-desktop"
                        v-for="bank in playerBankList" :key="bank.id">

                        <div class="payment-account-info">
                            <div class="text bold xs mb-4 uppercase color-primary-9">
                                {{ $t('bank_name') }}
                            </div>

                            <div class="text bold md mb-4 lg-desktop uppercase color-other-1" placeholder="Bank Name">
                                {{ bank.paymentChannelCode }}
                            </div>

                            <div class="text bold xs uppercase color-primary-9" placeholder="Bank Name">
                                <span>{{ $t('bank_account_number') }}: </span>
                                <span>{{ bank.accountNumber }}</span>
                            </div>

                            <div class="text bold xs uppercase color-primary-9" placeholder="ATM Card Number">
                            </div>

                            <div class="text bold xs uppercase color-primary-9" placeholder="IFSC Code">
                            </div>

                            <div class="text bold xs uppercase color-primary-9" placeholder="Bank Branch">
                            </div>

                            <div class="text bold xs uppercase color-primary-9" placeholder="Transit Number">
                            </div>
                        </div>

                        <div class="buttons-wrapper">
                            <a class="icon-update" @click="editPlayerBank(bank.id)"></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="content-box full-width border-box" v-show="isShowAddBank">
                <form id="add-payment-account-form" @submit="updatePlayerBankInformation" novalidate="novalidate">

                    <div class="color-primary-3 mb-24 area-title bold text-align-left uppercase">{{
                        $t('update_account_details') }}</div>
                    <div class="input-area input-label-left mb-16">
                        <div class="input-label mt-0  mb-4 mb-0-tablet" id="addPaymentAccount-holdername">{{
                            $t('bank_account_holder_name') }}</div>
                        <div class="input-wrapper">
                            <div class="input disabled">
                                <input type="text" :placeholder="$t('bank_account_holder_name')"
                                    v-model="bankData.accountName">
                                <div class="icon icon-right icon-right-two"></div>
                                <div class="icon icon-right icon-lock"></div>
                            </div>
                        </div>
                    </div>
                    <PaymentChannelSelection @payment-channel-update="handlePaymentChannelUpdate"
                        :value="bankData.paymentChannelId" />


                    <div class="input-area input-label-left mb-16">
                        <div class="input-label  mb-4 mb-0-tablet" id="addPaymentAccount-accountnumber">{{
                            $t('bank_account_number') }}</div>
                        <div class="input-wrapper">
                            <div class="input">
                                <input id="AccountNumber" type="text" minlength="1" required="" maxlength="19"
                                    data-val="true" name="AccountNumber" value="" v-model="bankData.accountNumber">
                                <div class="icon icon-right"></div>
                            </div>
                            <div class="input-bottom-text disabled"></div>

                        </div>
                    </div>
                    <div class="input-area input-label-left mb-16">
                        <div class="input-label  mb-4 mb-0-tablet"></div>
                        <div class="input-wrapper">
                            <div class="input-area-radio mb-32">
                                <label class="input-label  auto-width m-0">
                                    <span class="pl-8" id="addPaymentAccont-confirmcorrectinformation">{{
                                        $t('add_bank_verification_msg') }}</span>
                                </label>
                            </div>
                        </div>
                    </div>



                    <div class="buttons-wrapper pt-32" style="justify-content: flex-start;">
                        <button type="button" class="button xl xl-tablet lg-desktop button-primary"
                            :class="{ 'button-disabled': !isFormValid }" :disabled="!isFormValid"
                            @click="updatePlayerBankInformation">{{ $t('save_account') }}
                        </button>
                        <button type="button" class="button xl xl-tablet lg-desktop button lg button-inner-light"
                            @click="isShowAddBank = false; clearForm();">{{ $t('cancel') }}</button>

                    </div>

                </form>



            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, computed, inject } from 'vue';
import { notificationSymbol } from '../tools/symbol';
import MemberNavBar from '../components/MemberNavBar.vue';
import MemberSecondaryNavBar from '../components/MemberSecondaryNavBar.vue';
import PaymentChannelSelection from '../components/DropdownCollection/PaymentChannelSelection.vue';
import { globalState } from '../store/globalState';
import apiServices from '@/services/apiServices';

const isShowAddBank = ref(false);
const isUpdateBank = ref(false);
const playerBankList = ref([]);
const userData = ref({});
const bankData = ref({});

const notify = inject(notificationSymbol);

const handlePaymentChannelUpdate = (paymentChannelId) => {
    bankData.value.paymentChannelId = paymentChannelId;
};



const isFormValid = computed(() => {
    return bankData.value.accountNumber && bankData.value.paymentChannelId;
});

const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
        const context = this;
        const args = arguments;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func.apply(context, args), delay);
    }
};

const updatePlayerBankInformation = debounce(async () => {
    if (!isUpdateBank.value) {
        const response = await apiServices.createPlayerBankAccount({
            playerId: bankData.value.id,
            accountName: bankData.value.accountName,
            accountNumber: bankData.value.accountNumber,
            paymentChannelId: bankData.value.paymentChannelId,
        });
        if (response.status === 200) {
            notify('create_player_bank_success', 'success');
            clearForm();
        } else {
            console.error('error:', response);
        }
    } else {
        const response = await apiServices.updatePlayerBankAccountInformation({
            id: bankData.value.id,
            accountName: bankData.value.accountName,
            accountNumber: bankData.value.accountNumber,
            paymentChannelId: bankData.value.paymentChannelId,
        });
        if (response.status === 200) {
            notify('update_player_bank_success', 'success');
            clearForm();
        } else {
            console.error('error:', response);
        }
    }

}, 1000);


const addPlayerBank = () => {
    isShowAddBank.value = true;
    isUpdateBank.value = false;
};

const editPlayerBank = async (id) => {
    isShowAddBank.value = true;
    isUpdateBank.value = true;
    const bankDetails = await apiServices.getPlayerBankAccountById({ id: id });
    bankData.value = { ...bankDetails.data };
};

const clearForm = () => {
    isShowAddBank.value = false;
    isUpdateBank.value = false;
    bankData.value.paymentChannelId = 0;
    bankData.value.accountNumber = '';
    fetchData();
};

const fetchData = async () => {
    const resp = await apiServices.getMemberById({ id: globalState.user.id });
    userData.value = { ...resp.data };

    bankData.value.id = userData.value.id;
    bankData.value.accountName = userData.value.name;

    const bankDetails = await apiServices.getPlayerBankAccounts({ playerId: globalState.user.id });
    playerBankList.value = bankDetails.data.playerBankAccounts;
};

onMounted(async () => {
    fetchData();
});
</script>
<style scoped>
body .buttons-wrapper {
    justify-content: unset;
}

.content .content-wrapper .content-box,
::v-deep(.select-box__input-text) {
    background-color: var(--other-1)  !important; 
}

::v-deep(.select-box) {
    color: var(--primary-3)  !important; 
}
::v-deep(.input-area .input input, .input-area .input select),
::v-deep(.input-label),
.bank-method-tabber .active a span:before,
.bank-method-tabber a,
.profile-section-title {
    color: var(--primary-3)  !important; 
}
</style>