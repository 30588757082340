export const TransactionType = {
    Deposit: 1,
    Withdrawal: 2,
    GroupCommission: 3,
    ProfitfromBet: 4,
    Adjustment: 5,
    VoidWithdrawal: 6,
    Transfer: 7,
    Bet: 8,
    VoidBet: 9,
    ReferralHold: 10,
    ReferralReturn: 11,
    FirstBetHold: 12,
    FirstBetReturn: 13
};


export function getTransactionTypeLabel(typeId) {
    switch (typeId) {
        case TransactionType.Deposit:
            return 'Deposit';
        case TransactionType.Withdrawal:
            return 'Withdrawal';
        case TransactionType.GroupCommission:
            return 'Group Commission';
        case TransactionType.ProfitfromBet:
            return 'Profit from Bet';
        case TransactionType.Adjustment:
            return 'Adjustment';
        case TransactionType.VoidWithdrawal:
            return 'Void Withdrawal';
        case TransactionType.Transfer:
            return 'Transfer';
        case TransactionType.Bet:
            return 'Bet';
        case TransactionType.VoidBet:
            return 'Void Bet';
        case TransactionType.ReferralHold:
            return 'Referral Hold';
        case TransactionType.ReferralReturn:
            return 'Referral Return';
        case TransactionType.FirstBetHold:
            return 'First Bet Hold';
        case TransactionType.FirstBetReturn:
            return 'First Bet Return';
        default:
            return '-';
    }
}