<template>
    <div class="content-box full-width border-box">
        <form id="TransactionHistoryForm">
            <div class="d-flex mb-16-tablet flex-column flex-row-tablet">
                <DateRangeSelector @dateRangeChanged="handleDateRangeChange" />
            </div>
            <div class="d-flex flex-column flex-row-tablet" style="flex-direction: row-reverse !important;">

                <div class="input-area transaction-input flex-column flex-row-tablet">

                    <div class="input-area nopad">
                        <button type="button" class="button lg button-primary button-validatable" id="submit-button"
                            @click="validateForm">{{ $t('submit') }}</button>
                    </div>
                </div>

            </div>


        </form>
        <div id="HistoryFundTransferPlaceholder"
            class="table-placeholder  align-items-center  mt-32 mr-0-desktop ml-0-desktop" style="overflow-x: auto;">
            <table class="responsive-table full-width ml-0 mr-0">
                <thead class="color-primary-1 background-color-other-1">
                    <tr>
                        <th class="table-header background-color-primary-3 color-other-1 bold p-12" colspan="10">
                            {{ $t('match_result') }}

                        </th>
                    </tr>

                </thead>
                <tbody>
                    <tr class="hide-on-mobiledynamic-table-row">
                        <td class="hide-on-mobile first-column"><strong class="p-12"> {{
                            $t('match') }}</strong></td>
                        <td class="hide-on-mobile"><strong class="p-12">{{ $t('kick_off_date') }}</strong></td>
                        <td class="hide-on-mobile"><strong class="p-12">{{ $t('full_time') }}</strong></td>
                        <td class="hide-on-mobile"><strong class="p-12">{{ $t('half_time') }}</strong></td>
                        <td class="hide-on-mobile"><strong class="p-12">{{ $t('score') }}</strong></td>
                    </tr>
                    <tr class="dynamic-table-row" v-for="item in bets" :key="item.id">
                        <td class="responsive-td-display auto-height d-flex d-none-desktop flex-column align-items-flex-start background-color-primary-11 justify-content-center p-16"
                            id="date-time-data" data-title="Date/Time">
                            <div class="responsive-columns d-flex flex-column full-width">
                                <div class="flex-row-one d-flex align-items-center justify-content-flex-start">
                                    <span class="light-text color-primary-5">{{ $t('kick_off_date') }}</span>
                                    <span class="light-text color-primary-5 hide-on-mobile">{{ $t('transaction_id')
                                    }}</span>

                                </div>
                                <div class="flex-row-one-data d-flex align-items-center justify-content-flex-start">
                                    <strong>{{ convertToTimeZoneAndFormat(item.eventAt) }}</strong>
                                    <strong  v-if="globalState.isChinese" >  {{ item.chineseHomeTeamName }} &nbsp; VS
                                        &nbsp;{{ item.chineseAwayTeamName }}</strong>

                                        <strong v-else  >  {{ item.homeTeamName }} &nbsp; VS
                                        &nbsp;{{ item.awayTeamName }}</strong>
                                  
                                </div>


                                <div class="flex-row-no-gap d-flex align-items-center justify-content-flex-start mt-16">
                                    <span class="light-text color-primary-5">{{ $t('full_time') }}</span>
                                    <span class="light-text color-primary-5">{{ $t('half_time') }}</span>
                                    <span class="light-text color-primary-5">{{ $t('score') }}</span>
                                </div>
                                <div class="flex-row-no-gap-data d-flex align-items-center justify-content-flex-start">
                                    <strong>{{ item.fullScore }}</strong>
                                    <strong> {{ item.halfScore }}</strong>
                                    <strong>
                                        {{ item.score }}
                                    </strong>
                                </div>
                            </div>
                        </td>



                        <td class="hide-on-mobile mobile-details" data-title="Match">
                            <div  v-if="globalState.isChinese" class="history-datetime-transactionId-td m-12 ">
                                        {{ item.chineseHomeTeamName }} &nbsp; VS
                                        &nbsp;{{ item.chineseAwayTeamName }}
                                    </div>

                                    <div v-else class="history-datetime-transactionId-td m-12 ">
                                        {{ item.homeTeamName }} &nbsp; VS
                                        &nbsp;{{ item.awayTeamName }}
                                    </div>
                        </td>
                        <td class="hide-on-mobile  hide-in-details" id="date-time-data" data-title="Date/Time">
                            <div class="m-12"><span class="date"><strong>{{ convertToTimeZoneAndFormat(item.eventAt)
                            }}</strong></span>
                            </div>
                        </td>


                        <td class="hide-on-mobile mobile-details" data-title="Score">
                            <div class="history-datetime-transactionId-td m-12 number">{{ item.fullScore }}
                            </div>
                        </td>
                        <td class="hide-on-mobile mobile-details" data-title="Amount">
                            <div class="history-datetime-transactionId-td m-12 number"> {{ item.halfScore }}
                            </div>
                        </td>

                        <td class="hide-on-mobile mobile-details" data-title="Profit">
                            <div class="history-datetime-transactionId-td m-12 number">{{ item.score }}
                            </div>
                        </td>

                    </tr>


                    <tr v-if="bets.length === 0">
                        <td data-title="No records" colspan="107" class="text-align-center display-reverted">
                            {{ $t('no_results') }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>
</template>
<script setup>
import { ref, reactive , onMounted, onUnmounted} from 'vue';
import DateRangeSelector from './FieldCollection/DateRangeSelector.vue';

import { globalState } from '../store/globalState';
import { eventBus } from '../services/eventBus';
import apiServices from '../services/apiServices';
import { EventStatusEnum } from '../enum/eventStatus';
import { convertToTimeZoneAndFormat } from '../services/generalFunction';
// Define reactive state
const formData = reactive({
    startDate: '',
    endDate: '',
});

const sorting = reactive({
    sortBy: 'eventAt',
    sortOrder: 'desc'
});

const bets = ref([]);

// Define methods
const validateForm = () => {
    getMatchResult();
};

const handleDateRangeChange = (dateRange) => {
    formData.startDate = dateRange.startDate;
    formData.endDate = dateRange.endDate;
};

onMounted(async () => {
    try {

        const handleLanguageChange = (newLang) => {
            console.log(`Language changed to: ${newLang}`);
            if (newLang === 'zh-CN' || newLang === 'zh-TW' || localStorage.getItem('lang') === 'zh-CN' || localStorage.getItem('lang') === 'zh-TW') {
                globalState.isChinese = true;
            } else {
                globalState.isChinese = false;
            }
        };

        eventBus.on('languageChanged', handleLanguageChange);

        // Clean up the event listener when the component is unmounted
        onUnmounted(() => {
            eventBus.off('languageChanged', handleLanguageChange);
        });
    } catch (error) {
        // Handle error case
        console.error(error);
    }
});


const getMatchResult = async () => {
    const params = {
        startEventAt: formData.startDate,
        endEventAt: formData.endDate,
        statusId: EventStatusEnum.Completed
    };

    if (sorting.sortBy) {
        params.sort = sorting.sortBy;
        params.order = sorting.sortOrder;
    }

    const response = await apiServices.getEvents(params);
    bets.value = response.data.events;

    // Fetch event details for each bet
    const eventDetailsPromises = bets.value.map(async (bet) => {
        const eventResponse = await apiServices.getEventById({ id: bet.id });
        return eventResponse.data;
    });

    // Wait for all event detail fetches to complete
    const eventsDetails = await Promise.all(eventDetailsPromises);

    // Combine the bets with their corresponding event details
    bets.value = bets.value.map((bet, index) => {
        const eventDetail = eventsDetails[index];

        // Initialize score
        let score = '';

        // Check if fullScore is available and valid
        if (eventDetail.fullScore && eventDetail.fullScore.includes(':')) {
            const scores = eventDetail.fullScore.split(':').map(Number);
            score = scores.reduce((sum, currentScore) => sum + currentScore, 0).toString();

            if (score == "0") {
                const scoresf = eventDetail.halfScore.split(':').map(Number);
                score = scoresf.reduce((sum, currentScore) => sum + currentScore, 0).toString();
            }
        }

        return {
            ...bet,
            fullScore: eventDetail.fullScore || 'N/A', // Fallback to 'N/A' if not available
            halfScore: eventDetail.halfScore || 'N/A', // Fallback to 'N/A' if not available
            score: score
        };
    });

    console.log(bets.value);
}

</script>
<style scoped>
.content .content-wrapper .responsive-table tbody tr td .date,
.content .content-wrapper .responsive-table tbody tr td .number {
    white-space: nowrap;
}

.table-placeholder {
    display: block !important;
}

.content .content-wrapper .responsive-table tbody tr td {
    white-space: nowrap;
}

.content .content-wrapper .content-box,
::v-deep(.select-box__input-text) {
    background-color: var(--other-1)  !important; 
}

::v-deep(.select-box) {
    color: var(--primary-3)  !important; 
}
::v-deep(.input-area .input input, .input-area .input select),
::v-deep(.input-label),
.bank-method-tabber .active a span:before,
.bank-method-tabber a,
.profile-section-title {
    color: var(--primary-3)  !important; 
}
</style>