<template>
    <div class="carousel-placeholder">
        <div class="carousel-wrapper swiper-initialized swiper-horizontal swiper-pointer-events swiper-backface-hidden"
            data-tracking-name="Carousel">
            <swiper @swiper="onSwiper" :spaceBetween="30" :centeredSlides="true" :rewind="true" :autoplay="{
                delay: 12500,
                disableOnInteraction: true,
            }" :pagination="{ clickable: true, }" :navigation="true" :modules="modules" class="mySwiper">
                <swiper-slide v-for="(image, index) in images" :key="index">
                    <img :src="image" :alt="index">
                </swiper-slide>

                <!-- Pagination -->
                <template v-slot:container-end>
                    <div class="swiper-button">

                        <!-- <a class="button sm md-tablet button-primary" @click="goToNextSlide">Next</a>
    <a class="button sm md-tablet button-primary" @click="goToPrevSlide">Prev</a> -->
                    </div>

                </template>

            </swiper>
        </div>
    </div>

</template>


<script>
import { ref, onMounted, onUnmounted } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

import { eventBus } from '../services/eventBus';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


export default {
    components: {
        Swiper,
        SwiperSlide
    },
    setup() {
        const swiperInstance = ref(null);
        const images = ref([]);

        const lang = ref('');
        const onSwiper = (swiper) => {
            swiperInstance.value = swiper;
        };

        const goToNextSlide = () => {
            if (swiperInstance.value) {
                swiperInstance.value.slideNext();
            }
        };

        const goToPrevSlide = () => {
            if (swiperInstance.value) {
                swiperInstance.value.slidePrev();
            }
        };

        const changeLang = () => {
            lang.value = localStorage.getItem("lang");

            if (lang.value == 'th-TH') {
                const imageContext = require.context(
                    '@/assets/image/slidding-banner/th-TH/',
                    false,
                    /\.(jpe?g|png|gif|svg)$/
                );


                const loadedImages = imageContext.keys().map((key) => {
                    const imageUrl = imageContext(key);
                    return imageUrl;
                });

                images.value = loadedImages;
            }
            else if (lang.value == 'zh-CN' || lang.value == 'zh-TW') {
                const imageContext = require.context(
                    '@/assets/image/slidding-banner/zh-CN/',
                    false,
                    /\.(jpe?g|png|gif|svg)$/
                );


                const loadedImages = imageContext.keys().map((key) => {
                    const imageUrl = imageContext(key);
                    return imageUrl;
                });

                images.value = loadedImages;
            } else {
                const imageContext = require.context(
                    '@/assets/image/slidding-banner/en-US/',
                    false,
                    /\.(jpe?g|png|gif|svg)$/
                );


                const loadedImages = imageContext.keys().map((key) => {
                    const imageUrl = imageContext(key);
                    return imageUrl;
                });

                images.value = loadedImages;
            }
        }
        onMounted(() => {

            changeLang();

            eventBus.on('languageChanged', changeLang);

            // Clean up the event listener when the component is unmounted
            onUnmounted(() => {
                eventBus.off('languageChanged', changeLang);
            });
        });

        return {
            images, modules: [Autoplay, Pagination, Navigation], swiperInstance, onSwiper,
            goToNextSlide,
            goToPrevSlide
        };
    }
};
</script>

<style>
.swiper-slide {
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100% !important;
}

.mySwiper {
    width: 100%;
    background-color: var(--primary-1);
}

.swiper-button {
    display: flex;
}

.swiper-button-prev:after,
.swiper-button-next:after {
    font-family: swiper-icons;
    text-transform: none !important;
    letter-spacing: 0;
    font-variant: initial;
    line-height: 1;
    color: var(--secondary-3);
    font-size: 18px;
    font-weight: 800;
}

.swiper-pagination-bullet-active {
    background-color: var(--secondary-3);
}

@media(max-width: 767.5px) {
    .swiper-slide>img {
        width: 390px !important;
        height: 200px !important;
    }
}
</style>