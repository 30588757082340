<template>
  <div style="width: 100%; display: flex; justify-content: center; position: relative;">
    <video 
      ref="videoPlayer" 
      controls 
      :src="videoSrc" 
      style="width: 100%; max-width: 640px;" 
      muted 
      playsinline 
      loop>
      <!-- Your browser does not support the video tag. -->
    </video>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';

const videoSrc = '/advertisement.mp4';
const videoPlayer = ref(null);

onMounted(() => {
const observer = new IntersectionObserver((entries) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      // Start playing the video when it is visible
      videoPlayer.value.play().catch(e => console.error("Error trying to play the video:", e));
    }
    // Note: There's no else clause to pause the video when it's not visible
  });
}, {
  root: null,
  rootMargin: '0px',
  threshold: 0.5 // Adjust if you want the video to start playing after a different amount of visibility
});

observer.observe(videoPlayer.value);
});
</script>


<style scoped>

.video-player { width: 60%; }
@media (max-width: 768px) { .video-player { width: 100% !important; } }
.overlay { position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.5); z-index: 9999; display: flex; justify-content: center; align-items: center; }
.overlay-content { text-align: center; color: #ffffff; }
.overlay-content h2 { font-size: 24px; margin-bottom: 10px; }
.overlay-content p { font-size: 16px; margin-bottom: 0; }
</style>
