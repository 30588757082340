<template>
    <div class="recent-trnas-layout" id="transactions">
        <input type="checkbox" id="recent-info-details-chck">
        <label class="item-header" for="recent-info-details-chck" id="recentTransactions-title">
            <span class="text bold uppercase md">
                {{ $t('recent_transaction') }}
            </span>
            <div class="acc-caret"></div>
        </label>

        <div class="item-content">

            <div id="RecentTransactionsPlaceholder" style="display: flex" class="">
                <div class="flex-table-wrapper">
                    <table class="responsive-table recent-transactions-table">
                        <thead>
                            <tr class="hide-on-mobile">
                                <th class="hide-on-mobile"><strong>{{ $t('date') }}</strong></th>
                                <th class="hide-on-mobile"><strong>ID</strong></th>
                                <th class="hide-on-mobile"><strong>{{ $t('method') }}</strong></th>
                                <th><strong>{{ $t('type') }}</strong></th>
                                <th><strong>{{ $t('amount') }}</strong></th>
                                <th><strong>{{ $t('status') }}</strong></th>
                            </tr>
                        </thead>

                        <tbody v-if="isLoading">
                            <tr>
                                <td colspan="6"> <!-- Adjust colspan to match the number of columns -->
                                    <div class="table-spinner">
                                        <!-- Spinner Element -->
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-if="transactions && transactions.length > 0 && !isLoading">
                            <tr v-for="transaction in transactions" :key="transaction.id">
                                <td class="responsive-td-display auto-height d-flex d-none-desktop flex-column align-items-flex-start background-color-primary-11 justify-content-center p-16"
                                    id="date-time-data" data-title="Date/Time">
                                    <div class="responsive-columns d-flex flex-column full-width">
                                        <div class="flex-row-one d-flex align-items-center justify-content-flex-start">
                                            <span class="light-text color-primary-5">{{ $t('transaction_date') }}</span>
                                            <span class="light-text color-primary-5 hide-on-mobile">{{
                                                $t('transaction_id') }}</span>
                                            <span class="ml-auto d-flex justify-content-center align-items-center"
                                                :class="getTransactionStatusLabel(transaction.statusId).toLocaleLowerCase()">
                                                {{
                                                    $t(getTransactionStatusLabel(transaction.statusId).toLocaleLowerCase())
                                                }}</span>
                                        </div>
                                        <div class="flex-row-one-data d-flex align-items-center justify-content-flex-start">
                                            <strong>{{ convertToTimeZoneAndFormat(transaction.createdAt) }}</strong>
                                            <strong class="hide-on-mobile">{{ transaction.id }}</strong>

                                        </div>
                                        <div
                                            class="flex-row-on-mobile up-to-tablet align-items-center justify-content-flex-start mt-16">
                                            <span class="light-text color-primary-5">{{ $t('transaction_id') }}</span>
                                        </div>
                                        <div
                                            class="flex-row-on-mobile up-to-tablet align-items-center justify-content-flex-start">
                                            <strong>{{ transaction.id }} </strong>
                                        </div>

                                        <div
                                            class="flex-row-no-gap d-flex align-items-center justify-content-flex-start mt-16">
                                            <span class="light-text color-primary-5">{{ $t('method') }}</span>
                                            <span class="light-text color-primary-5">{{ $t('type') }}</span>
                                            <span class="light-text color-primary-5">{{ $t('amount') }}</span>
                                        </div>
                                        <div
                                            class="flex-row-no-gap-data d-flex align-items-center justify-content-flex-start">
                                            <strong>{{ transaction.paymentMethodName }}</strong>
                                            <strong>{{
                                                $t(getTransactionTypeLabel(transaction.transactionSourceId).toLocaleLowerCase())
                                            }}</strong>
                                            <strong>
                                                {{ formatNumber(transaction.amount) }} {{ $t('currency_code') }}
                                            </strong>
                                        </div>
                                    </div>
                                </td>
                                <td class="text-align-left-mobile" data-title="Date / Time">
                                    <div class="transaction-data-container">
                                        {{ convertToTimeZoneAndFormat(transaction.createdAt) }}</div>
                                </td>
                                <td class="hide-on-mobile" data-title="ID">
                                    <div class="transaction-data-container">{{ transaction.id }}</div>
                                </td>
                                <td class="hide-on-mobile capitalize" data-title="Method">
                                    <div class="transaction-data-container">{{ transaction.paymentMethodName }}</div>
                                </td>
                                <td class="has-trans-info text-align-left-mobile"
                                    trans-id="a02e3d1d-5a87-48d6-a393-7375a795c8e1" data-title="Type">
                                    <div class="transaction-data-container">{{
                                        $t(getTransactionTypeLabel(transaction.transactionSourceId).toLocaleLowerCase()) }}
                                    </div>
                                </td>
                                <td class="has-trans-info text-align-right-mobile float-right-mobile bold-big-mobile recent-transactions-amount"
                                    trans-id="a02e3d1d-5a87-48d6-a393-7375a795c8e1" data-title="Amount">
                                    <div class="transaction-data-container">{{ formatNumber(transaction.amount) }} {{
                                        $t('currency_code') }}</div>
                                </td>
                                <td class="text-align-left-mobile recent-transactions-status"
                                    trans-id="a02e3d1d-5a87-48d6-a393-7375a795c8e1" data-title="Status">
                                    <div class="transaction-data-container wrapper">
                                        <div class="status-text-content">
                                            <span class="transaction-status"
                                                :class="getTransactionStatusLabel(transaction.statusId).toLocaleLowerCase()">{{
                                                    $t(getTransactionStatusLabel(transaction.statusId).toLocaleLowerCase())
                                                }}</span>
                                        </div>

                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="6" class="text-align-center display-reverted">
                                    {{ $t('no_results') }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>


        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, reactive, onUnmounted } from 'vue';
import apiServices from '../services/apiServices';
import { getTransactionTypeLabel } from '../enum/transactionType';
import { getTransactionStatusLabel } from '../enum/transactionStatus';
import { convertToTimeZoneAndFormat, formatNumber } from '../services/generalFunction';

import { globalState } from '../store/globalState';
import { eventBus } from '../services/eventBus';


const isLoading = ref(true);
const transactions = ref([]);
const sorting = reactive({
    sortBy: 'createdAt',
    sortOrder: 'desc'
});

const fetchTransactions = async () => {
    try {
        const params = {
            id: globalState.user.id,
        };
        if (sorting.sortBy) {
            params.sort = sorting.sortBy;
            params.order = sorting.sortOrder;
        }
        const response = await apiServices.getPlayerTransactionListbyId(params);
        transactions.value = response.data.playerTransactions;
        isLoading.value = false;
    } catch (error) {
        console.error('Failed to fetch transactions:', error);
        isLoading.value = false;
    }
};

// Add class to body when component mounts
onMounted(() => {
    fetchTransactions();
    eventBus.on('refresh', handleRefresh);
});

onUnmounted(() => {
    // Cleanup our listener when the component is unmounted
    eventBus.off('refresh', handleRefresh);
});

const handleRefresh = () => {
    fetchTransactions(); // Fetch new transactions
};





</script>


<style scoped>
.content .content-wrapper .responsive-table thead th {

    padding-left: 10px;
}

.rejected {
    color: var(--system-5) !important;
}

.pending {
    color: var(--system-3) !important;
}

.processing {
    color: var(--system-7) !important;
}

.approved {
    color: var(--system-1) !important;
}
</style>

