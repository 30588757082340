<template>
     <div class="input-area d-flex flex-column mr-32-tablet flex-column flex-row-tablet mb-16 mb-0-tablet">
                    <div class="d-flex gap-8 flex-column flex-row-tablet resize">
                        <label class="field-label date-from-label input-label input-label-long" for="IntervalStart"
                            id="transactionHistory-transactiontype">
                           {{ this.$t('transaction_type')}}:
                        </label>

                        <div class="select-box">

                            <div class="select-box__current" tabindex="1" asp-for="TransactionSelectedType">
                                <div class="select-box__value">
                                    <input class="select-box__input selected-option" type="radio"
                                        id="TransactionHistoryDropdown_all" value="all" name="TransactionSelectedType">
                                    <p class="select-box__input-text"> {{ this.$t('all')}}</p>
                                </div>

                                <div class="select-box__value" v-for="option in transactionTypeOptions" :key="option.id">
                                    <input class="select-box__input" type="radio"
                                        :id="'TransactionHistoryDropdown_' + option.id" value="withdrawal"
                                        name="TransactionSelectedType">
                                    <p class="select-box__input-text">{{ this.$t(option.label.toLowerCase()) }}</p>
                                </div>


                            </div>

                            <div class="select-box__list">
                                <div class="select-box-list-options-container">
                                    <label @click="setTransactionType(0)" class="select-box__option" for="TransactionHistoryDropdown_all"
                                        aria-hidden="true">
                                        <div class="option-text">
                                            <p class="md ml-8">{{ this.$t('all')}}</p>
                                        </div>
                                    </label>

                                    <label @click="setTransactionType(option.id)" class="select-box__option" v-for="option in transactionTypeOptions"
                                        :key="option.id" :for="'TransactionHistoryDropdown_' + option.id"
                                        aria-hidden="true">
                                        <div class="option-text">
                                            <p class="md ml-8">{{ this.$t(option.label.toLowerCase()) }}</p>
                                        </div>
                                    </label>


                                </div>
                            </div>
                        </div>

                    </div>
                </div>
</template>

<script>
import { TransactionType } from '../../enum/transactionType.js'
export default {

    data() {
        return {
            transactionType : 0
        }
    },
    methods:{
        setTransactionType(value){
            this.transactionType = value;
        }
    },
    computed: {
        transactionTypeOptions() {
            return Object.entries(TransactionType).map(([key, value]) => ({
                id: value,
                label: key,
            }));
        },
    },
    watch: {
        'transactionType'(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.$emit('transactionTypeChanged', newVal);
            }
        }
    },
};
</script>