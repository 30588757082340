<template>
    <div v-if="!isMobile" class="switch">
      <!-- Desktop version of the toggle -->
      <span class="text">
        <span>{{ isDark ? $t('darkmode') : $t('lightmode') }}</span>
      </span>
      <div class="toggle-wrapper" data-tracking-name="Dark mode">
        <input class="mode-toggle" id="mode-toggle-header" name="Mode Switch" type="checkbox" v-model="isDark" aria-label="Toggle Dark Mode">
        <label class="track-events" data-tracking-name="ON" for="mode-toggle-header"></label>
      </div>
    </div>
  
    <div v-else class="switch">
      <!-- Mobile version of the toggle -->
      <span class="text">
        <span>{{ isDark ? $t('darkmode') : $t('lightmode') }}</span>
      </span>
      <div class="toggle-wrapper" data-tracking-name="Dark mode">
        <input class="mode-toggle" id="mode-toggle-side-menu-header" name="Mode Switch" type="checkbox" v-model="isDark">
        <label class="track-events" data-tracking-name="ON" for="mode-toggle-side-menu-header"></label>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        isDark: false
      };
    },
    props: {
      isMobile: Boolean
    },
    watch: {
      isDark(newValue) {
        // Update the data-theme attribute when isDark changes
        document.documentElement.setAttribute('data-theme', newValue ? 'dark' : 'default');
      }
    }
  };
  </script>
  