<template>
    <!-- Paste your HTML here -->
    <div class="login-popup">
        <div class="modal-overlay modal-login active" id="modal-login" v-if="globalState.isLoginShow">
            <div class="modal-content">
                <div class="modal-background"></div>
                <div class="modal-header login-header">
                    <div class="modal-close" @click="closePopup"></div>
                </div>

                <div class="modal-body">
                    <div id="login-content" style="">
                        <div class="modal-body-title">
                            <img src="../assets/image/logo/mlogo-desktop.svg" class="logo-desktop" alt="home">
                        </div>

                        <div class="modal-body-content">
                            <form id="login-form" class="modal-form">

                                <div class="input-area dark mb-4 ">
                                    <div class="input-label" id="loginModal-username">{{ $t('username') }}</div>
                                    <div class="input-wrapper">
                                        <div class="input has-icon-right ">
                                            <input type="text" class="PrimaryLoginCredValidation" name="loginUsername"
                                                id="loginUsername" autocomplete="off" @keyup.enter="login"
                                                :placeholder="$t('input_username')" v-model="credentials.username">
                                            <div class="icon icon-right"></div>
                                        </div>
                                        <div class="input-bottom-text"></div>
                                    </div>
                                </div>

                                <div class="input-area dark mb-12">
                                    <div class="input-label" id="loginModal-password">{{ $t('password') }}</div>
                                    <div class="input-wrapper">
                                        <div class="input has-two-icons-right">
                                            <input :type="showPassword ? 'text' : 'password'" name="loginPassword"
                                                id="loginPassword" class="PasswordValidation"
                                                :placeholder="$t('input_password')" autocomplete="new-password"
                                                @keyup.enter="login" v-model="credentials.password">
                                            <div class="icon icon-right"></div>
                                            <div :class="showPassword ? 'icon icon-right icon-eye-show toggled' : 'icon icon-right icon-eye-show'"
                                                class="pw-toggle pointer" @click="showPassword = !showPassword"></div>
                                        </div>
                                        <div class="input-bottom-text"></div>
                                    </div>
                                </div>

                                <div class="message-area mt-24"></div>

                                <button type="button" class="button lg xl-tablet button-primary mt-32" id="login-button"
                                    @click="login">
                                    <div class="button-loading-icon left" v-show="isLoginClick">
                                        <span class="loader">
                                            <span class="left"></span>
                                            <span class="right"></span>
                                        </span>
                                    </div>
                                    {{ $t('login') }}
                                </button>
                            </form>

                            <!-- <div class="register-text text-forgot-login-details">
                                <button class="button lg xl-tablet button-inner-dark forgot-login-details-link m-0">
                                    {{ $t('forgot_login_details') }}
                                </button>
                            </div> -->
                            <div class="login-wrapper-additional">
                                <div class="content-inline">
                                    <span>{{ $t('not_yet_member') }}</span>
                                    <a class="underline register-here" @click="showRegister">{{
                                        $t('create_new_account') }}</a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { inject, reactive, toRefs, ref } from 'vue';
import { notificationSymbol } from '../tools/symbol';
import apiService from '../services/apiServices.js'
import { setAuthStatus, globalState } from '../store/globalState';

export default {
    name: 'LoginComponent',
    setup() {
        // Convert data properties to reactive state
        const state = reactive({
            credentials: {
                username: '',
                password: ''
            },
            showPassword: false,
        });

        const isLoginClick = ref(false);
        const notify = inject(notificationSymbol);
        // Convert methods to functions within setup
        const getBalance = async (id) => {
            try {
                const memberWalletRes = await apiService.getMemberWalletById({ id: id });
                globalState.balance = memberWalletRes.data.walletBalance;
            } catch (error) {
                // Handle errors (e.g., showing notification, logging)
                notify('balance_fetch_failed', 'error');
            }
        };

        const getProfile = async () => {
            try {
                const response = await apiService.checkPlayerSession();
                if (response.status === 200) {
                    globalState.user = response.data;
                    globalState.timezoneOffset = response.data.timezoneOffset;
                }
            } catch (error) {
                // Handle errors (e.g., showing notification, logging)
                notify('balance_fetch_failed', 'error');
            }
        };

        const login = async () => {
            try {
                isLoginClick.value = true;
                state.credentials.password = state.credentials.password.trim();
                
                const response = await apiService.login(state.credentials);
                if (response.status == 200) {

                    notify('login_success', 'success');
                    localStorage.setItem('token', response.data.token);
                    setAuthStatus(true);
                    if (localStorage.getItem('referralCode')) {
                        localStorage.removeItem('referralCode');
                    }
                    await getBalance(response.data.id);
                    await getProfile();
                    closePopup();
                } else {
                    // Handle login error
                    notify('login_failed', 'error');

                }

                isLoginClick.value = false;

            } catch (error) {
                notify('login_failed', 'error');
                isLoginClick.value = false;
            }
        };

        const closePopup = () => {
            document.body.style.overflow = '';
            globalState.isLoginShow = false;
        };

        const showRegister = () => {
            document.body.style.overflow = 'hidden';
            globalState.isRegShow = true;
        };

        // You can expose any
        // Expose the state and methods to the template
        return {
            globalState,
            isLoginClick,
            ...toRefs(state),
            login,
            closePopup,
            showRegister,
        };
    }
};
</script>


  