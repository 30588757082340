export const CommissionTypeEnum = {
    Agent: 1,
    Generation: 2,
    Rank: 3,
    Global: 4,
};

export function getCommissionTypeLabel(typeId) {
    switch (typeId) {
        case CommissionTypeEnum.Agent:
            return 'Agent Comm.';
        case CommissionTypeEnum.Generation:
            return 'Generation Comm.';
        case CommissionTypeEnum.Rank:
            return 'Rank Comm.';
        case CommissionTypeEnum.Global:
            return 'Global Comm.';

        default:
            return '-';
    }
}