<script setup>
import { useRouter } from 'vue-router';
import redirectTo from '../services/generalFunction';
import { eventBus } from '../services/eventBus';

const router = useRouter();
const referralCode = router.currentRoute.value.params.ReferralCode;

if(!localStorage.getItem('token')){
    localStorage.setItem('referralCode', referralCode);
    localStorage.setItem('showRegModal', 'true');
}

eventBus.emit('fromAffiliate');
redirectTo("/");

</script>
