<template>
    <MemberNavBar />

    <div class="content-wrapper">

        <MemberSecondaryNavBar />

        <div class="content-box full-width border-box">
            <div class="left-form-layout">
                <div class="edit-content">
                    <div class="profile-section-title">{{ $t('personal_details') }}</div>
                    <form id="edit-player-form">
                        <div class="input-area input-label-left mb-16">
                            <div class="input-label" id="edit-firstname">{{ $t('nickname') }}</div>
                            <div class="input-wrapper">
                                <div class="input disabled">
                                    <input type="text" :placeholder="$t('nickname')" id="FirstName" name="FirstName"
                                        v-model="userData.name">
                                    <div class="icon icon-right icon-lock"></div>
                                </div>
                                <div class="input-bottom-text"></div>

                            </div>
                        </div>

                        <div class="input-area input-label-left mb-16">
                            <div class="input-label" id="edit-dateofbirth">{{ $t('date_of_birth') }}</div>
                            <div class="input-wrapper">
                                <div class="input">
                                    <input type="date" id="DateOfBirth" name="DateOfBirth" v-model="userData.dateOfBirth">
                                    <span class="icon-calendar"></span>
                                    <div class="icon icon-right icon-info pointer" @click="isShowHelp = !isShowHelp"></div>
                                </div>
                                <div class="input-bottom-text pt-8 pl-12 help-toggle" v-if="isShowHelp">{{
                                    $t('date_of_birth_msg') }}</div>
                                <div class="input-bottom-text"></div>

                            </div>
                        </div>

                        <div class="input-area input-label-left mb-16">
                            <div class="input-label" id="edit-username">{{ $t('username') }}</div>
                            <div class="input-wrapper">
                                <div class="input disabled">
                                    <input type="text" :placeholder="$t('username')" v-model="userData.username">
                                    <div class="icon icon-right icon-lock"></div>
                                </div>
                                <div class="input-bottom-text"></div>

                            </div>
                        </div>


                        <div class="input-area input-label-left mb-16">
                            <div class="input-label" id="edit-email">{{ $t('email') }}</div>
                            <div class="input-wrapper">
                                <div class="input disabled">
                                    <input type="text" :placeholder="$t('email')" id="Email" name="Email"
                                        v-model="userData.email">
                                    <div class="icon icon-right icon-lock"></div>
                                </div>
                                <div class="input-bottom-text"></div>

                            </div>
                        </div>

                        <div class="input-area input-label-left mb-16">
                            <div class="input-label" id="edit-mobilephone">{{ $t('mobile') }}</div>
                            <div class="input-wrapper">
                                <div class="input disabled">
                                    <input type="text" :placeholder="$t('mobile')" v-model="userData.contactNumber">
                                    <div class="icon icon-right icon-lock"></div>
                                </div>
                                <div class="input-bottom-text phone-number-validation">
                                </div>

                            </div>
                        </div>

                        <div class="profile-section-title">{{ $t('change_my_password') }}</div>

                        <div class="input-area input-label-left mb-16">
                            <div class="input-label" id="edit-oldpassword">{{ $t('new_password') }}</div>
                            <div class="input-wrapper">
                                <div class="input has-two-icons-right" :class="{ 'error': !isPasswordValid && newPassword, 'valid': isPasswordValid }">
                                    <input :type="showPassword ? 'text' : 'password'" autocomplete="new-password"
                                        :placeholder="$t('password_validation')" v-model="newPassword" id="OldPassword"
                                        name="OldPassword">
                                    <div class="icon icon-right"></div>
                                    <div @click="showPassword = !showPassword"
                                        :class="showPassword ? 'icon icon-right icon-eye-show toggled' : 'icon icon-right icon-eye-show'">
                                    </div>
                                </div>
                                <div class="input-bottom-text disabled"></div>
                            </div>

                        </div>

                        <div class="input-area input-label-left mb-16">
                            <div class="input-label" id="edit-newpassword">{{ $t('confirm_password') }}</div>
                            <div class="input-wrapper">
                                <div class="input has-two-icons-right"   :class="{ 'error': !isPasswordConfirmed && confirmPassword, 'valid': isPasswordConfirmed }">
                                    <input :type="showNewPassword ? 'text' : 'password'" autocomplete="new-password"
                                        :placeholder="$t('confirm_match_new')" v-model="confirmPassword" id="Password"
                                        maxlength="255" name="Password">
                                    <div class="icon icon-right"></div>
                                    <div @click="showNewPassword = !showNewPassword"
                                        :class="showNewPassword ? 'icon icon-right icon-eye-show toggled' : 'icon icon-right icon-eye-show'">
                                    </div>
                                </div>
                                <div class="input-bottom-text disabled"></div>
                            </div>

                        </div>


                        <div class="buttons-wrapper mt-32">

                            <button type="button" @click="updatePlayerInformation"
                                class="button xl xl-tablet xl-desktop button-primary button-validatable"
                                id="submit-button">{{ $t('submit') }}</button>

                        </div>

                    </form>
                </div>

            </div>

        </div>
    </div>
</template>

<script setup>
import { ref, reactive, computed, onMounted, inject } from 'vue';
import { notificationSymbol } from '../tools/symbol';
import apiServices from '@/services/apiServices';
import MemberNavBar from '../components/MemberNavBar.vue';
import MemberSecondaryNavBar from '../components/MemberSecondaryNavBar.vue';
import { globalState } from '../store/globalState';

const notify = inject(notificationSymbol);

const userData = reactive({
    id: globalState.user.id,
   
});

const isShowHelp = ref(false);
const showPassword = ref(false);
const showNewPassword = ref(false);
const newPassword = ref('');
const confirmPassword = ref('');

const isPasswordValid = computed(() => {
    return newPassword.value.length >= 6;
});

const isPasswordConfirmed = computed(() => {
    return newPassword.value === confirmPassword.value;
});

const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
        const context = this;
        const args = arguments;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func.apply(context, args), delay);
    }
};
const updatePlayerInformation = debounce(async () => {
    if (newPassword.value) {
        if(!isPasswordConfirmed.value){
            notify("confirm_match_new", "error");
        }
        if (isPasswordValid.value && isPasswordConfirmed.value) {
            // Call API to update user data
            try {
                const response = await apiServices.updateMemberInformation({
                    password: newPassword.value,
                    id: globalState.user.id,
                    name: userData.name,
                    countryCallingCodeId: userData.countryCallingCodeId,
                    contactNumber: userData.contactNumber,
                    email: userData.email,
                    dateOfBirth: userData.dateOfBirth,
                    membershipId: userData.membershipId,
                    isOptForMarketing: userData.isOptForMarketing,
                });
                if (response.status === 200) {
                    notify("update_member_success", "success");
                    newPassword.value = '';
                    confirmPassword.value ='';

                } else {
                    console.error('error:', response);
                }
            } catch (error) {
                notify("update_member_failed", 'error');
            }
        } else {
            notify("password_validate_failed", "error");
        }
    } else {
        const response = await apiServices.updateMemberInformation(
            {
                id: globalState.user.id,
                name: userData.name,
                countryCallingCodeId: userData.countryCallingCodeId,
                contactNumber: userData.contactNumber,
                email: userData.email,
                dateOfBirth: userData.dateOfBirth,
                membershipId: userData.membershipId,
                isOptForMarketing: userData.isOptForMarketing,
            }
        );
        if (response.status === 200) {
            notify("update_member_success", "success");
        } else {
            notify("update_member_failed", 'error');
        }
    }

}, 1000);



onMounted(async () => {
    const resp = await apiServices.getMemberById({ id: globalState.user.id });
    Object.assign(userData, resp.data);

    if(resp.data.dateOfBirth != null){

        userData.dateOfBirth = resp.data.dateOfBirth.split('T')[0];
    }
});
</script>
<style scoped>
body .buttons-wrapper {
    justify-content: unset;
}

.content .content-wrapper .content-box,
::v-deep(.select-box__input-text) {
    background-color: var(--other-1)  !important; 
}

::v-deep(.select-box) {
    color: var(--primary-3)  !important; 
}
::v-deep(.input-area .input input, .input-area .input select),
::v-deep(.input-label),
.bank-method-tabber .active a span:before,
.bank-method-tabber a,
.profile-section-title {
    color: var(--primary-3)  !important; 
}
</style>