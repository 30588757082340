<template>
    <div class="content-box full-width border-box">
        <div class="content-title color-primary-3 mb-24" style="text-transform: uppercase;">
            {{ $t('transaction_history') }}
        </div>
        <form id="TransactionHistoryForm">
            <div class="d-flex mb-16-tablet flex-column flex-row-tablet">
                <DateRangeSelector @dateRangeChanged="handleDateRangeChange" />
            </div>
            <div class="d-flex flex-column flex-row-tablet">
                <TransactionTypeSelection @transactionTypeChanged="handleTransactionTypeChange" />
                <TransactionStatusSelection @transactionStatusChanged="handleTransactionStatusChange" />

            </div>

            <div class="d-flex flex-column flex-row-tablet" style="flex-direction: row-reverse !important;">

                <div class="input-area transaction-input flex-column flex-row-tablet">

                    <div class="input-area nopad">
                        <button type="button" class="button lg button-primary button-validatable" id="submit-button"
                            @click="validateForm">{{ $t('submit') }}</button>
                    </div>
                </div>

            </div>
        </form>

        <div id="TransactionHistoryPlaceholder"
            class="table-placeholder d-flex align-items-center justify-content-center mt-32 mr-0-desktop ml-0-desktop">
            <table class="responsive-table full-width mr-0 ml-0">
                <thead class="color-primary-1 background-color-other-1">
                    <tr>
                        <th class="table-header background-color-primary-3 color-other-1 bold p-12" colspan="7">
                            {{ $t('transaction_history') }}
                            <span v-show="formData.startDate && formData.endDate">{{ $t('date') }}: {{
                                formData.startDate }} {{ $t('to') }} {{ formData.endDate }}</span>
                        </th>

                    </tr>
                    <tr class="hide-on-mobile mb-16">
                        <th class="hide-on-mobile first-column"><strong class="p-12">No.</strong></th>

                        <th class="hide-on-mobile"><strong>{{ $t('date') }}</strong></th>
                        <th class="hide-on-mobile"><strong>ID</strong></th>
                        <th class="hide-on-mobile"><strong>{{ $t('method') }}</strong></th>
                        <th><strong>{{ $t('type') }}</strong></th>
                        <th><strong>{{ $t('amount') }}</strong></th>
                        <th><strong>{{ $t('status') }}</strong></th>
                    </tr>
                </thead>
                <tbody v-if="items && items.length > 0">
                    <tr v-for="(transaction, index) in items" :key="transaction.id">
                        <td class="responsive-td-display auto-height d-flex d-none-desktop flex-column align-items-flex-start background-color-primary-11 justify-content-center p-16"
                            id="date-time-data" data-title="Date/Time">
                            <div class="responsive-columns d-flex flex-column full-width">
                                <div class="flex-row-one d-flex align-items-center justify-content-flex-start">
                                    <span class="light-text color-primary-5">{{ $t('transaction_date') }}</span>
                                    <span class="light-text color-primary-5 hide-on-mobile">{{ $t('transaction_id')
                                    }}</span>
                                    <span class="ml-auto d-flex justify-content-center align-items-center"
                                        :class="getTransactionStatusLabel(transaction.statusId).toLocaleLowerCase()">
                                        {{ $t(getTransactionStatusLabel(transaction.statusId).toLocaleLowerCase()) }}</span>
                                </div>
                                <div class="flex-row-one-data d-flex align-items-center justify-content-flex-start">
                                    <strong>{{ convertToTimeZoneAndFormat(transaction.createdAt) }}</strong>
                                    <strong class="hide-on-mobile">{{ transaction.id }}</strong>

                                </div>
                                <div
                                    class="flex-row-on-mobile up-to-tablet align-items-center justify-content-flex-start mt-16">
                                    <span class="light-text color-primary-5">{{ $t('transaction_id') }}</span>
                                </div>
                                <div class="flex-row-on-mobile up-to-tablet align-items-center justify-content-flex-start">
                                    <strong>{{ transaction.id }} </strong>
                                </div>

                                <div class="flex-row-no-gap d-flex align-items-center justify-content-flex-start mt-16">
                                    <span class="light-text color-primary-5">{{ $t('method') }}</span>
                                    <span class="light-text color-primary-5">{{ $t('type') }}</span>
                                    <span class="light-text color-primary-5">{{ $t('amount') }}</span>
                                </div>
                                <div class="flex-row-no-gap-data d-flex align-items-center justify-content-flex-start">
                                    <strong>{{ transaction.paymentMethodName }}</strong>
                                    <strong>{{
                                        $t(getTransactionTypeLabel(transaction.transactionSourceId).toLocaleLowerCase())
                                    }}</strong>
                                    <strong>
                                        {{ formatNumber(transaction.amount) }} {{ $t('currency_code') }}
                                    </strong>
                                </div>
                            </div>
                        </td>
                        <td class="text-align-left-mobile" data-title="Date / Time">
                            <div class="transaction-data-container">{{ index + 1 }}
                            </div>
                        </td>
                        <td class="text-align-left-mobile" data-title="Date / Time">
                            <div class="transaction-data-container">{{ convertToTimeZoneAndFormat(transaction.createdAt) }}
                            </div>
                        </td>
                        <td class="hide-on-mobile" data-title="ID">
                            <div class="transaction-data-container">{{ transaction.id }}</div>
                        </td>
                        <td class="hide-on-mobile capitalize" data-title="Method">
                            <div class="transaction-data-container">{{ transaction.paymentMethodName }}</div>
                        </td>
                        <td class="has-trans-info text-align-left-mobile" trans-id="a02e3d1d-5a87-48d6-a393-7375a795c8e1"
                            data-title="Type">
                            <div class="transaction-data-container">{{
                                $t(getTransactionTypeLabel(transaction.transactionSourceId).toLocaleLowerCase()) }}</div>
                        </td>
                        <td class="has-trans-info text-align-right-mobile float-right-mobile bold-big-mobile recent-transactions-amount"
                            trans-id="a02e3d1d-5a87-48d6-a393-7375a795c8e1" data-title="Amount">
                            <div class="transaction-data-container">{{ formatNumber(transaction.amount) }} {{
                                $t('currency_code') }}</div>
                        </td>
                        <td class="text-align-left-mobile recent-transactions-status"
                            trans-id="a02e3d1d-5a87-48d6-a393-7375a795c8e1" data-title="Status">
                            <div class="transaction-data-container wrapper">
                                <div class="status-text-content">
                                    <span class="transaction-status"
                                        :class="getTransactionStatusLabel(transaction.statusId).toLocaleLowerCase()">{{
                                            $t(getTransactionStatusLabel(transaction.statusId).toLocaleLowerCase()) }}</span>
                                </div>

                            </div>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td colspan="7" class="text-align-center display-reverted">
                            {{ $t('no_results') }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>


    </div>
</template>

<script setup>
import { ref, reactive } from 'vue';
import TransactionTypeSelection from './DropdownCollection/TransactionTypeSelection.vue';
import TransactionStatusSelection from './DropdownCollection/TransactionStatusSelection.vue';
import DateRangeSelector from './FieldCollection/DateRangeSelector.vue';
import { globalState } from '../store/globalState';
import apiServices from '../services/apiServices';

import { getTransactionTypeLabel } from '../enum/transactionType';
import { getTransactionStatusLabel } from '../enum/transactionStatus';
import { convertToTimeZoneAndFormat , formatNumber } from '../services/generalFunction';

const formData = reactive({
    startDate: '',
    endDate: '',
    transactionType: '',
    transactionStatus: ''
});

const sorting = reactive({
    sortBy: 'id',
    sortOrder: 'desc'
});

const items = ref([
]);

const validateForm = () => {
    fetchTransactions();
};

const handleTransactionTypeChange = (selectedType) => {
    formData.transactionType = selectedType;
};

const handleTransactionStatusChange = (selectedStatus) => {
    formData.transactionStatus = selectedStatus;
};

const handleDateRangeChange = (dateRange) => {
    formData.startDate = dateRange.startDate;
    formData.endDate = dateRange.endDate;
};

const fetchTransactions = async () => {
    try {
        const params = {
            id: globalState.user.id,
        };
        if (sorting.sortBy) {
            params.sort = sorting.sortBy;
            params.order = sorting.sortOrder;
        }
        const response = await apiServices.getPlayerTransactionListbyId(params);
        let filteredTransactions = response.data.playerTransactions;

        // Filtering logic
        if (formData.transactionType) {
            filteredTransactions = filteredTransactions.filter(item => item.transactionSourceId === formData.transactionType);
        }

        if (formData.transactionStatus) {
            filteredTransactions = filteredTransactions.filter(item => item.statusId === formData.transactionStatus);
        }

        if (formData.startDate) {
            const startDate = new Date(formData.startDate);
            filteredTransactions = filteredTransactions.filter(item => new Date(convertToTimeZoneAndFormat(item.createdAt)) >= startDate);
        }

        if (formData.endDate) {
            const endDate = new Date(formData.endDate);
            filteredTransactions = filteredTransactions.filter(item => new Date(convertToTimeZoneAndFormat(item.createdAt)) <= endDate);
        }

        items.value = filteredTransactions;
    } catch (error) {
        console.error('Failed to fetch transactions:', error);
    }
};

</script>

<style scoped>
.content .content-wrapper .responsive-table thead th {

    padding-left: 10px;
}

.rejected {
    color: var(--system-5) !important;
}

.pending {
    color: var(--system-3) !important;
}

.processing {
    color: var(--system-7) !important;
}

.approved {
    color: var(--system-1) !important;
}

.content .content-wrapper .content-box,
::v-deep(.select-box__input-text) {
    background-color: var(--other-1)  !important; 
}

::v-deep(.select-box) {
    color: var(--primary-3)  !important; 
}
::v-deep(.input-area .input input, .input-area .input select),
::v-deep(.input-label),
.bank-method-tabber .active a span:before,
.bank-method-tabber a,
.profile-section-title {
    color: var(--primary-3)  !important; 
}
</style>
