<template>
    <MemberNavBar />

    <div class="content-wrapper">

        <RecentTransaction />

        <div class="content-cashier  d-flex mb-16-desktop">


            <RemainingBalance />

            <div class="cashier-iframe-body ">
                <TransactionNav />

                <div class="content-box full-width border-box mx-0-desktop pr-4">

                    <div class="left-form-layout">

                        <div class="edit-content">


                            <div class="m-t-1-4">
                                <div class="profile-section-title">{{ $t('withdrawal_step_1') }}</div>


                                <div class="deposit-page-container">
                                    <div class="input-area input-label-left mb-16 mt-16">

                                        <div class="input-label "
                                            id="addPaymentAccount-bankname">
                                            {{ $t('bank_account') }}</div>
                                        <div class="input-wrapper">
                                            <div class="select-box">

                                                <div class="select-box__current" tabindex="1">


                                                    <div class="select-box__value"
                                                        v-show="transData.playerBankAccountId == option.id"
                                                        v-for="option in playerBankAccounts" :key="option.id">
                                                        <input class="select-box__input" type="radio"
                                                            :id="'filteredPlayerBanks_' + option.accountNumber">
                                                        <p class="select-box__input-text">{{ option.accountName }} - {{
                                                            option.accountNumber }}</p>
                                                    </div>


                                                </div>

                                                <div class="select-box__list">
                                                    <div class="select-box-list-options-container">


                                                        <label @click="setPlayerWithdrawalAccount(option)"
                                                            class="select-box__option" v-for="option in playerBankAccounts"
                                                            :key="option.id"
                                                            :for="'filteredPlayerBanks_' + option.accountNumber"
                                                            aria-hidden="true">
                                                            <div class="option-text">
                                                                <p class="md ml-8">{{ option.accountName }} - {{
                                                                    option.accountNumber }}</p>
                                                            </div>
                                                        </label>


                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="input-area input-label-left mb-16">
                                        <div class="input-label" id="edit-username">{{ $t('withdrawal_amount') }} (USD)</div>
                                        <div class="input-wrapper">
                                            <div class="input" :class="{ 'error': !isValidAmount, 'valid': isValidAmount }">
                                                <input type="number" :placeholder="$t('withdrawal_amount')" @input="calculate"
                                                    v-model="transData.usdAmount">
                                                <div class="icon icon-right "></div>
                                            </div>
                                            <div class="input-bottom-text"></div>

                                        </div>
                                    </div>
                                    <div class="input-area input-label-left mb-16">
                                        <div class="input-label" id="edit-username">{{ $t('withdrawal_currency') }}
                                        </div>
                                        <div class="input-wrapper">
                                            <div class="select-box">

                                                <div class="select-box__current" tabindex="1"
                                                    asp-for="TransactionSelectedType2">


                                                    <div class="select-box__value"
                                                        v-for="option in currencyExchangeList" :key="option.id">
                                                        <input class="select-box__input" type="radio"
                                                            :id="'TransactionStatusDropdown_' + option.currencyCode"
                                                            value="withdrawal" name="TransactionSelectedType2">
                                                        <p class="select-box__input-text">{{
                                option.currencyCode}}</p>
                                                    </div>


                                                </div>

                                                <div class="select-box__list">
                                                    <div class="select-box-list-options-container">


                                                        <label @click="setCurrencyExchange(option.toUsdRate, option.id)"
                                                            class="select-box__option"
                                                            v-for="option in currencyExchangeList" :key="option.id"
                                                            :for="'TransactionStatusDropdown_' + option.currencyCode"
                                                            aria-hidden="true">
                                                            <div class="option-text">
                                                                <p class="md ml-8">{{ option.currencyCode}}
                                                                </p>
                                                            </div>
                                                        </label>


                                                    </div>
                                                </div>
                                            </div>



                                        </div>
                                    </div>
                                    <div class="input-area input-label-left mb-16">
                                        <div class="input-label" id="edit-username">{{ $t('withdrawal_amount') }}</div>
                                        <div class="input-wrapper">
                                            <div class="input" >
                                                <input type="text" :placeholder="$t('withdrawal_amount')" 
                                                    v-model="transData.amount" disabled >
                                                <div class="icon icon-right "></div>
                                            </div>
                                            <div class="input-bottom-text"></div>

                                        </div>
                                    </div>


                                </div>
                                <div class="buttons-wrapper pt-32" style="justify-content: center;">
                                    <button type="button" class="button xl xl-tablet lg-desktop button-primary"
                                        :class="{ 'button-disabled': !isValidAmount }" :disabled="!isValidAmount"
                                        @click="submitTransction">{{ $t('submit') }}
                                    </button>

                                </div>

                            </div>

                        </div>

                    </div>
                </div>


            </div>
        </div>

    </div>
</template>

<script setup>

import '../assets/css/page/deposit.css'
import { inject, onMounted, ref, computed } from 'vue';
import { notificationSymbol } from '../tools/symbol';
import MemberNavBar from '../components/MemberNavBar.vue';
import RecentTransaction from '../components/RecentTransaction.vue';
import RemainingBalance from '../components/RemainingBalance.vue';
import TransactionNav from '../components/TransactionNav.vue';
import apiServices from '../services/apiServices';
import { globalState } from '../store/globalState';

import {eventBus} from '../services/eventBus';

const notify = inject(notificationSymbol);
const playerBankAccounts = ref([]);

const currencyExchangeList = ref([]);
const rate = ref(0);
const transData = ref({
    playerId: globalState.user.id,
    amount: 0,
    playerBankAccountId: 0,
});

function setPlayerWithdrawalAccount(value) {
    transData.value.playerBankAccountId = value.id;
}


const clearForm = () => {

    // Reset transaction data
    transData.value = {
        playerId: globalState.user.id,
        amount: 0,
        playerBankAccountId: 0,
        currencyExchangeId:0,
        // Add other fields as needed
    };


};

// You can now use this function to clear the form whenever needed


const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
        const context = this;
        const args = arguments;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func.apply(context, args), delay);
    }
};

const submitTransction = debounce(async () => {
    try {
        const request = {
            playerId: transData.value.playerId,
            playerBankAccountId: transData.value.playerBankAccountId,
            Amount: transData.value.usdAmount,
            currencyExchangeId: transData.value.currencyExchangeId,
        }

        const response = await apiServices.createWithdrawalTransaction(request);
        if (response.status === 200) {
            notify('withdrawal_submittted', 'success');
            eventBus.emit('refresh');
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
            clearForm();
        } else {
            console.error('error:', response);
        }

    } catch (error) {
        notify(error.response.data.message, 'error');
    }

}, 1000);



async function getCurrencyExchange() {
    try {
        const response = await apiServices.getCurrencyExchangeList({ statusId: 1 });
        currencyExchangeList.value = response.data.currencyExchanges;
    } catch (error) {
        notify(error, 'error');
    }
}

const isValidAmount = computed(() => {
    // Check if the amount is a positive number and not empty
    const amount = parseFloat(transData.value.amount);
    return !isNaN(amount) && amount > 0;
});



const setCurrencyExchange = (value, id) => {
        rate.value = value;
    if (value > 0 && transData.value.usdAmount > 0) {
        transData.value.amount = (transData.value.usdAmount / rate.value).toFixed(2);
        transData.value.currencyExchangeId = id;

    } else {
        transData.value.amount = 0;
    }
};

const formatInput = (value) => {
    const positiveNumberRegex = /^[0-9]*\.?[0-9]+$/;
    
    if (positiveNumberRegex.test(value) || value === '') {
        // Limiting input to 2 decimal places
        const num = value.toFixed(2)
        const parts = num.split('.');
        if (parts.length > 1 && parts[1].length > 2) {
            // If more than 2 decimal places, truncate it
            return `${parts[0]}.${parts[1].slice(0, 2)}`;
        }

        isValidAmount.value = true;
        return num;
    }else{

        isValidAmount.value = false
    }
};

const calculate = () => {
    transData.value.amount = formatInput(transData.value.usdAmount);
    // Formatting bet amount to 2 decimal places
    const positiveNumberRegex = /^[0-9]*\.?[0-9]+$/;
    if (positiveNumberRegex.test(transData.value.usdAmount) || transData.value.usdAmount === '') {

        if(rate.value > 0){
            transData.value.amount = (transData.value.usdAmount / rate.value).toFixed(2);
        }else{
            transData.value.amount = 0;
        }
    } else {
        transData.value.amount = 0;
    }
};

async function getPlayerBankAccounts() {
    try {
        const bankDetails = await apiServices.getPlayerBankAccounts({ playerId: globalState.user.id });
        playerBankAccounts.value = bankDetails.data.playerBankAccounts;

    } catch (error) {
        notify(error, 'error');
    }
}



onMounted(() => {
    getPlayerBankAccounts();
    getCurrencyExchange();
});


</script>
<style scoped>
.profile-section-title {
    text-transform: capitalize;
}

.bank-method-tabber a span {
    margin-top: 0px;
    margin-bottom: 0px;
}

.bank-method-tabber a {

    padding: 10px;
}

.payment-method:hover {
    text-decoration: unset !important;
    border-bottom: 2px solid #caab72;
}

.button.button-primary{
    width: 60%;

}
.bank-method-tabber {
    margin: 0 !important;
}

.deposit-page-container {
    padding: 0px 16px;
}

.m-t-1-4 {
    margin-top: 1.4em;
}

body #view-payment-accounts .payment-account-container {
    border-radius: 4px !important;
    margin: 10px 0;
}

.bank-method-tabber li {
    border-right: 2px solid var(--primary-2);
    padding: 5px 14px 5px 3px;
}

.bank-method-tabber li:last-child,
.bank-method-tabber li:last-of-type {
    border-right: none;
}

.button.button-primary.button-disabled {
    background-color: var(--secondary-5) !important;
}

.input-file-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
}
.content .content-wrapper .content-box,
::v-deep(.select-box__input-text) {
    background-color: var(--other-1)  !important; 
}

::v-deep(.select-box) {
    color: var(--primary-3)  !important; 
}
::v-deep(.input-area .input input, .input-area .input select),
::v-deep(.input-label),
.bank-method-tabber .active a span:before,
.bank-method-tabber a,
.profile-section-title {
    color: var(--primary-3)  !important; 
}
</style>