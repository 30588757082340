export default {
  "login": "Gabung",
  "logout": "Keluar",
  "register": "Daftar",
  "are_u_member": "Apakah kamu anggota?",
  "home": "Rumah",
  "sports": "Olahraga",
  "livecasino": "Kasino Langsung",
  "casino": "Kasino",
  "kenolotto": "Keno & Lotto",
  "promotions": "Promosi",
  "vip": "VIP",
  "darkmode": "Mode Gelap",
  "lightmode": "Modus Cahaya",
  "username": "Nama belakang",
  "password": "Kata sandi",
  "input_username": "Masukkan nama pengguna terdaftar Anda",
  "input_password": "Ketik kata sandi Anda",
  "keep_login": "Biarkan saya tetap masuk",
  "create_new_account": "Buat akun baru!",
  "forgot_login_details": "Lupa detail login Anda?",
  "not_yet_member": "Belum menjadi anggota?",
  "register_to": "DAFTAR KE",
  "reg_msg_1": "  Harap dicatat bahwa kami tidak mengizinkan anggota untuk memiliki lebih dari satu (1) akun.",
  "already_have_account": " Sudah memiliki akun?",
  "login_here": "Masuk disini!",
  "name": "Nama",
  "how_address_u": "Bagaimana kami menyapa Anda?",
  "username_validation": "Panjang 3-16 karakter alfanumerik",
  "password_validation": "Panjang 6-30 karakter",
  "email": "Surel",
  "cancel": "Membatalkan",
  "update_account_details": "Perbarui detail akun Anda",
  "bank_account_holder_name": "Nama Pemilik Rekening Bank",
  "add_bank_account": "TAMBAHKAN REKENING BANK BARU",
  "bank_name": "Nama Bank",
  "bank_account_number": "Nomor rekening bank",
  "add_bank_verification_msg": "Dengan mengklik Simpan Rekening, saya mengonfirmasi bahwa informasi rekening bank yang diberikan sudah benar.",
  "save_account": "Simpan Akun",
  "total_balances": "Jumlah Saldo",
  "date_time": "Tanggal Waktu",
  "account_profile": "Profil Akun",
  "update_bank_details": " Perbarui Detail Bank",
  "cashier": "Kasir",
  "profile": "Profil",
  "history": "Sejarah",
  "deposit": "Menyetorkan",
  "withdrawal": "Penarikan",
  "slidding_announcement": "Platform taruhan anti-gelombang 365WON secara resmi diluncurkan! Investasikan dengan bijak dan kekayaan Anda tidak terbatas! Memimpin masa depan baru taruhan olahraga",
  "refer_friend": "Referensikan Teman",
  "transfer": "Transfer",
  "statement": "Penyataan",
  "match_result": "Hasil Pertandingan",
  "bet_record": "Catatan Taruhan",
  "transaction_history": "Transaksi",
  "running_bets": "Taruhan Berjalan",
  "no_results": "Tidak ada hasil",
  "latest_statement": "Pernyataan Terbaru",
  "total_bet_amount": "Jumlah Total Taruhan",
  "total_earned_profit": "Total Keuntungan yang Diperoleh",
  "transaction_type": "tipe transaksi",
  "all": "Semua",
  "groupcommission": "Komisi Kelompok",
  "profitfrombet": "Untung dari Taruhan",
  "adjustment": "Pengaturan",
  "voidwithdrawal": "Batalkan Penarikan",
  "bet": "Bertaruh",
  "voidbet": "Batalkan Taruhan",
  "referralhold": "Penangguhan Rujukan",
  "referralreturn": "Pengembalian Referensi",
  "firstbethold": "Penahanan Taruhan Pertama",
  "firstbetreturn": "Pengembalian Taruhan Pertama",
  "pending": "Tertunda",
  "processing": "Pengolahan",
  "approved": "Disetujui",
  "rejected": "Ditolak",
  "transaction_status": "Status Transaksi",
  "select_date_from": "Pilih Tanggal Dari",
  "to": "Ke",
  "date_to_invalid": "Tanggal Dari harus lebih awal dari Tanggal Ke.",
  "date": "Tanggal",
  "transaction_id": "ID transaksi",
  "transaction_date": "tanggal transaksi",
  "kick_off_date": "Waktu Kick-off",
  "match": "Cocok",
  "score": "Skor",
  "amount": "Jumlah",
  "profit": "Laba",
  "projected_profit": "Proyeksi Keuntungan",
  "status": "Status",
  "submit": "Kirim",
  "odds": "Kemungkinan",
  "personal_details": "Data pribadi",
  "nickname": "Nama panggilan",
  "date_of_birth": "Tanggal lahir",
  "date_of_birth_msg": "Memberikan tanggal lahir Anda adalah opsional tetapi disarankan untuk tujuan promosi",
  "mobile": "Seluler",
  "change_my_password": "Ubah kata sandi saya",
  "old_password": "Password lama",
  "enter_ur_old_password": "Masukkan kata sandi lama Anda",
  "new_password": "kata sandi baru",
  "currency_code": "USD",
  "withdrawable": "Dapat ditarik",
  "hey": "Hai",
  "ur_membership_level_is": "Tingkat Keanggotaan Anda adalah",
  "method": "metode",
  "type": "Jenis",
  "deposit_step_2": "Pilih rekening bank 365 won yang akan disetor",
  "deposit_step_3": "Setelah transfer selesai, silakan isi formulir di bawah ini",
  "deposit_step_1": "Pilih metode pembayaran untuk melakukan deposit",
  "bank_account": "Akun bank",
  "bank": "Bank",
  "recent_transaction": "transaksi terkini",
  "upload": "Mengunggah",
  "receipt": "Kuitansi",
  "deposit_submittted": "Setoran Dikirim",
  "file_upload_validation": "Ukuran file tidak boleh melebihi 5MB",
  "copied_to_clipboard": "Disalin ke papan klip!",
  "failed_to_copy": "Gagal menyalin ke papan klip.",
  "withdrawal_submittted": "Penarikan Dikirim",
  "withdrawal_step_1": "Penarikan ke bank yang Anda inginkan",
  "transfer_step_1": "Transfer ke teman Anda",
  "player_not_found": "Pemain tidak ada!",
  "transfer_submitted": "Transfer Dikirim",
  "register_success": "Pendaftaran telah selesai!",
  "invalid_email": "alamat email salah",
  "register_failed": "Registrasi gagal !",
  "login_failed": "Kredensial masuk tidak valid",
  "login_success": "Masuk Berhasil!",
  "confirm_password": "konfirmasi sandi",
  "password_validate_failed": "Validasi kata sandi gagal",
  "update_member_success": "Update Informasi Anggota Berhasil",
  "confirm_match_new": "Konfirmasi Kata Sandi harus sesuai dengan Kata Sandi Baru",
  "update_member_failed": "Pembaruan Informasi Anggota Gagal",
  "session_expired": "Sesi Anda telah berakhir. Silakan masuk lagi.",
  "update_player_bank_success": "Rekening Bank telah diperbarui.",
  "create_player_bank_success": "Rekening Bank telah dibuat.",
  "correct_score": "Skor Benar",
  "bet_now": "Bertaruh Sekarang",
  "see_all": "Lihat semua",
  "quick_easy": "Ini cepat dan mudah",
  "join_now": "Bergabunglah dengan 365 Won",
  "fast_deposit_time": "Aman dengan waktu deposit tercepat",
  "start_ur_journey": "Mulailah perjalanan kemenangan Anda sekarang!",
  "play": "Bermain",
  "all_market": "Semua Pasar",
  "ft_correct_score": "Skor Benar FT",
  "ht_correct_score": "Skor Benar HT",
  "available_volume": "Volume yang Tersedia",
  "show_correct_score": "Tunjukkan skor yang benar",
  "total_volume": "Jumlah Volume",
  "hot": "Panas",
  "login_first": "Silahkan masuk terlebih dahulu",
  "affiliate": "afiliasi",
  "about_us": "Tentang kami",
  "commission": "Komisi",
  "other_options": "Pilihan lain",
  "bet_amount": "Jumlah Taruhan",
  "profit_amount": "Jumlah Keuntungan",
  "u_are_betting": "Anda bertaruh",
  "bet_success": "Taruhan Sukses",
  "commission_transaction": "Transaksi Komisi",
  "aff_login_id": "ID Masuk Afiliasi",
  "comm_type": "Jenis Komisi",
  "commission_rate": "Tarif Komisi",
  "total_commission_earned": "Total Komisi yang Diperoleh",
  "affiiate_list": "Daftar Afiliasi",
  "member_id": "Tanda Anggota",
  "fullname": "Nama lengkap",
  "membership_level": "Tingkat Keanggotaan",
  "reg_date": "tanggal registrasi",
  "direct_affiliate_list": "Daftar Afiliasi Langsung",
  "total_num_of_aff_grp": "Jumlah Total Grup Afiliasi",
  "total_num_of_direct_aff": "Jumlah Total Afiliasi Langsung",
  "new_affiliate": "Afiliasi Baru",
  "total_group_size": "Total Ukuran Grup",
  "full_time": "Waktu penuh",
  "half_time": "Setengah waktu",
  "jackpot_amount": "10.000",
  "deposit_amount": "Jumlah Setoran",
  "deposit_currency": "Mata Uang Setoran",
  "withdrawal_amount": "Jumlah penarikan",
  "withdrawal_currency": "Mata Uang Penarikan",
  "referral_link_copied": "Tautan rujukan disalin!",
  "invite_via_link": "Undang Teman Anda Melalui Tautan Referensi",
  "direct_affiliate": "Afiliasi Langsung",
  "save_photo": "Simpan ke foto",
  "share_my_code": "Bagikan Kode Saya",
  "qr_url_copied": "URL Kode QR disalin!",
  "referral": "Rujukan"
};