<template>
    <div class="side-cashier-content mt-16 mr-16-desktop">
        <div class="dropdown-header">
            <div class="top">
                <div class="text sm balance mt-24 ml-24 mb-4">{{ $t('total_balances') }}</div>
            </div>
            <div class="bottom  ml-24">
                <span class="text lg bold balance uppercase">{{ $t('currency_code') }} &nbsp;</span>
                <span id="balance-total-raw" style="display: none;">0</span>
                <span id="balance-total" class="text lg bold balance balance-total mr-8">{{ balance }}</span>
                <div class="refresh" @click="toggleRefresh" :class="{ 'rotate': isRotating }" data-action="refresh"></div>
            </div>
        </div>

        <div class="dropdown-content">


        </div>
    </div>
</template>
<script>

import { getBalance } from '../store/globalState'
import { formatNumber} from '../services/generalFunction'
export default {
    data() {
        return {
            isRotating: false,
        };
    },
    computed: {
        balance() {
            return this.$globalState.balance ? formatNumber(this.$globalState.balance) : 0.00;
        },
    },
    methods: {
        async toggleRefresh() {
            this.isRotating = true;
            try {
                    await getBalance();
                    // Update balance here
                } catch (error) {
                    console.error('Error refreshing balance:', error);
                    // Handle errors
                }
                setTimeout(() => {
                    this.isRotating = false;
                }, 1000); // match the duration of your animatio

           

        },
    },
};
</script>

<style scoped>
@media (min-width: 767.5px) {
    .content-cashier .side-cashier-content .dropdown-header {
        border-radius: 8px !important;
    }
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.rotate {
    animation: rotate 1s linear;
}

.content-cashier .side-cashier-content .dropdown-header .bottom .balance {
    font-size: 18px;
}

.content-cashier .side-cashier-content .dropdown-header .top {
    margin-bottom: 10px;
}

::v-deep(.dropdown-menu-balance .dropdown-content-wrapper .dropdown-content-wrapper-scrollable) {
  
    overflow: hidden !important;
}
</style>