<template>
  <div>
    <!-- Render MainLayout only if the current path is not '/payment' -->
    <MainLayout v-if="!isPaymentRoute" />    
    <BlankLayout v-if="isPaymentRoute" />
  </div>
</template>

<script setup>
import { useRoute,  } from 'vue-router';
import MainLayout from './Layout/MainLayout.vue';
import BlankLayout from './Layout/BlankLayout.vue';
import { globalState } from './store/globalState';
import { watch, onMounted, onUnmounted, computed } from 'vue';

const route = useRoute();
const isPaymentRoute = computed(() => route.path === '/payment');

const toggleBodyScroll = (shouldDisable) => {
  document.body.style.overflow = shouldDisable ? 'hidden' : '';
};

// Set up the watcher in the onMounted lifecycle hook
onMounted(() => {
  watch(
    // Watch multiple sources
    () => [
      globalState.isLoginShow,
      globalState.isRegShow,
      globalState.isAffRegShow,
      globalState.isShowPlaceBet,
      isPaymentRoute.value // Now also consider if it's the payment route
    ],
    (newValues) => {
      // Whenever any of them changes, we check if we should disable the scroll
      const anyModalOpen = newValues.slice(0, 4).some(value => value);
      toggleBodyScroll(anyModalOpen);
    },
    { immediate: true } // Run immediately to apply current state
  );
});

// Make sure to enable scroll when the component is unmounted
onUnmounted(() => {
  toggleBodyScroll(false);
});
</script>
