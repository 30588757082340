<template>
    <div class="fullscreen-overlay">
        <div class="payment-status">
            <p class="loading-text" v-if="loading">Payment is in processing</p>
            <div class="loading-animation" v-if="loading">
                <img src="../assets/loading.gif" alt="Loading" width="300">
            </div>

            <transition name="payment-status">
                <div v-if="success" class="payment-status">
                    <img src="../assets/success.png" alt="Payment Successful" width="300">
                    <p class="loading-text">Payment Successful!</p>
                </div>
            </transition>

            <transition name="payment-status">
                <div v-if="error" class="payment-status">
                    <img src="../assets/failed.png" alt="Payment Failed" width="300">
                    <p class="loading-text">Payment failed. Please contact admin.</p>
                </div>
            </transition>
        </div>
    </div>
</template>

<script setup>
import { ref, onUnmounted, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';

import { TransactionStatusEnum } from '../enum/transactionStatus'
import apiServices from '../services/apiServices';

const router = useRouter();
const route = useRoute();
const loading = ref(true);
const success = ref(false);
const error = ref(false);
let interval = null;
let timeout = null;
const transactionId = ref(route.params.transactionId);

const checkPaymentStatus = async () => {
    try {
        const response = await apiServices.getDepositTransactionById({id :transactionId.value});
        if (response.data.statusId == TransactionStatusEnum.Approved) {
            loading.value = false;
            success.value = true;
            clearInterval(interval);
            clearTimeout(timeout);
            closePage();
        }

        if (response.data.statusId == TransactionStatusEnum.Rejected) {
            loading.value = false;
            error.value = true;
            clearInterval(interval);
            clearTimeout(timeout);
            closePage();
        }
    } catch (err) {
        console.error('API call failed:', err);
       
    }
};

const closePage = () => {
    setTimeout(() => {
        router.push('/');
    }, 2000); // Redirects to the homepage after 5 seconds
};

onMounted(() => {
    interval = setInterval(() => {
        if (!success.value && !error.value) {
            checkPaymentStatus();
        }
    }, 3000);

    timeout = setTimeout(() => {
        if (!success.value && !error.value) {
            loading.value = false;
            error.value = true;
            clearInterval(interval);
            closePage();
        }
    }, 60000); // 60 seconds timeout
});

onUnmounted(() => {
    clearInterval(interval);
    clearTimeout(timeout);
});
</script>


<style scoped>
.fullscreen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.payment-status {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.loading-text {
    font-size: 2em;
    font-weight: 600;
    font-family: monospace;
    color: #535353;
}


.payment-status-enter-active, .payment-status-leave-active {
    transition: opacity 0.5s;
}

.payment-status-enter, .payment-status-leave-to  {
    opacity: 0;
}
</style>