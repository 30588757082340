export const EventStatusEnum = {
    Draft: 1,
    Pending: 2,
    Running: 3,
    Completed: 4,
    Cancelled: 5
  };
  
  export function getEventStatusLabel(typeId) {
    switch (typeId) {
      case EventStatusEnum.Draft:
        return 'Draft';
        case EventStatusEnum.Pending:
            return 'Pending';
        case EventStatusEnum.Running:
            return 'Running';
        case EventStatusEnum.Completed:
            return 'Completed';
        case EventStatusEnum.Cancelled:
            return 'Cancelled';
       
        default:
            return '-';
    }
}