<template>
    <SlidingBanner />

   
    <div class="three-step-guide d-flex full-width background-color-secondary-1 justify-content-center">
        <div class="three-step-guide-content d-flex flex-row border-box full-width py-16 py-28-desktop">
            <div class="step d-flex flex-row pointer">
                <img class="icon" src="../assets/image/icon/icon-three-step-guide-join.svg" alt="Join">
                <div class="d-flex flex-column justify-content-center ml-8 ml-16-desktop">
                    <div class="uppercase bold color-secondary-3 h7 h6-tablet h5-desktop">{{ $t('join_now') }}</div>
                    <div class="text md d-none d-flex-desktop color-primary-7 mt-4">{{ $t('quick_easy') }}</div>
                </div>
            </div>

            <div class="arrow d-flex background-color-primary-6"></div>

            <div class="step d-flex flex-row pointer">

                <img class="icon" src="../assets/image/icon/icon-three-step-guide-deposit.svg" alt="Deposit">
                <div class="d-flex flex-column justify-content-center ml-8 ml-16-desktop">
                    <div class="uppercase bold color-secondary-3 h7 h6-tablet h5-desktop">{{ $t('deposit') }}</div>
                    <div class="text md d-none d-flex-desktop color-primary-7 mt-4">{{ $t('fast_deposit_time') }}</div>
                </div>
            </div>

            <div class="arrow d-flex background-color-primary-6"></div>

            <div class="step d-flex flex-row pointer">

                <img class="icon" src="../assets/image/icon/icon-three-step-guide-play.svg" alt="PLay">

                <div class="d-flex flex-column justify-content-center ml-8 ml-16-desktop">
                    <div class="uppercase bold color-secondary-3 h7 h6-tablet h5-desktop">{{ $t('play') }}</div>
                    <div class="text md d-none d-flex-desktop color-primary-7 mt-4">{{ $t('start_ur_journey') }}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div  class="banner-web desktop-only">
        <div class="banner-number">{{ formatNumber(currentNumber) }}</div>
    </div>
    <div  class="banner-web mobile-only">
        <div class="banner-number">{{ formatNumber(currentNumber) }}</div>
    </div>
  
    <div class="dynamic-content">
        <div class="area bg sportsbook-section pt-16 pb-32 pt-24-tablet pb-64-tablet pt-32-desktop pb-80-desktop px-24-desktop"
            id="area-sportsbook" data-type="default" data-tracking-name="Sportsbook" v-if="isShow">
            <div class="title-component d-flex flex-column my-16 mx-0 my-20-tablet my-24-desktop pl-16 pl-0-desktop">


                <div class="d-flex flex-row">


                    <span class="uppercase bold color-primary-mode-1 h6 h5-tablet h4-desktop"
                        style="text-transform: capitalize;">
                        {{ $t('sports') }}
                    </span>


                    <a class="track-events uppercase bold color-primary-mode-3 text xs md-tablet lg-desktop absolute pr-16 pr-24-desktop"
                        data-tracking-name="Mfeeds - See All Products" @click="$redirectTo('/sports')">
                        <span>{{ $t('see_all') }}</span>



                        <div class="mask"
                            style="mask: url('/images/icon/icon-arrow-right.svg') no-repeat center; mask-size: contain;">
                        </div>

                    </a>

                </div>

            </div>
            <div class="odds-component background-primary-13">
                <div class="block cards-sets odds-cards-wrap">
                    <button class="button button-primary track-events bet-now" @click="$redirectTo('/sports')">
                        {{ $t('bet_now') }}
                    </button>
                    <div class="area pt-20 pb-32 pt-20-tablet pb-24-tablet pt-56-desktop pb-32-desktop"
                        id="sports-highlight">

                        <CorrectScore @hasItem="handleHasitem" @empty="handleEmptyitem" />
                    </div>

                </div>

                
            </div>
           

        </div>
        <div style="position: relative;margin: 1em;">

<VideoPlayer/>
</div>
    </div>
</template>
  

<script setup>
import { ref, onMounted } from 'vue';
import SlidingBanner from '../components/SliddingBanner.vue';
import CorrectScore from '../components/CorrectScore.vue';
import VideoPlayer from '../components/VideoPlayer.vue';
import {formatNumber} from '../services/generalFunction';

import { useI18n } from 'vue-i18n';
const { t } = useI18n({ useScope: 'global' });
const isShow = ref(true)


const handleHasitem = () => {
    isShow.value = true;
}

const handleEmptyitem = () => {
    isShow.value = false;
}

const currentNumber = ref(parseFloat(t('jackpot_amount')));

function setStartTimeToMarch12() {
  const now = new Date();
  const currentYear = now.getFullYear();
  const startOfMarch12 = new Date(currentYear, 2, 12); // Month is 0-indexed, so 2 is March
  const secondsSinceMarch12 = Math.floor((now - startOfMarch12) / 1000);
  const initialIncrement = secondsSinceMarch12 * 0.01; // 1 second = 1 cent
  currentNumber.value = (parseFloat(currentNumber.value) + initialIncrement).toFixed(2);
}

function getRandomIncrement() {
  return (Math.random() * (0.1 - 0.01) + 0.01).toFixed(2); // Generates a random decimal between 0.01 and 0.1
}

function animateNumber() {
  setInterval(() => {
    currentNumber.value = (parseFloat(currentNumber.value) + parseFloat(getRandomIncrement())).toFixed(2);
  }, 100); // Adjust interval time if needed
}






onMounted(() => {
 // Initialize the number based on the current date and time compared to March 12
setStartTimeToMarch12();

// Optionally, start the animation with random increments
animateNumber();
});

</script>

<style scoped>
.carousel-container {
    overflow-x: auto;
    white-space: nowrap;
    scrollbar-width: none;
    /* For Firefox */
    -ms-overflow-style: none;
    /* For Internet Explorer and Edge */

    /* For Chrome, Safari, and Opera */
    &::-webkit-scrollbar {
        display: none;
    }
}

.swiper-wrapper {
    display: inline-flex;

    padding-bottom: 10px !important;
}

@media (max-width: 768px) {
    .odds-cards-wrap {
        background-color: transparent !important;
    }

    .bg{
        background-image: url(../assets//image/home-page/upcoming-events/msports_EN.png);
    background-color: var(--primary-13);
    background-repeat: no-repeat;
    }

    .banner-web{
       min-height: 100px !important;
    }
    .banner-number{
      font-size: 40px !important;
      text-shadow: 1px 2px 1px #caab72 !important
    }
}

.w-100{
    width: 100%;
}


.banner-web{
    background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(../assets/image/icon/bonus-web.gif);

  width: 100%;
  min-height: 320px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.banner-number{
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    padding-top: 10px;

    font-size: 100px;
    color: #ffffffde;
    font-weight: 700;
    z-index: 2;
    text-shadow: 1px 5px 1px #caab72
}
</style>