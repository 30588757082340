<template>
    <div class="header flex-column">
        <div class="header-content">
            <div class="header-overlay-container"></div>
            <div class="logo-mobile">
                <a id="header-logo-mobile" @click="$redirectTo('/');">
                    <img src="../assets/image/logo/mlogo-mobile_cn.svg" alt="家">
                </a>
            </div>
            <div class="logo-desktop">
                <a id="header-logo" @click="$redirectTo('/');">
                    <img src="../assets/image/logo/mlogo-desktop.svg" alt="家">
                </a>
            </div>

            <div id="animated-widget-wrapper" class="animated-widget-wrapper">
            </div>
            <div class="header-content-right" v-if="globalState.isAuth">


                <DarkMode :is-mobile="false" />


                <div class="dropdown-menu-balance  header-balance" id="header-balance-seamless" data-currency-code="MYR">
                    <div class="menu-header">
                        <div class="top">
                            <div class="text bold balance">{{ $t('total_balances') }}</div>
                            <div class="dd-caret"></div>
                        </div>
                        <div class="bottom">
                            <div class="refresh" :class="{ 'spin': isRefreshing }" @click="refreshBalance"
                                data-action="refresh"></div>
                            <div data-autofill-total="balance-without-currency"
                                class="text bold balance header-balance__hidden-hide up-to-tablet"></div>
                            <div data-autofill-total="balance-with-currency"
                                class="text bold balance header-balance__hidden-hide from-tablet-up">{{
                                    $t('currency_code') }}&nbsp;{{ formatNumber(globalState.balance) }}</div>

                        </div>
                    </div>

                    <div class="dropdown-content-wrapper">
                        <div class="dropdown-content-wrapper-scrollable">
                            <div class="dropdown-header">
                                <div class="top">
                                    <div class="text sm balance mt-24 ml-24 mb-2">{{ $t('total_balances') }}</div>

                                </div>
                                <div class="bottom  ml-24">
                                    <div class="text xl bold balance">{{ $t('currency_code') }} &nbsp;</div>
                                    <div class="text xl bold balance mr-8" data-autofill-total="balance">{{ formatNumber(globalState.balance) }}</div>
                                    <div class="refresh" :class="{ 'spin': isRefreshing }" @click="refreshBalance"
                                        data-action="refresh"></div>
                                </div>
                            </div>

                            <div class="dropdown-content">
                                <div class="item-balance withdrawable mx-16">
                                    <div class="item-top mt-16 mx-16">
                                        <div class="text sm withdrawable">{{ $t('withdrawable') }}</div>
                                        <div class="icon withdrawable-icon">{{ formatNumber(globalState.balance) }}</div>
                                    </div>
                                    <div class="item-bottom mb-16 d-flex">
                                        <div class="text lg bold ml-16 currency-text">{{ $t('currency_code') }} &nbsp;
                                        </div>
                                        <div class="text lg bold withdrawable-balance" data-autofill-total="withdrawable">
                                            {{ formatNumber(globalState.balance) }}</div>
                                    </div>
                                </div>


                                <div class="header-balance__wallet" data-wallet-type="central"
                                    data-url="/Common/GetBalanceCached" data-outcome="success"></div>

                                <div class="balance-buttons my-24">
                                    <button class="button lg button-primary seamless" id="header-balance-deposit"
                                        @click="$redirectTo('/deposit')">{{ $t('deposit') }}</button>

                                    <button class="button lg button-secondary-light seamless"
                                        id="header-balance-withdrawable" data-url="/cashier/withdrawal"
                                        @click="$redirectTo('/withdrawal')">{{ $t('withdrawal') }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <button class="button sm md-tablet button-primary" @click="$redirectTo('/deposit')">{{
                    $t('deposit') }}</button>




                <div class="dropdown-menu-accu" id="dropdown-menu-accunt">
                    <div class="menu-header">
                        <div class="icon-account mr-8"></div>
                        <div class="dd-caret"></div>
                    </div>

                    <div class="dropdown-content-wrapper">
                        <div class="dropdown-header p-16">
                            <div class="d-flex flex-column full-width color-other-1">
                                <div class="d-flex flex-row space-between">
                                    <div class="d-flex my-auto">
                                        <span class="text bold lg">{{ $t('hey') }},&nbsp;</span>
                                        <span class="text bold lg"> {{ globalState.user ? globalState.user.name : '' }}</span>
                                    </div>

                                    <div class="d-flex flex-column text-align-end">
                                        <span class="text bold xxs">{{ $t('ur_membership_level_is') }}</span>
                                        <span class="text bold lg uppercase color-bronze">{{ globalState.user ?
                                            globalState.user.membershipName : '' }}</span>
                                    </div>
                                </div>



                            </div>
                        </div>
                        <div class="dropdown-content-wrapper-scrollable pr-8">
                            <div class="dropdown-content">
                                <div class="item" id="accordionCashier">
                                    <input type="checkbox" id="chckCashier">
                                    <label class="item-header" for="chckCashier">
                                        <div class="icon cashier mr-12"></div>
                                        <a class="no-underline" @click="$redirectTo('/deposit')">
                                            <div class="text md bold">{{ $t('cashier') }}</div>
                                        </a>
                                        <div class="acc-caret"></div>
                                    </label>

                                    <div class="item-content mr-24 ml-24">
                                        <a class="no-underline" @click="$redirectTo('/deposit')" >
                                            <div class="dd-item">
                                                <div class="text sm bold">
                                                    {{ $t('deposit') }}
                                                </div>
                                            </div>
                                        </a>


                                        <a class="no-underline" id="account-withdrawal"
                                            @click="$redirectTo('/withdrawal')" >
                                            <div class="dd-item">
                                                <div class="text sm bold">
                                                    {{ $t('withdrawal') }}
                                                </div>
                                            </div>
                                        </a>

                                        <a class="no-underline" id="account-transfer"
                                            @click="$redirectTo('/transfer')" >
                                            <div class="dd-item">
                                                <div class="text sm bold">
                                                    {{ $t('transfer') }}
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div class="item" id="accordionProfile">
                                    <input type="checkbox" id="chckProfile">
                                    <label class="item-header" for="chckProfile">
                                        <div class="icon profile mr-12"></div>
                                        <a class="no-underline"  id="cashier_nav_profile"
                                            @click="$redirectTo('/profile')">
                                            <div class="text md bold">{{ $t('profile') }}</div>
                                        </a>
                                        <div class="unread-messages-container-dropdown unread-messages-icon"
                                            style="display: none;">
                                            <span id="unread-count"></span>
                                        </div>
                                        <div class="acc-caret"></div>
                                    </label>

                                    <div class="item-content mr-24 ml-24">
                                        <a class="no-underline"  @click="$redirectTo('/profile')">
                                            <div class="dd-item">
                                                <div class="text sm bold">
                                                    {{ $t('account_profile') }}
                                                </div>
                                            </div>
                                        </a>

                                        <a class="no-underline"  @click="$redirectTo('/bankdetails')">
                                            <div class="dd-item">
                                                <div class="text sm bold">
                                                    {{ $t('update_bank_details') }}
                                                </div>
                                            </div>
                                        </a>


                                    </div>
                                </div>

                                <div class="item" id="accordionHistory" @click="$redirectTo('/history')">
                                    <input type="checkbox" id="chckHistory">
                                    <label class="item-header" for="chckHistory">
                                        <div class="icon history mr-12"></div>
                                        <a class="no-underline" id="cashier_nav_history" >
                                            <div class="text md bold"> {{ $t('history') }}</div>
                                        </a>
                                    </label>


                                </div>


                                <div class="item" id="logout" @click="logout">
                                    <label class="item-header">
                                        <div class="icon logout mr-12"></div>
                                        <div class="text md bold"> {{ $t('logout') }}</div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="header-content-right" v-else>

                <DarkMode :is-mobile="false" />


                <button class="button sm md-tablet button-secondary-dark mr-8" id="login-button"
                    @click="showLoginPopup">
                    <span style="vertical-align: inherit;">
                        <span style="vertical-align: inherit;">{{ $t('login') }}</span>
                    </span>
                </button>
                <button class="button sm md-tablet button-primary" id="desktop-join-now-btn"  @click="showRegisterPopup">
                    <span style="vertical-align: inherit;">
                        <span style="vertical-align: inherit;">{{ $t('register') }}</span>
                    </span>
                </button>
            </div>


            <MobileSideMenu  />
        </div>
    </div>

    <LoginComponent/>
    <RegisterComponent />
    <AffRegisterComponent />
    <PlaceBet/>
</template>

<script setup>
import { onMounted,onBeforeUnmount,ref } from 'vue';
import MobileSideMenu from './MobileSideMenu.vue';
import DarkMode from './DarkMode.vue';
import LoginComponent from '../Pages/LoginComponent.vue';
import AffRegisterComponent from '../Pages/AffRegisterComponent.vue';
import PlaceBet from '../components/PlaceBet.vue';
import RegisterComponent from '../Pages/RegisterComponent.vue';
import { logout, getBalance, globalState } from '../store/globalState'; 
import { formatNumber } from '../services/generalFunction'; 
import { eventBus } from '../services/eventBus';
const isRefreshing = ref(false);


function showLoginPopup() {
    globalState.isLoginShow = true;
}

function showRegisterPopup() {
    globalState.isRegShow = true;
}
async function refreshBalance() {
    isRefreshing.value = true;
    try {
        await getBalance();
    } catch (error) {
        console.error('Error refreshing balance:', error);
    } finally {
        setTimeout(() => {
            isRefreshing.value = false;
        }, 1000);
    }
}

const handleFromReferral = () => {
    const flag = localStorage.getItem('showRegModal');
    if (flag == 'true') {
        showRegisterPopup();
        localStorage.removeItem('showRegModal');
    }
}

onMounted(() => {
    eventBus.on('fromAffiliate', handleFromReferral);
});

onBeforeUnmount(() => {
  eventBus.off('fromAffiliate', handleFromReferral);
});
</script>


