<template>
    <div id="lang-dropdown" class="dropdown">
        <a class="dropbtn" :id="selectedLanguage.id" :data-language="selectedLanguage.code"
            :data-currency="selectedLanguage.currency">
            <img :src="`/images/icon/flag.${selectedLanguage.code}.svg`" alt="{{ selectedLanguage.name }}">
            <div class="text sm hide-text">{{ selectedLanguage.name }}</div>
            <i class="icon-caret"></i>
        </a>

        <div class="dd-caret"></div>

        <div class="dropdown-contiainer">
            <div class="dropdown-content dropdown-menu-lang-dropdown px-12 py-12">
                <div v-for="(language, index) in languages" :key="index" class="language-item"
                    @click="changeLanguage(language)">
                    <a :id="`language-${language.code}-${language.currency}-Desktop`" class="change-language px-12 py-12"
                        :data-language="language.code" :data-currency="language.currency">
                        <img :src="`/images/icon/flag.${language.code}.svg`" class="mr-8" >
                        <div class="text md lang">
                            {{ language.name }}
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            languages: [
                { code: 'en-US', name: 'English', currency: 'MYR' },
                { code: 'zh-CN', name: '简体中文', currency: 'MYR' },
                { code: 'zh-TW', name: '繁体中文', currency: 'MYR' },
                 { code: 'id-ID', name: 'Bhs Indonesia', currency: 'MYR' },
                { code: 'th-TH', name: 'ไทย', currency: 'MYR' },
                 { code: 'vi-VN', name: 'Tiếng Việt', currency: 'MYR' },
            ],
            selectedLanguage: {
                code: '',
                name: '',
                currency: '',
                id: '',
            },
        };
    },
    methods: {
        changeLanguage(language) {
            this.selectedLanguage.code = language.code;
            this.selectedLanguage.name = language.name;
            this.selectedLanguage.currency = language.currency;
            this.selectedLanguage.id = language.code;

            this.$changeLang(language.code);
        },
        mapSelectedLanguage() {
            const storedLanguageCode = localStorage.getItem('lang');
            const matchedLanguage = this.languages.find(language => language.code === storedLanguageCode);

            if (matchedLanguage) {
                this.selectedLanguage.code = matchedLanguage.code;
                this.selectedLanguage.name = matchedLanguage.name;
                this.selectedLanguage.currency = matchedLanguage.currency;
                this.selectedLanguage.id = matchedLanguage.code;
            }
        },
    },
    created() {
        this.mapSelectedLanguage();
    },
};
</script>