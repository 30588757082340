<template>
    <div class="tabbed-links">
        <a id="tab-account" class="tabbed-links__item  button-icon" :class="{ selected: $route.path === '/profile' }" @click="this.$redirectTo('/profile')">
            <span class="icon"></span>
            {{ $t('account_profile')}}
        </a>
        <a id="tab-cashier" class="tabbed-links__item  button-icon" :class="{ selected: $route.path === '/bankdetails' }" @click="this.$redirectTo('/bankdetails')">
            <span class="icon"></span>
           {{ $t('update_bank_details') }}
        </a>
        <!-- <a id="tab-inbox" class="tabbed-links__item  button-icon" href="/account/messages">
            <span class="icon"></span>
            Inbox
            <div class="unread-messages-container upper-corner"><span id="unread-messages-tab-counter"></span></div>
        </a> -->
    </div>
</template>