<template>
    <MemberNavBar />

    <div class="content-wrapper">

        <RecentTransaction />

        <div class="content-cashier  d-flex mb-16-desktop">


            <RemainingBalance />

            <div class="cashier-iframe-body ">
                <TransactionNav />

                <div class="content-box full-width border-box mx-0-desktop pr-4">

                    <div class="left-form-layout">

                        <div class="edit-content">
                            <div class="profile-section-title  d-flex"> <span class="custom-no">1</span>{{
                                $t('deposit_step_1') }}</div>
                            <div class="scroll-holder scroll-x bank-method-tabber">
                                <div class="scroll-mover">
                                    <div class="scroller-inner">
                                        <ul>
                                            <li @click="setPaymentMethod(method.id, method.name, method )" class="box"
                                                :class="{ active: paymentMethodSelected == method.id }"
                                                v-for="method in filteredPaymentMethods" :key="method.id">
                                                <a class="payment-method" role="button">

                                                    <span :data-txt="method.name">{{ method.name }}</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="m-t-1-4" v-if="paymentMethodSelected">
                                <div class="profile-section-title  d-flex"> <span class="custom-no">2</span>{{
                                $t('deposit_step_2') }}</div>

                                <div class="deposit-page-container">
                                    <PaymentChannelSelectionFromChannelAccount
                                        @payment-channel-update="handlePaymentChannelUpdate"
                                        :value="transData.paymentChannelId" :method="paymentMethodSelected" />
                                </div>

                                <div class="deposit-page-container" v-if="paymentChannelSelected">
                                    <div class="input-area input-label-left mb-16 mt-16">

                                        <div class="input-label " id="addPaymentAccount-bankname">
                                            {{ $t('bank_account') }}</div>
                                        <div class="input-wrapper">
                                            <div class="select-box">

                                                <div class="select-box__current" tabindex="1">


                                                    <div class="select-box__value"
                                                        v-show="transData.paymentChannelAccountId == option.id"
                                                        v-for="option in paymentChannelAccount" :key="option.id">
                                                        <input class="select-box__input" type="radio"
                                                            :id="'filteredBanks_' + option.id">
                                                        <p class="select-box__input-text">{{ option.accountName }}</p>
                                                    </div>


                                                </div>

                                                <div class="select-box__list">
                                                    <div class="select-box-list-options-container">


                                                        <label @click="setPaymentChannelAccount(option)"
                                                            class="select-box__option"
                                                            v-for="option in paymentChannelAccount" :key="option.id"
                                                            :for="'filteredBanks_' + option.id" aria-hidden="true">
                                                            <div class="option-text">
                                                                <p class="md ml-8">{{ option.accountName }}</p>
                                                            </div>
                                                        </label>


                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div class="deposit-page-container" id="view-payment-accounts"
                                    v-if="channelAccountSelected">
                                    <div class="payment-account-container px-16 py-16 px-20-desktop py-20-desktop">

                                        <div class="payment-account-info">
                                            <div class="text bold xs mb-4 uppercase color-primary-9">
                                                {{ $t('bank_account_holder_name') }}
                                            </div>

                                            <div class="text bold md mb-4 lg-desktop uppercase color-other-1"
                                                placeholder="Bank Name">
                                                {{ channelAccountSelected.accountName }}
                                            </div>
                                        </div>

                                        <div class="buttons-wrapper">
                                            <a class="icon-copy"
                                                @click="copyToClipboard(channelAccountSelected.accountName)"></a>
                                        </div>
                                    </div>

                                    <div class="payment-account-container px-16 py-16 px-20-desktop py-20-desktop">

                                        <div class="payment-account-info">
                                            <div class="text bold xs mb-4 uppercase color-primary-9">
                                                {{ $t('bank_account_number') }}
                                            </div>

                                            <div class="text bold md mb-4 lg-desktop uppercase color-other-1"
                                                placeholder="Bank Name">
                                                {{ channelAccountSelected.accountNumber }}
                                            </div>
                                        </div>

                                        <div class="buttons-wrapper">
                                            <a class="icon-copy"
                                                @click="copyToClipboard(channelAccountSelected.accountNumber)"></a>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="m-t-1-4" v-if="channelAccountSelected">
                                <div class="profile-section-title  d-flex" v-if="paymentMethodSelected"><span
                                        class="custom-no">3</span>{{ $t('deposit_step_3') }}</div>


                                <div class="deposit-page-container">
                                  
                                    <div class="input-area input-label-left mb-16">
                                        <div class="input-label" id="edit-username">{{ $t('deposit_amount') }} (USD)
                                        </div>
                                        <div class="input-wrapper">
                                            <div class="input"
                                                :class="{ 'error': !isValidAmount, 'valid': isValidAmount }">
                                                <input type="number" :placeholder="$t('deposit_amount')"
                                                    @input="calculate" v-model="transData.usdAmount">
                                                <div class="icon icon-right "></div>
                                            </div>
                                            <div class="input-bottom-text"></div>

                                        </div>
                                    </div>

                                    
                                    <div class="input-area input-label-left mb-16"  v-if="isCrypto">
                                        <div class="input-label" id="edit-username">{{ $t('deposit_currency') }}
                                        </div>
                                        <div class="input-wrapper">
                                            <div class="input">
                                                <input type="text" value="USDT" disabled>
                                                   
                                                <div class="icon icon-right "></div>
                                            </div>
                                            <div class="input-bottom-text"></div>

                                        </div>
                                    </div>
                                    <div class="input-area input-label-left mb-16"  v-if="isOnlinePayment">
                                        <div class="input-label" id="edit-username">{{ $t('deposit_currency')  }}
                                        </div>
                                        <div class="input-wrapper">
                                            <div class="input">
                                                <input type="text" value="THB" disabled>
                                                   
                                                <div class="icon icon-right "></div>
                                            </div>
                                            <div class="input-bottom-text"></div>

                                        </div>
                                    </div>
                                    <div class="input-area input-label-left mb-16" v-if="!isCrypto && !isOnlinePayment">
                                        <div class="input-label" id="edit-username">{{ $t('deposit_currency') }}
                                        </div>
                                        <div class="input-wrapper">
                                            <div class="select-box">

                                                <div class="select-box__current" tabindex="1"
                                                    asp-for="TransactionSelectedType2">


                                                    <div class="select-box__value"
                                                        v-for="option in currencyExchangeList" :key="option.id">
                                                        <input class="select-box__input" type="radio" 
                                                            :id="'TransactionStatusDropdown_' + option.currencyCode"
                                                            value="withdrawal" name="TransactionSelectedType2">
                                                        <p class="select-box__input-text">{{
                                option.currencyCode }}</p>
                                                    </div>


                                                </div>

                                                <div class="select-box__list">
                                                    <div class="select-box-list-options-container">


                                                        <label @click="setCurrencyExchange(option.toUsdRate, option.id)"
                                                            class="select-box__option"
                                                            v-for="option in currencyExchangeList" :key="option.id"
                                                            :for="'TransactionStatusDropdown_' + option.currencyCode"
                                                            aria-hidden="true">
                                                            <div class="option-text">
                                                                <p class="md ml-8">{{ option.currencyCode }}
                                                                </p>
                                                            </div>
                                                        </label>


                                                    </div>
                                                </div>
                                            </div>



                                        </div>
                                    </div>

                                    <div class="input-area input-label-left mb-16">
                                        <div class="input-label" id="edit-username">{{ $t('deposit_amount') }}</div>
                                        <div class="input-wrapper">
                                            <div class="input">
                                                <input type="text" :placeholder="$t('deposit_amount')"
                                                    v-model="transData.amount" disabled>
                                                <div class="icon icon-right "></div>
                                            </div>
                                            <div class="input-bottom-text"></div>

                                        </div>
                                    </div>

                                    <div class="input-area input-label-left mb-16" v-if="!isOnlinePayment">
                                        <div class="input-label" id="edit-username">{{ $t('receipt') }}</div>
                                        <div class="input-wrapper input-file-wrapper">
                                            <input type="file" ref="fileInput" style="display: none;"
                                                @change="handleFileUpload" />
                                            <div class="input">
                                                <input type="text" :placeholder="$t('receipt')" readonly
                                                    v-model="receiptName">
                                                <div class="icon icon-right "></div>
                                            </div>
                                            <button type="button" class="button xl xl-tablet lg-desktop button-primary"
                                                @click="triggerFileInput">{{ $t('upload') }}
                                            </button>
                                        </div>
                                    </div>

                                </div>
                                <div class="buttons-wrapper pt-32" style="justify-content: center;">
                                    <button type="button" class="button xl xl-tablet lg-desktop button-primary"
                                        :class="{ 'button-disabled': !isValidAmount || (!isOnlinePayment && !isValidReceipt) }"
                                        :disabled="!isValidAmount || (!isOnlinePayment && !isValidReceipt)" @click="submitTransction">{{
                                        $t('submit') }}
                                    </button>

                                </div>

                            </div>

                        </div>

                    </div>
                </div>


            </div>
        </div>

    </div>
</template>

<script setup>

import '../assets/css/page/deposit.css'
import { inject, onMounted, ref, computed } from 'vue';
import { notificationSymbol } from '../tools/symbol';
import MemberNavBar from '../components/MemberNavBar.vue';
import RecentTransaction from '../components/RecentTransaction.vue';
import RemainingBalance from '../components/RemainingBalance.vue';
import TransactionNav from '../components/TransactionNav.vue';
import apiServices from '../services/apiServices';
import PaymentChannelSelectionFromChannelAccount from '../components/DropdownCollection/PaymentChannelSelectionFromChannelAccount.vue';
import { globalState } from '../store/globalState';

import { eventBus } from '../services/eventBus';

const notify = inject(notificationSymbol);
const paymentMethods = ref([]);
const paymentMethodSelected = ref(0);
const rate = ref(0);
const receiptName = ref('');
const paymentChannelSelected = ref('');
const paymentChannelAccount = ref([]);
const currencyExchangeList = ref([]);
const isCrypto = ref(false);
const isOnlinePayment = ref(false);

const channelAccountSelected = ref(null);
const transData = ref({
    playerId: globalState.user.id,
    amount: 0,
    paymentChannelAccountId: 0,
    currencyExchangeId: 0
});
const fileInput = ref(null);


function setPaymentMethod(value, name, item) {
    paymentMethodSelected.value = value;
    paymentChannelSelected.value = null;
    paymentChannelAccount.value = [];
    channelAccountSelected.value = null;

    // Check if the payment method name includes 'crypto', case-insensitive
    if (name.toLowerCase().includes("crypto") || name.toLowerCase().includes("usdt")) {
        isCrypto.value = true;
    } else {
        isCrypto.value = false;
    }
    if (item.isOnlinePayment) {
        isOnlinePayment.value = true;
        // Map currency code from currency exchange list
        const thbCurrency = currencyExchangeList.value.find(currency => currency.currencyCode === 'THB');
        
        if (thbCurrency) {
          setCurrencyExchange(thbCurrency.toUsdRate, thbCurrency.id);
        }
    } else {
        isOnlinePayment.value = false;
    }
}

function handlePaymentChannelUpdate(paymentChannelName) {
    paymentChannelSelected.value = paymentChannelName;
    getPaymentChannelAccountByChannel(paymentChannelName);
}

function setPaymentChannelAccount(value) {
    transData.value.paymentChannelAccountId = value.id;
    channelAccountSelected.value = value;

    if (isCrypto.value && currencyExchangeList.value.length > 0) {
    // Find the currency object with the name 'USDT' in the list, case-insensitively
    var currency = currencyExchangeList.value.find(x => x.currencyCode.toLowerCase() === 'usdt');

    if (currency) {
        setCurrencyExchange(currency.toUsdRate, currency.id);
    }

    if(isOnlinePayment.value){
        const thbCurrency = currencyExchangeList.value.find(currency => currency.currencyCode === 'THB');
     
        if (thbCurrency) {
        setCurrencyExchange(thbCurrency.toUsdRate, thbCurrency.id);
        }
    }
       
}

}


const clearForm = () => {
    // Reset payment method related states
    paymentMethodSelected.value = '';
    paymentChannelSelected.value = null;
    paymentChannelAccount.value = [];

    // Reset channel account selected state
    channelAccountSelected.value = null;

    // Reset transaction data
    transData.value = {
        playerId: globalState.user.id,
        amount: 0,

        currencyExchangeId: 0,
        // Add other fields as needed
    };

    // Reset file input
    if (fileInput.value) {
        fileInput.value.value = null; // Reset the file input element
    }
    receiptName.value = ''; // Clear the receipt name
};

// You can now use this function to clear the form whenever needed

const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
        const context = this;
        const args = arguments;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func.apply(context, args), delay);
    }
};

const submitTransction = debounce(async () => {
    try {
        if(isOnlinePayment.value){
        const thbCurrency = currencyExchangeList.value.find(currency => currency.currencyCode === 'THB');
     
        if (thbCurrency) {
            transData.value.currencyExchangeId = thbCurrency.id;
        }
    }

        const request = {
            playerId: transData.value.playerId,
            paymentChannelAccountId: transData.value.paymentChannelAccountId,
            Amount: transData.value.usdAmount,
            receiptFileExtension: transData.value.receiptFileExtension ? transData.value.receiptFileExtension : '',
            receiptFile: transData.value.receiptFileExtension ? transData.value.receiptFile : '',
            currencyExchangeId: transData.value.currencyExchangeId,
        }
        const response = await apiServices.createDepositTransaction(request);
        if (response.status === 200) {

            if(response.data.isOnlinePayment){
                window.location.href = response.data.paymentUrl;
            }
            else{
                notify('deposit_submittted', 'success');
            eventBus.emit('refresh');
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
            }
           

          

            clearForm();
        } else {
            console.error('error:', response);
        }

    } catch (error) {
        notify(error.response.data.message, 'error');
    }

}, 1000);


const copyToClipboard = async (text) => {
    try {
        await navigator.clipboard.writeText(text);
        notify('copied_to_clipboard', 'success');
    } catch (error) {
        notify('failed_to_copy', 'error');
    }
};

const isValidAmount = computed(() => {
    // Check if the amount is a positive number and not empty
    const amount = parseFloat(transData.value.amount);
    return !isNaN(amount) && amount > 0;
});

const isValidReceipt = computed(() => {
    // Check if receiptName is a non-empty string (not just whitespace)
    return receiptName.value.trim().length > 0;
});


const triggerFileInput = () => {
    if (fileInput.value) {
        fileInput.value.click(); // Trigger the file input click event to open the file dialog
    }
};

const formatInput = (value) => {
    const positiveNumberRegex = /^[0-9]*\.?[0-9]+$/;
    
    if (positiveNumberRegex.test(value) || value === '') {
        // Limiting input to 2 decimal places
        if(value){
            const num =  value.toFixed(2);
        const parts = num.split('.');
        if (parts.length > 1 && parts[1].length > 2) {
            // If more than 2 decimal places, truncate it
            return `${parts[0]}.${parts[1].slice(0, 2)}`;
        }

        return num;
        }else{
            return 0
        }
       
    }
};

const calculate = () => {
    transData.value.amount = formatInput(transData.value.usdAmount);
    // Formatting bet amount to 2 decimal places
    const positiveNumberRegex = /^[0-9]*\.?[0-9]+$/;
    if (positiveNumberRegex.test(transData.value.usdAmount) || transData.value.usdAmount === '') {

        if(rate.value > 0){

            transData.value.amount = (transData.value.usdAmount / rate.value).toFixed(2);
        isValidAmount.value = true;
        }else{
            transData.value.amount = 0;
        }
    } else {
        transData.value.amount = 0;
        isValidAmount.value = false
    }
};


async function getPaymentMethods() {
    try {
        const response = await apiServices.getPaymentMethods();
        paymentMethods.value = response.data.paymentMethods;
        filterPaymentMethods();
    } catch (error) {
        notify(error, 'error');
    }
}

async function getCurrencyExchange() {
    try {
        const response = await apiServices.getCurrencyExchangeList({ statusId: 1 });
        currencyExchangeList.value = response.data.currencyExchanges;
    } catch (error) {
        notify(error, 'error');
    }
}

const setCurrencyExchange = (value, id) => {
    rate.value = value;
    if (value > 0 && transData.value.usdAmount > 0) {
       
        transData.value.amount = (transData.value.usdAmount / rate.value).toFixed(2);
        transData.value.currencyExchangeId = id;

    } else {
        transData.value.amount = 0;
    }
};
const filteredPaymentMethods = ref([]);
const filterPaymentMethods = async () => {
    const promises = paymentMethods.value.map(async (paymentMethod) => {
        try {
            const response = await apiServices.getPaymentChannelAccounts({ paymentMethodId: paymentMethod.id });
            if (response.data.paymentChannelAccounts.length > 0) {
                return paymentMethod;
            }
        } catch (error) {
            console.error('Error fetching payment channel accounts:', error);
            // Optionally handle errors, e.g., by returning null or logging the error
        }
        return null; // Return null for payment methods with no accounts or in case of an error
    });

    // Wait for all promises to resolve
    const results = await Promise.all(promises);

    // Filter out null values (payment methods that have 0 paymentChannelAccounts or failed to fetch)
    filteredPaymentMethods.value = results.filter(method => method !== null);
};

async function getPaymentChannelAccountByChannel(paymentChannelName) {
    try {
        const response = await apiServices.getPaymentChannelAccounts();
        paymentChannelAccount.value = response.data.paymentChannelAccounts.filter(account =>
            account.paymentChannelName.toLowerCase().replace(/\s+/g, '') === paymentChannelName.toLowerCase().replace(/\s+/g, '')
        );

        if(isOnlinePayment.value){
        const thbCurrency = currencyExchangeList.value.find(currency => currency.currencyCode === 'THB');
     
        if (thbCurrency) {
        setCurrencyExchange(thbCurrency.toUsdRate, thbCurrency.id);
        }
    }
    } catch (error) {
        notify(error, 'error');
    }
}




const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    const maxSize = 5242880;

    if (file) {
        if (file.size > maxSize) {
            notify('file_upload_validation', 'error');
            return;
        }

        const reader = new FileReader();
        reader.onload = (e) => {
            receiptName.value = file.name;
            transData.value.receiptFile = e.target.result.split(',').pop();
            transData.value.receiptFileExtension = file.name.split('.').pop();
        };
        reader.readAsDataURL(file);
    }
};


onMounted(() => {
    getPaymentMethods();
    getCurrencyExchange();
});


</script>
<style scoped>
.custom-no {
    color: var(--primary-1);
    background-color: var(--secondary-3);
    border-radius: 50%;
    font-size: 12px;
    height: 10px;
    width: 10px;
    text-align: center;
    display: block;
    padding: 1px 5px 6px 5px;
    margin-right: 5px;
    margin-top: 2px;
}

.profile-section-title {
    text-transform: capitalize;
}

.button.button-primary {
    width: 60%;

}

.bank-method-tabber a span {
    margin-top: 0px;
    margin-bottom: 0px;
}

.bank-method-tabber a {

    padding: 10px;
}

.payment-method:hover {
    text-decoration: unset !important;
    border-bottom: 2px solid #caab72;
}

.bank-method-tabber {
    margin: 0 !important;
}

.deposit-page-container {
    padding: 0px 16px;
}

.m-t-1-4 {
    margin-top: 1.4em;
}

body #view-payment-accounts .payment-account-container {
    border-radius: 4px !important;
    margin: 10px 0;
}

.bank-method-tabber li {
    border-right: 2px solid var(--primary-2);
    padding: 5px 14px 5px 3px;
}

.bank-method-tabber li:last-child,
.bank-method-tabber li:last-of-type {
    border-right: none;
}

.button.button-primary.button-disabled {
    background-color: var(--secondary-5) !important;
}

.input-file-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
}

.content .content-wrapper .content-box,
::v-deep(.select-box__input-text) {
    background-color: var(--other-1) !important;
}

::v-deep(.select-box) {
    color: var(--primary-3) !important;
}

::v-deep(.input-area .input input, .input-area .input select),
::v-deep(.input-label),
.bank-method-tabber .active a span:before,
.bank-method-tabber a,
.profile-section-title {
    color: var(--primary-3) !important;
}
</style>