export default {
  "login": "Login",
  "logout": "Logout",
  "register": "Register",
  "are_u_member": "Are u member ?",
  "home": "Home",
  "sports": "Sports",
  "livecasino": "Live Casino",
  "casino": "Casino",
  "kenolotto": "Keno & Lotto",
  "promotions": "Promotions",
  "vip": "VIP",
  "darkmode": "Dark Mode",
  "lightmode": "Light Mode",
  "username": "Username",
  "password": "Password",
  "input_username": "Input your registered username",
  "input_password": "Type your password",
  "keep_login": "Keep me signed in",
  "create_new_account": "Create a new account!",
  "forgot_login_details": "Forgot your login details?",
  "not_yet_member": "Not a member yet?",
  "register_to": "REGISTER TO",
  "reg_msg_1": "  Please note that we do not permit members to own more than one (1) account.",
  "already_have_account": " Already have an account?",
  "login_here": "Login here!",
  "name": "Name",
  "how_address_u": "How do we address you?",
  "username_validation": "3-16 alphanumeric characters long",
  "password_validation": "6-30 characters long",
  "email": "E-Mail",
  "cancel": "Cancel",
  "update_account_details": "Update your account details",
  "bank_account_holder_name": "Bank Account Holder Name",
  "add_bank_account": "ADD NEW BANK ACCOUNT",
  "bank_name": "Bank Name",
  "bank_account_number": "Bank Account Number",
  "add_bank_verification_msg": "By clicking Save Account, I confirm that the provided bank account information is correct.",
  "save_account": "Save Account",
  "total_balances": "Total Balance",
  "date_time": "Date / Time",
  "account_profile": "Account Profile",
  "update_bank_details": " Update Bank Details",
  "cashier": "Cashier",
  "profile": "Profile",
  "history": "History",
  "deposit": "Deposit",
  "withdrawal": "Withdrawal",
  "slidding_announcement": "365WON anti-wave betting platform is officially launched! Invest wisely and your wealth will be unlimited! Leading the new future of sports betting",
  "refer_friend": "Refer Friend",
  "transfer": "Transfer",
  "statement": "Statement",
  "match_result": "Match Result",
  "bet_record": "Bets Record",
  "transaction_history": "Transactions",
  "running_bets": "Running Bets",
  "no_results": "No Results",
  "latest_statement": "Latest Statement",
  "total_bet_amount": "Total Bet Amount",
  "total_earned_profit": "Total Earned Profit",
  "transaction_type": "Transaction Type",
  "all": "All",
  "groupcommission": "Group Commission",
  "profitfrombet": "Profit from Bet",
  "adjustment": "Adjustment",
  "voidwithdrawal": "Void Withdrawal",
  "bet": "Bet",
  "voidbet": "Void Bet",
  "referralhold": "Referral Hold",
  "referralreturn": "Referral Return",
  "firstbethold": "First Bet Hold",
  "firstbetreturn": "First Bet Return",
  "pending": "Pending",
  "processing": "Processing",
  "approved": "Approved",
  "rejected": "Rejected",
  "transaction_status": "Transaction Status",
  "select_date_from": "Select Date From",
  "to": "To",
  "date_to_invalid": "Date From must be earlier than Date To.",
  "date": "Date",
  "transaction_id": "Transaction ID",
  "transaction_date": "Transaction Date",
  "kick_off_date": "Kick-off Time",
  "match": "Match",
  "score": "Score",
  "amount": "Amount",
  "profit": "Profit",
  "projected_profit": "Projected Profit",
  "status": "Status",
  "submit": "Submit",
  "odds": "Odds",
  "personal_details": "Personal details",
  "nickname": "Nickname",
  "date_of_birth": "Date of Birth",
  "date_of_birth_msg": "Providing your birthday is optional but recommended for promotional purposes",
  "mobile": "Mobile",
  "change_my_password": "Change my password",
  "old_password": "Old password",
  "enter_ur_old_password": "Enter your old password",
  "new_password": "New Password",
  "currency_code": "USD",
  "withdrawable": "Withdrawable",
  "hey": "Hey",
  "ur_membership_level_is": "Your Membership Level is",
  "method": "Method",
  "type": "Type",
  "deposit_step_2": "Select the 365 won bank account to deposit to",
  "deposit_step_3": "Once the transfer is completed, please fill the form below",
  "deposit_step_1": "Select the payment method to deposit to",
  "bank_account": "Bank Account",
  "bank": "Bank",
  "recent_transaction": "Recent Transactions",
  "upload": "Upload",
  "receipt": "Receipt",
  "deposit_submittted": "Deposit Submitted",
  "file_upload_validation": "File size should not exceed 5MB",
  "copied_to_clipboard": "Copied to clipboard!",
  "failed_to_copy": "Failed to copy to clipboard.",
  "withdrawal_submittted": "Withdrawal Submitted",
  "withdrawal_step_1": "Withdrawal to your desired bank",
  "transfer_step_1": "Transfer to your friend",
  "player_not_found": "Player does not exist!",
  "transfer_submitted": "Transfer Submitted",
  "register_success": "Registration has been completed !",
  "invalid_email": "Invalid email address",
  "register_failed": "Registration failed !",
  "login_failed": "Invalid login credential",
  "login_success": "Login Success ! ",
  "confirm_password": "Confirm Password",
  "password_validate_failed": "Password validation failed",
  "update_member_success": "Update Member Information Success",
  "confirm_match_new": "Confirm Password must match with New Password",
  "update_member_failed": "Update Member Information Failed",
  "session_expired": "Your session has expired. Please log in again.",
  "update_player_bank_success": "Bank Account has been updated.",
  "create_player_bank_success": "Bank Account has been created.",
  "correct_score": "Correct Score",
  "bet_now": "Bet Now",
  "see_all": "See All",
  "quick_easy": "It's quick and easy",
  "join_now": "Join 365 Won",
  "fast_deposit_time": "Secure with the fastest deposit times",
  "start_ur_journey": "Start your winning journey now!",
  "play": "Play",
  "all_market": "All Market",
  "ft_correct_score": "FT Correct Score",
  "ht_correct_score": "HT Correct Score",
  "available_volume": "Available Volume",
  "show_correct_score": "Show the correct score",
  "total_volume": "Total Volume",
  "hot": "Hot",
  "login_first": "Please login first",
  "affiliate": "affiliate",
  "about_us": "About Us",
  "commission": "Commission",
  "other_options": "Other Options",
  "bet_amount": "Bet Amount",
  "profit_amount": "Profit Amount",
  "u_are_betting": "You are betting",
  "bet_success": "Bet Success",
  "commission_transaction": "Commission Transaction",
  "aff_login_id": "Affiliate Login ID",
  "comm_type": "Commission Type",
  "commission_rate": "Commission Rate",
  "total_commission_earned": "Total Earned Commission",
  "affiiate_list": "Affiliate List",
  "member_id": "Member ID",
  "fullname": "Full Name",
  "membership_level": "Membership Level",
  "reg_date": "Registration Date",
  "direct_affiliate_list": "Direct Affiliate List",
  "total_num_of_aff_grp": "Total Number of Affiliate Group",
  "total_num_of_direct_aff": "Total Number of Direct Affiliate",
  "new_affiliate": "New Affiliate",
  "total_group_size": "Total Group Size",
  "full_time": "Full Time",
  "half_time": "Half Time",
  "jackpot_amount": "10000",
  "deposit_amount": "Deposit Amount",
  "deposit_currency": "Deposit Currency",
  "withdrawal_amount": "Withdrawal Amount",
  "withdrawal_currency": "Withdrawal Currency",
  "referral_link_copied": "Referral link copied !",
  "invite_via_link": "Invite Your Friend Via Referral Link",
  "direct_affiliate": "Direct Affiliate",
  "save_photo": "Save to photos",
  "share_my_code": "Share My Code",
  "qr_url_copied": "QR Code URL copied !",
  "referral": "Referral"
};