<template>
  <div class="main-menu">
    <div class="main-menu-content">
      <div v-for="item in authenticatedMenuItems" :key="item.id" :id="item.id" class="track-events"
        :class="{ selected: item.id === selectedMenuItem }" @click="$redirectTo(item.url);"
        :data-tracking-name="item.trackingName" :data-url="item.url">
        <span>{{ $t(item.name) }}</span>
        <div class="macro-menu" v-if="item.subMenu && item.subMenu.length">
          <div class="menu-content">

            <div v-for="subItem in item.subMenu" :key="subItem.url" class="submenu-item"
              @click="handleSubItemClick(subItem)" @click.stop>
              <img :src="`/images/`+subItem.img"
                class="card-image dropdown-img" alt="Rebate">
              {{ $t(subItem.name) }}

            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
  
<script setup>
import { ref, computed } from 'vue';
import { useRoute } from 'vue-router';
import { globalState } from '../store/globalState';
import redirectTo from '../services/generalFunction';

const currentRoute = useRoute();

const menuItems = ref([
  { id: "menu-item-index", name: "home", url: "/", requiresAuth: false, trackingName: "Home" },
  {
    id: "menu-item-sportsbook", name: "sports", url: "/sports", trackingName: "Sportsbook",
    subMenu: [
      { name: "correct_score", url: "/sports", img:'mmexport1709029766775.png'},
      { name: "running_bets", url: "/history", tabName: 'betrecord', requiresAuth: true , img:'mmexport1709029780330.png'},
      { name: "match_result", url: "/history", tabName: 'result', requiresAuth: true , img:'mmexport1709029769047.png'},
      { name: "latest_statement", url: "/history", requiresAuth: true , tabName: 'statement' , img:'mmexport1709029771860.png'},
    ]
  },
  {
    id: "menu-item-affiliate", name: "affiliate", url: "/affiliate", requiresAuth: true, trackingName: "affiliate",
    subMenu: [
      { name: "affiliate", url: "/affiliate", tabName: 'affiliate', requiresAuth: true, img:'mmexport1709029774851.png' },
      { name: "commission", url: "/commission", tabName: 'commission', requiresAuth: true, img:'mmexport1709029777543.png' },
      { name: "new_affiliate", url: "/affiliate", tabName: 'new_affiliate', requiresAuth: true , isShowAffReg: true, img:'mmexport1709029111867.png' },
    ]
  },
  {
    id: "menu-item-aboutus", name: "about_us", url: "/aboutus", requiresAuth: true, trackingName: "aboutus"
  },
]);

const selectedMenuItem = computed(() => {
  // Returns the ID of the menu item that matches the current route.
  const currentPath = currentRoute.path;
  const selectedItem = menuItems.value.find(item => item.url === currentPath);
  return selectedItem ? selectedItem.id : null;
});

const authenticatedMenuItems = computed(() => {
  // Filter menu items based on the authentication status
  return menuItems.value.map(item => {
    const filteredSubMenu = item.subMenu?.filter(subItem => !subItem.requiresAuth || globalState.isAuth);
    return {
      ...item,
      subMenu: filteredSubMenu
    };
  }).filter(item => !item.requiresAuth || globalState.isAuth);
});


const setActiveTab = (tabName) => {
  localStorage.setItem('activeTab', tabName);
};

const handleSubItemClick = (subItem) => {
  // If there's a tabName in the subItem, set the active tab
  if (subItem.tabName) {
    setActiveTab(subItem.tabName);
  }

  if (subItem.isShowAffReg) {
     globalState.isAffRegShow = true;
  }
  // Redirect to the subItem's URL
  redirectTo(subItem.url);
};

</script>
  
<style scoped>
.track-events {
  cursor: pointer;
  /* Additional styles for track-events */
}

.macro-menu {
  display: none;
  position: absolute;
  width: 100%;
  /* Adjust as necessary */
  top: 100%;
  /* Adjust if needed to position the submenu */
  left: 0;
  z-index: 1000;
  /* Ensure it's above other content */
  /* Add more styling as per your design */
}

.track-events:hover .macro-menu {
  max-height: 590px;
  padding-top: var(--spacing-20);
}

@media (min-width: 1023.5px) {
  .track-events:hover .macro-menu .menu-content {
    height: max-content;
    padding: var(--spacing-32) var(--spacing-32) var(--spacing-8);
  }
}

.submenu-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  cursor: pointer;
}

.dropdown-img {
  width: 150px;
  margin-bottom: 10px;
}

.submenu-item>.dropdown-img {
  transition: transform 0.3s ease;
}

.submenu-item:hover>.dropdown-img {
  transform: scale(1.2);
}
</style>
  