<template>
    <div class="input-area input-label-left mb-16 mt-16">

        <div class="input-label " id="addPaymentAccount-bankname">
            {{ $t('bank') }}</div>
        <div class="input-wrapper">
            <div class="select-box">

                <div class="select-box__current" tabindex="1">


                    <div class="select-box__value" v-show="paymentChannelId == option.id" v-for="option in filteredBanks" :key="option.id">
                        <input class="select-box__input" type="radio" :id="'filteredPaymentChannels_' + option.id">
                        <p class="select-box__input-text">{{ option.name }}</p>
                    </div>


                </div>

                <div class="select-box__list">
                    <div class="select-box-list-options-container">


                        <label @click="setPaymentChannel(option.id)" class="select-box__option"
                            v-for="option in filteredBanks" :key="option.id" :for="'filteredPaymentChannels_' + option.id"
                            aria-hidden="true">
                            <div class="option-text">
                                <p class="md ml-8">{{ option.name }}</p>
                            </div>
                        </label>


                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue';
import apiServices from '../../services/apiServices';

export default {
    props: {
        value: Number,
        method: String,
    },
    setup(props, context) {
        const paymentChannelId = ref(0);
        const paymentChannels = ref(null);
        const selectedBank = ref('');
        const searchQuery = ref('');

        const setPaymentChannel = (value) => {
            paymentChannelId.value = value;
            context.emit('payment-channel-update', paymentChannelId.value);
        };

        watch(() => props.value, (newValue) => {
            paymentChannelId.value = newValue;
            let attempts = 0;
            const intervalId = setInterval(() => {
                const optionElement = document.querySelector(`#filteredPaymentChannels_${newValue}`);
                if (optionElement) {
                    clearInterval(intervalId);
                  
                    optionElement.click();
                } else {
                    attempts++;
                    if (attempts >= 10) {
                        clearInterval(intervalId);
                    }
                }
            }, 300);
        });



        const getPaymentChannels = async () => {
            try {
                const response = await apiServices.getPaymentChannels();
                paymentChannels.value = response.data.paymentChannels;

            } catch (error) {
                console.error('error:', error);
            }
        };

        const filteredBanks = computed(() => {
            if (!paymentChannels.value) return [];

            // If searchQuery has a value, filter by searchQuery; otherwise, return all channels
            let result = searchQuery.value
                ? paymentChannels.value.filter(bank =>
                    bank.name.toLowerCase().includes(searchQuery.value.toLowerCase()))
                : paymentChannels.value;

            // Further filter by method if it has a value
            if (props.method) {
                result = result.filter(bank => bank.paymentMethodName === props.method);
            }

            return result;
        });

        onMounted(() => {
            getPaymentChannels();
        });

        return {
            paymentChannelId,
            paymentChannels,
            selectedBank,
            searchQuery,
            setPaymentChannel,
            filteredBanks
        };
    }
};
</script>