export default {
  "login": "登入",
  "logout": "登出",
  "register": "註冊",
  "are_u_member": "你是会员吗？",
  "home": "主頁",
  "sports": "體育",
  "livecasino": "綫上賭場",
  "casino": "賭場",
  "kenolotto": "基諾和樂透",
  "promotions": "优惠",
  "vip": "貴賓",
  "darkmode": "深色模式",
  "lightmode": "浅色模式",
  "username": "用戶名",
  "password": "密碼",
  "input_username": "輸入用戶名",
  "input_password": "輸入密碼",
  "keep_login": "保持登錄状态",
  "create_new_account": "注冊新用戶",
  "forgot_login_details": "忘記登錄詳情？",
  "not_yet_member": "還不是會員？",
  "register_to": "注冊",
  "reg_msg_1": "請注意，我們不允許會員擁有超過一 (1) 個帳戶。",
  "already_have_account": "已經有帳戶？",
  "login_here": "在此登入！",
  "name": "姓名",
  "how_address_u": "怎麼稱呼你？",
  "username_validation": "長度為 3-16 個字母數字字符",
  "password_validation": "長度為 6-30 個字符",
  "email": "電子郵件",
  "cancel": "取消",
  "update_account_details": "更新您的帳戶詳細信息",
  "bank_account_holder_name": "銀行帳戶持有人姓名",
  "add_bank_account": "新增銀行帳戶",
  "bank_name": "銀行名",
  "bank_account_number": "銀行帳號",
  "add_bank_verification_msg": "透過點擊“儲存帳戶”，我確認所提供的銀行帳戶資訊是正確的。",
  "save_account": "儲存帳戶",
  "total_balances": "總餘額",
  "date_time": "時間",
  "account_profile": "帳戶資料",
  "update_bank_details": "更新銀行詳細信息",
  "cashier": "交易",
  "profile": "簡介",
  "history": "歷史",
  "deposit": "存款",
  "withdrawal": "提款",
  "slidding_announcement": "365WON反波胆体育赛事平台正式上线！投资有道，财富无限！引领体育竞彩新未来",
  "refer_friend": "推薦朋友",
  "transfer": "轉入",
  "statement": "报表",
  "match_result": "比賽結果",
  "bet_record": "投注記錄",
  "transaction_history": "交易記錄",
  "running_bets": "進行中投注",
  "no_results": "未找到結果",
  "latest_statement": "最新报表",
  "total_bet_amount": "總投注金額",
  "total_earned_profit": "利潤總額",
  "transaction_type": "交易類型",
  "all": "全部",
  "groupcommission": "集團佣金",
  "profitfrombet": "投注獲利",
  "adjustment": "調整",
  "voidwithdrawal": "無效提款",
  "bet": "賭注",
  "voidbet": "無效賭注",
  "referralhold": "推薦保证金",
  "referralreturn": "推薦回報",
  "firstbethold": "首注保留",
  "firstbetreturn": "首注返還",
  "pending": "待辦的",
  "processing": "處理中",
  "approved": "批准",
  "rejected": "駁回",
  "transaction_status": "交易狀態",
  "select_date_from": "選擇日期",
  "to": "到",
  "date_to_invalid": "起始日期必須早於終止日期。",
  "date": "日期",
  "transaction_id": "交易ID",
  "transaction_date": "交易日期",
  "kick_off_date": "開球時間",
  "match": "比賽",
  "score": "分數",
  "amount": "數額",
  "profit": "利潤",
  "projected_profit": "預計利潤",
  "status": "狀態",
  "submit": "提交",
  "odds": "賠率",
  "personal_details": "個人資料",
  "nickname": "暱稱",
  "date_of_birth": "出生日期",
  "date_of_birth_msg": "提供您的生日是可選的，但建議出於促銷目的",
  "mobile": "手機號碼",
  "change_my_password": "修改我的密碼",
  "old_password": "舊密碼",
  "enter_ur_old_password": "輸入您的舊密碼",
  "new_password": "新密碼",
  "currency_code": "USD",
  "withdrawable": "可提款",
  "hey": "你好",
  "ur_membership_level_is": "您的會員等級是",
  "method": "方法",
  "type": "種類",
  "deposit_step_2": "選擇存入365 Won 的銀行帳戶",
  "deposit_step_3": "轉帳完成後，請填寫以下表格",
  "deposit_step_1": "選擇要存入的付款方式",
  "bank_account": "銀行帳戶持有人姓名",
  "bank": "銀行",
  "recent_transaction": "最近交易",
  "upload": "上傳",
  "receipt": "收據",
  "deposit_submittted": "存款已提交",
  "file_upload_validation": "文件大小不得超過 5MB",
  "copied_to_clipboard": "已複製到剪貼簿！",
  "failed_to_copy": "無法複製到剪貼簿。",
  "withdrawal_submittted": "提款已提交",
  "withdrawal_step_1": "提款至您指定的銀行",
  "transfer_step_1": "轉給你的朋友",
  "player_not_found": "玩家不存在！",
  "transfer_submitted": "轉帳已提交",
  "register_success": "註冊已完成！",
  "invalid_email": "無效的郵件地址",
  "register_failed": "註冊失敗 ！",
  "login_failed": "登入憑證無效",
  "login_success": "登入成功！",
  "confirm_password": "確認密碼",
  "password_validate_failed": "密碼驗證失敗",
  "update_member_success": "更新會員資訊成功",
  "confirm_match_new": "確認密碼必須與新密碼匹配",
  "update_member_failed": "更新會員資訊失敗",
  "session_expired": "已過期。 請重新登入。",
  "update_player_bank_success": "銀行帳戶已更新。",
  "create_player_bank_success": "銀行帳戶已建立。",
  "correct_score": "波胆",
  "bet_now": "立即投注",
  "see_all": "看全部",
  "quick_easy": "又快又簡單",
  "join_now": "加入 365 Won",
  "fast_deposit_time": "以最快的存款時間確保安全",
  "start_ur_journey": "現在就開始你的致勝之旅吧！",
  "play": "玩",
  "all_market": "所有市場",
  "ft_correct_score": "FT正確分數",
  "ht_correct_score": "HT正確分數",
  "available_volume": "可用量",
  "show_correct_score": "顯示波胆",
  "total_volume": "總成交量",
  "hot": "熱門",
  "login_first": "請先登入",
  "affiliate": "推荐人",
  "about_us": "關於我們",
  "commission": "佣金",
  "other_options": "其他选项",
  "bet_amount": "投注金額",
  "profit_amount": "利潤金額",
  "u_are_betting": "你正在投注",
  "bet_success": "投注成功",
  "commission_transaction": "佣金交易",
  "aff_login_id": "會員登入 ID",
  "comm_type": "佣金類型",
  "commission_rate": "佣金率",
  "total_commission_earned": "賺取的佣金總額",
  "affiiate_list": "會員列表",
  "member_id": "會員ID",
  "fullname": "全名",
  "membership_level": "會員等級",
  "reg_date": "註冊日期",
  "direct_affiliate_list": "直接附屬機構列表",
  "total_num_of_aff_grp": "附屬團體總數",
  "total_num_of_direct_aff": "直接附屬公司總數",
  "new_affiliate": "新會員",
  "total_group_size": "團體總規模",
  "full_time": "全場",
  "half_time": "半場",
  "jackpot_amount": "10000",
  "deposit_amount": "存款金額",
  "deposit_currency": "存款貨幣",
  "withdrawal_amount": "提款金額",
  "withdrawal_currency": "提款貨幣",
  "referral_link_copied": "推薦連結已複製！",
  "invite_via_link": "透過推薦連結邀請您的朋友",
  "direct_affiliate": "直接附屬機構",
  "save_photo": "儲存到照片",
  "share_my_code": "分享我的程式碼",
  "qr_url_copied": "二維碼網址已複製！",
  "referral": "推薦"
};