import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../Pages/HomePage.vue';
import SportsPage from '../Pages/SportsPage.vue';
import ProfilePage from '../Pages/ProfilePage.vue';
import BankDetailPage from '../Pages/BankDetailPage.vue';
import DepositPage from '../Pages/DepositPage.vue';
import TransferPage from '../Pages/TransferPage.vue';
import WithdrawalPage from '../Pages/WithdrawalPage.vue';
import HistoryPage from '../Pages/HistoryPage.vue';
import CommissionPage from '../Pages/CommissionPage.vue';
import AffiliateListPage from '../Pages/AffiliateListPage.vue';
import ReferralPage from '../Pages/ReferralPage.vue';
import ReferralRedirectPage from '../Pages/[ReferralCode].vue';
import PaymentProcessingPage from '../Pages/PaymentProcessingPage.vue';
import { globalState, setAuthStatus, logout } from '../store/globalState';

import { eventBus } from '../services/eventBus';
import apiServices from '../services/apiServices';

function isAuthenticated() {
  // Replace this with your actual authentication logic
  return !!localStorage.getItem('token');
}

// Define your routes here
const routes = [
  { path: '/', name: 'Home', component: HomeView, meta: { title: '365 Won' } },
  { path: '/processingdeposit/:transactionId', name: 'Payment', component: PaymentProcessingPage, meta: { title: 'Payment', requiresAuth: true } },
  { path: '/sports', name: 'Sports', component: SportsPage, meta: { title: 'Sport Page' } },
  { path: '/profile', name: 'Profile', component: ProfilePage, meta: { title: 'Profile', requiresAuth: true } },
  { path: '/affiliate', name: 'Affiliate List', component: AffiliateListPage, meta: { title: 'Affiliate List', requiresAuth: true } },
  { path: '/bankdetails', name: 'Bank Detail', component: BankDetailPage, meta: { title: 'Bank Detail', requiresAuth: true } },
  { path: '/deposit', name: 'Deposit', component: DepositPage, meta: { title: 'Deposit', requiresAuth: true } },
  { path: '/transfer', name: 'Transfer', component: TransferPage, meta: { title: 'Transfer', requiresAuth: true } },
  { path: '/withdrawal', name: 'Withdrawal', component: WithdrawalPage, meta: { title: 'Withdrawal', requiresAuth: true } },
  { path: '/commission', name: 'Commission', component: CommissionPage, meta: { title: 'Commission', requiresAuth: true } },
  { path: '/history', name: 'History', component: HistoryPage, meta: { title: 'History', requiresAuth: true } },
  { path: '/referafriend', name: 'Refer Friend', component: ReferralPage, meta: { title: 'Refer Friend', requiresAuth: true } },
  { path: '/referral/:ReferralCode', name: 'Referral Redirect', component: ReferralRedirectPage, meta: { title: 'Referral Redirect', requiresAuth: false } },
  { path: '/home', redirect: '/' },
  // add more routes as needed
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    // Always scroll to top on route change
    return { top: 0 };
  },
});



// Function to check session validity
function checkSession() {
  return apiServices.checkPlayerSession().then(async response => {
    if (response.status === 200) {
      globalState.user = response.data;
      setAuthStatus(true);
      const memberWalletRes = await apiServices.getMemberWalletById({ id: globalState.user.id });
      globalState.balance = memberWalletRes.data.walletBalance;
      globalState.timezoneOffset = response.data.timezoneOffset;
    }
    return response.status === 200;
  }).catch(() => {
    return false;
  });
}


router.beforeEach(async (to, from, next) => {
  // Set loading state and document title
  globalState.isLoading = true;
  globalState.isShowSideMenu = false;
  // Check if the route requires authentication
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isValidSession = await checkSession();
  if (requiresAuth && !isValidSession) {
    eventBus.emit('session-expired');
    if(isAuthenticated()){
      logout();
    }
    
    next('/home');
    return;
  }

  // Check if user is authenticated
  if (requiresAuth && !isAuthenticated() && !isValidSession) {
    next('/home'); 
  } else {
    next(); // Proceed to route
  }
});

router.afterEach(() => {
  eventBus.emit('languageChanged');
  globalState.isLoading = false; // Stop loading
});

export default router;
