<template>
    <div class="dynamic-content">
        <div class="content area">
            
            <div class="content-wrapper">
                <div>
                    <div class="profile-section-title">{{ $t('affiiate_list') }}
                        
                </div>
                <button class="button sm md-tablet button-primary reg-aff" id="desktop-join-now-btn"  @click="showAffRegisterPopup">
                    <span style="vertical-align: inherit;">
                        <span style="vertical-align: inherit;">{{ $t('register') }} ({{ $t('affiliate') }})</span>
                    </span>
                </button>
                </div>
                <div class="content-box full-width border-box">

                    <div class="rewards-cards">
                        <div class="rewards-cards-content">

                            <div class="card radius-left">
                                <div class="text sm title uppercase title-mobile-view" data-qa-type="message"
                                    data-qa-context="LoyaltyCurrentVipLevel">
                                    {{ $t('ur_membership_level_is') }}
                                </div>
                                <div class="text md uppercase bold text-mobile-view">
                                    {{ globalState.user.membershipName }} </div>
                            </div>

                            <div class="card">
                                <div class="text sm title uppercase title-mobile-view" data-qa-type="message"
                                    data-qa-context="LoyaltyTotalVIPPoints">
                                  {{ $t('total_num_of_direct_aff') }}
                                </div>
                                <div id="vipPoints" class="text md uppercase bold text-mobile-view">
                                {{ totalBetAmount }}
                                </div>
                            </div>

                            <div class="card radius-right">
                                <div class="text sm title uppercase title-mobile-view" data-qa-type="message"
                                    data-qa-context="LoyaltyRedeemableCashTitle">
                                    {{ $t('total_group_size') }}
                                </div>

                                <div id="vipCashable" class="text md uppercase bold uppercase text-mobile-view">
                                  
                                    {{ totalProfitAmount }} </div>

                                <div>
                                    <input type="hidden" id="redemption-rate-raw" value="13">
                                </div>
                            </div>

                        </div>
                    </div>
                    <div id="HistoryFundTransferPlaceholder"
                        class="table-placeholder  align-items-center  mt-32 mr-0-desktop ml-0-desktop"
                        style="overflow-x: auto;">
                        <table class="responsive-table full-width ml-0 mr-0">
                            <thead class="color-primary-1 background-color-other-1">
                                <tr>
                                    <th class="table-header background-color-primary-3 color-other-1 bold p-12"
                                        colspan="10">
                                        {{ $t('direct_affiliate_list') }}

                                    </th>
                                </tr>

                            </thead>
                            <tbody>
                                <tr class="hide-on-mobiledynamic-table-row">
                                    <td class="hide-on-mobile first-column"><strong class="p-12"> {{
                                        $t('member_id') }}</strong></td>
                                    <td class="hide-on-mobile"><strong class="p-12">{{ $t('fullname') }}</strong></td>
                                    <td class="hide-on-mobile"><strong class="p-12">{{ $t('membership_level') }}</strong></td>
                                    <td class="hide-on-mobile"><strong class="p-12">{{ $t('commission_rate') }}</strong></td>
                                    <td class="hide-on-mobile"><strong class="p-12">{{ $t('reg_date') }}</strong></td>
                                 </tr>

                                <tr class="dynamic-table-row" v-for="item in items" :key="item.id">
                                    <td class="responsive-td-display auto-height d-flex d-none-desktop flex-column align-items-flex-start background-color-primary-11 justify-content-center p-16"
                                        id="date-time-data" data-title="Date/Time">
                                        <div class="responsive-columns d-flex flex-column full-width">
                                            <div class="flex-row-one d-flex align-items-center justify-content-flex-start">
                                                <span class="light-text color-primary-5">{{ $t('reg_date') }}</span>
                                                <span class="light-text color-primary-5 hide-on-mobile">{{
                                                    $t('member_id')
                                                }}</span>

                                            </div>
                                            <div
                                                class="flex-row-one-data d-flex align-items-center justify-content-flex-start">
                                                <strong>{{ convertToTimeZoneAndFormat(item.createdAt) }}</strong>
                                                <strong class="hide-on-mobile">{{ item.id }}</strong>

                                            </div>
                                            <div
                                                class="flex-row-on-mobile up-to-tablet align-items-center justify-content-flex-start mt-16">
                                                <span class="light-text color-primary-5">{{ $t('fullname') }}</span>
                                            </div>
                                            <div
                                                class="flex-row-on-mobile up-to-tablet align-items-center justify-content-flex-start">
                                                <strong> {{ item.username }}</strong>
                                            </div>

                                            <div
                                                class="flex-row-no-gap d-flex align-items-center justify-content-flex-start mt-16">
                                                <span class="light-text color-primary-5">{{ $t('commission_rate') }}</span>
                                                <span class="light-text color-primary-5">{{ $t('membership_level') }}</span>
                                            </div>
                                            <div
                                                class="flex-row-no-gap-data d-flex align-items-center justify-content-flex-start">
                                                <strong>  &nbsp;{{ formatNumber(item.commissionRate)
                                                }}</strong>
                                                <strong>
                                                    {{ item.membershipName  }}
                                                </strong>
                                            </div>
                                        </div>
                                    </td>


                                    <td class="hide-on-mobile mobile-details">
                                        <div class="history-datetime-transactionId-td m-12 number">{{ item.id }}</div>
                                    </td>
                                 

                                    <td class="hide-on-mobile mobile-details" data-title="Match">
                                        <div class="history-datetime-transactionId-td m-12 number">{{ item.username }}
                                        </div>
                                    </td>
                                    <td class="hide-on-mobile mobile-details" data-title="Score">
                                        <div class="history-datetime-transactionId-td m-12 number">{{ item.membershipName }}
                                        </div>
                                    </td>
                                    <td class="hide-on-mobile mobile-details" data-title="Amount">
                                        <div class="history-datetime-transactionId-td m-12 number"> {{ formatNumber(item.commissionRate) }}
                                        </div>
                                    </td>

                                    <td class="hide-on-mobile  hide-in-details" id="date-time-data" data-title="Date/Time">
                                        <div class="m-12"><span class="date"><strong>{{
                                            convertToTimeZoneAndFormat(item.createdAt)
                                        }}</strong></span>
                                        </div>
                                    </td>
                                </tr>


                                <tr v-if="items.length === 0">
                                    <td data-title="No records" colspan="107" class="text-align-center display-reverted">
                                        {{ $t('no_results') }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>

        </div>
    </div>
</template>
<script setup>
import { onMounted, ref, onUnmounted } from 'vue';
import { globalState } from '../store/globalState';
import '../assets/css/page/fundtransfer.css';
import apiServices from '../services/apiServices';
import { convertToTimeZoneAndFormat , formatNumber } from '../services/generalFunction';
import {eventBus} from '../services/eventBus';

const totalBetAmount = ref(0);
const totalProfitAmount = ref(0);
const items = ref([]);
const referralInfo = ref([]);

const animateNumber = (finalNumber, duration, targetProperty) => {
    let currentNumber = 0;
    const stepTime = 50;
    const totalSteps = duration / stepTime;
    const increment = finalNumber / totalSteps;
    const interval = setInterval(() => {
        currentNumber += increment;
        if (currentNumber >= finalNumber) {
            clearInterval(interval);
            targetProperty.value = finalNumber;
        } else {
            targetProperty.value = Math.round(currentNumber);
        }
    }, stepTime);
};

const calculateTotalBetAmount = () => {
    return items.value.reduce((total) => total + 1, 0);
};

const calculateTotalEarnedProfit = () => {
    return referralInfo.value.reduce((total, item) => total + parseFloat(item.groupSize)  , 0);
};

const showAffRegisterPopup =() => {
    globalState.isAffRegShow = true;
}


onMounted(() => {
    fetchPlayerReferralInfo();
    getStatement();
    eventBus.on('refreshAffMember', getStatement);
});

onUnmounted(() => {
    // Cleanup our listener when the component is unmounted
    eventBus.off('refreshAffMember', getStatement);
});

const getStatement = async () => {
    const params = {
        id: globalState.user.id,
    };

    const response = await apiServices.getPlayerReferreeListbyId(params);
    items.value = response.data.playerReferees;

    const totalBet = calculateTotalBetAmount();
    const totalProfit = calculateTotalEarnedProfit();

    animateNumber(totalBet, 1000, totalBetAmount);
    animateNumber(totalProfit, 1000, totalProfitAmount);
}

const fetchPlayerReferralInfo = async () => {
      if (globalState.user.id) {
        try {
          const response = await apiServices.getPlayerReferralInfoById({ id: globalState.user.id });
          referralInfo.value = [response.data]; 

        } catch (error) {
          console.error(error.response.data.message);
        }
      }
    }
</script>
<style scoped>
.input-area .input .icon.icon-calendar {
    background-color: unset !important;
}

.content .content-wrapper .responsive-table tbody tr td .date,
.content .content-wrapper .responsive-table tbody tr td .number {
    white-space: nowrap;
}

.table-placeholder {
    display: block !important;
}

.content .content-wrapper .responsive-table tbody tr td {
    white-space: nowrap;
}

.content{
    padding-top: 10px;;
}

.match-sched-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 5px 20px;
    margin-bottom: 20px;
    text-align: center;
}

.match-sched-card span {
    margin: 0;
    font-size: 13px;
    float: left;
    padding-top: 5px;
    margin-left: -8px;
}

.total-amount {
    font-size: 26px;
    float: right;
    padding: 10px;
    margin-top: 1.4em;
}

.card-container {
    justify-content: flex-start;
    gap: 20px;
}

.profile-section-title {
    margin-top: 0.8em;
    margin-bottom: 10px;
}

.profile-section-title:after {
    content: "";
    background-color: var(--secondary-3);
    display: flex;
    width: 24px;
    height: 2px;
    margin-top: var(--spacing-12);
    z-index: 9;
}

.reg-aff{
    float: right;
    margin-bottom: 10px;
}

.content .content-wrapper .content-box,
::v-deep(.select-box__input-text) {
    background-color: var(--other-1)  !important; 
}
</style>