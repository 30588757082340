export default {
  "login": "Đăng nhập",
  "logout": "Đăng xuất",
  "register": "Đăng ký",
  "are_u_member": "Bạn có phải là thành viên không?",
  "home": "Trang chủ",
  "sports": "Các môn thể thao",
  "livecasino": "Sòng bạc trực tiếp",
  "casino": "Sòng bạc",
  "kenolotto": "Keno & xổ số",
  "promotions": "Khuyến mãi",
  "vip": "CAO CẤP",
  "darkmode": "Chế độ tối",
  "lightmode": "Chế độ sáng",
  "username": "tên tài khoản",
  "password": "Mật khẩu",
  "input_username": "Nhập tên người dùng đã đăng ký của bạn",
  "input_password": "Nhập mật khẩu của bạn",
  "keep_login": "Giữ tôi đăng nhập",
  "create_new_account": "Tạo tài khoản mới!",
  "forgot_login_details": "Quên thông tin đăng nhập của bạn?",
  "not_yet_member": "Chưa phải là thành viên?",
  "register_to": "ĐĂNG KÝ VÀO",
  "reg_msg_1": "  Xin lưu ý rằng chúng tôi không cho phép thành viên sở hữu nhiều hơn một (1) tài khoản.",
  "already_have_account": " Bạn co săn san để tạo một tai khoản?",
  "login_here": "Đăng nhập tại đây!",
  "name": "Tên",
  "how_address_u": "Chúng tôi xưng hô với bạn như thế nào?",
  "username_validation": "dài 3-16 ký tự chữ và số",
  "password_validation": "dài 6-30 ký tự",
  "email": "E-mail",
  "cancel": "Hủy bỏ",
  "update_account_details": "Cập nhật chi tiết tài khoản của bạn",
  "bank_account_holder_name": "Tên chủ tài khoản ngân hàng",
  "add_bank_account": "THÊM TÀI KHOẢN NGÂN HÀNG MỚI",
  "bank_name": "Tên ngân hàng",
  "bank_account_number": "Số tài khoản ngân hàng",
  "add_bank_verification_msg": "Bằng cách nhấp vào Lưu tài khoản, tôi xác nhận rằng thông tin tài khoản ngân hàng được cung cấp là chính xác.",
  "save_account": "Lưu tài khoản",
  "total_balances": "Tổng số dư",
  "date_time": "Ngày giờ",
  "account_profile": "Thông tin tài khoản cá nhân",
  "update_bank_details": " Cập nhật chi tiết ngân hàng",
  "cashier": "Thu ngân",
  "profile": "Hồ sơ",
  "history": "Lịch sử",
  "deposit": "Tiền gửi",
  "withdrawal": "Rút tiền",
  "slidding_announcement": "Nền tảng cá cược chống sóng 365WON chính thức ra mắt! Đầu tư khôn ngoan và sự giàu có của bạn sẽ là không giới hạn! Dẫn đầu tương lai mới của cá cược thể thao",
  "refer_friend": "Giới thiệu bạn bè",
  "transfer": "Chuyển khoản",
  "statement": "Tuyên bố",
  "match_result": "Kết quả trận đấu",
  "bet_record": "Bản ghi cược",
  "transaction_history": "Giao dịch",
  "running_bets": "Đang chạy cược",
  "no_results": "Ko có kết quả",
  "latest_statement": "Tuyên bố mới nhất",
  "total_bet_amount": "Tổng số tiền đặt cược",
  "total_earned_profit": "Tổng lợi nhuận kiếm được",
  "transaction_type": "Loại giao dịch",
  "all": "Tất cả",
  "groupcommission": "Hoa hồng nhóm",
  "profitfrombet": "Lợi nhuận từ việc đặt cược",
  "adjustment": "Điều chỉnh",
  "voidwithdrawal": "Rút tiền vô hiệu",
  "bet": "Cá cược",
  "voidbet": "Cược vô hiệu",
  "referralhold": "Giữ giới thiệu",
  "referralreturn": "Trả lại giới thiệu",
  "firstbethold": "Giữ cược đầu tiên",
  "firstbetreturn": "Hoàn trả cược đầu tiên",
  "pending": "Chưa giải quyết",
  "processing": "Xử lý",
  "approved": "Tán thành",
  "rejected": "Vật bị loại bỏ",
  "transaction_status": "Trạng thái giao dịch",
  "select_date_from": "Chọn ngày từ",
  "to": "ĐẾN",
  "date_to_invalid": "Ngày Từ phải sớm hơn Ngày Đến.",
  "date": "Ngày",
  "transaction_id": "ID giao dịch",
  "transaction_date": "Ngày Giao dịch",
  "kick_off_date": "Thời gian bắt đầu",
  "match": "Cuộc thi đấu",
  "score": "Điểm",
  "amount": "Số lượng",
  "profit": "Lợi nhuận",
  "projected_profit": "Lợi nhuận dự kiến",
  "status": "Trạng thái",
  "submit": "Nộp",
  "odds": "Tỷ lệ cược",
  "personal_details": "Thông tin cá nhân",
  "nickname": "Tên nick",
  "date_of_birth": "Ngày sinh",
  "date_of_birth_msg": "Cung cấp ngày sinh của bạn là tùy chọn nhưng nên dùng cho mục đích quảng cáo",
  "mobile": "Điện thoại di động",
  "change_my_password": "Thay đổi mật khẩu của tôi",
  "old_password": "Mật khẩu cũ",
  "enter_ur_old_password": "Nhập mật khẩu cũ của bạn",
  "new_password": "mật khẩu mới",
  "currency_code": "USD",
  "withdrawable": "Có thể rút",
  "hey": "Chào",
  "ur_membership_level_is": "Cấp độ thành viên của bạn là",
  "method": "Phương pháp",
  "type": "Kiểu",
  "deposit_step_2": "Chọn tài khoản ngân hàng 365 won để gửi vào",
  "deposit_step_3": "Sau khi quá trình chuyển hoàn tất, vui lòng điền vào mẫu dưới đây",
  "deposit_step_1": "Chọn phương thức thanh toán để gửi tiền vào",
  "bank_account": "Tài khoản ngân hàng",
  "bank": "Ngân hàng",
  "recent_transaction": "Giao dịch gần đây",
  "upload": "Tải lên",
  "receipt": "Biên lai",
  "deposit_submittted": "Đã gửi tiền gửi",
  "file_upload_validation": "Kích thước tệp không được vượt quá 5 MB",
  "copied_to_clipboard": "Sao chép vào clipboard!",
  "failed_to_copy": "Không sao chép được vào bảng nhớ tạm.",
  "withdrawal_submittted": "Đã gửi rút tiền",
  "withdrawal_step_1": "Rút tiền về ngân hàng bạn mong muốn",
  "transfer_step_1": "Chuyển cho bạn bè của bạn",
  "player_not_found": "Người chơi không tồn tại!",
  "transfer_submitted": "Đã gửi chuyển khoản",
  "register_success": "Đăng ký đã hoàn tất!",
  "invalid_email": "địa chỉ email không hợp lệ",
  "register_failed": "Đăng ký không thành công!",
  "login_failed": "Thông tin đăng nhập không hợp lệ",
  "login_success": "Đăng nhập thành công !",
  "confirm_password": "Xác nhận mật khẩu",
  "password_validate_failed": "Xác thực mật khẩu không thành công",
  "update_member_success": "Cập nhật thông tin thành viên thành công",
  "confirm_match_new": "Xác nhận Mật khẩu phải khớp với Mật khẩu mới",
  "update_member_failed": "Cập nhật thông tin thành viên không thành công",
  "session_expired": "Phiên của bạn đã hết hạn. Xin vui lòng đăng nhập lại.",
  "update_player_bank_success": "Tài khoản ngân hàng đã được cập nhật.",
  "create_player_bank_success": "Tài khoản ngân hàng đã được tạo.",
  "correct_score": "Điểm chính xác",
  "bet_now": "Đặt cược ngay",
  "see_all": "Nhìn thấy tất cả",
  "quick_easy": "Thật nhanh chóng và dễ dàng",
  "join_now": "Tham gia 365 won",
  "fast_deposit_time": "An toàn với thời gian gửi tiền nhanh nhất",
  "start_ur_journey": "Hãy bắt đầu hành trình chiến thắng của bạn ngay bây giờ!",
  "play": "Chơi",
  "all_market": "Tất cả thị trường",
  "ft_correct_score": "Điểm chính xác của FT",
  "ht_correct_score": "Điểm HT đúng",
  "available_volume": "Khối lượng có sẵn",
  "show_correct_score": "Hiển thị điểm chính xác",
  "total_volume": "Tổng khối lượng",
  "hot": "Nóng",
  "login_first": "Vui lòng đăng nhập trước",
  "affiliate": "liên kết",
  "about_us": "Về chúng tôi",
  "commission": "Nhiệm vụ",
  "other_options": "Sự lựa chọn khác",
  "bet_amount": "Số tiền đặt cược",
  "profit_amount": "Số tiền lãi",
  "u_are_betting": "Bạn đang cá cược",
  "bet_success": "Đặt cược thành công",
  "commission_transaction": "Giao dịch hoa hồng",
  "aff_login_id": "ID đăng nhập liên kết",
  "comm_type": "Loại hoa hồng",
  "commission_rate": "Tỷ lệ hoa hồng",
  "total_commission_earned": "Tổng số tiền hoa hồng kiếm được",
  "affiiate_list": "Danh sách liên kết",
  "member_id": "Mã thành viên",
  "fullname": "Họ và tên",
  "membership_level": "Cấp độ thành viên",
  "reg_date": "ngày đăng kí",
  "direct_affiliate_list": "Danh sách liên kết trực tiếp",
  "total_num_of_aff_grp": "Tổng số nhóm liên kết",
  "total_num_of_direct_aff": "Tổng số liên kết trực tiếp",
  "new_affiliate": "Liên kết mới",
  "total_group_size": "Tổng quy mô nhóm",
  "full_time": "Toàn thời gian",
  "half_time": "Nửa thời gian",
  "jackpot_amount": "10000",
  "deposit_amount": "Số tiền gửi",
  "deposit_currency": "Tiền gửi",
  "withdrawal_amount": "Số tiền rút",
  "withdrawal_currency": "Tiền tệ rút tiền",
  "referral_link_copied": "Đã sao chép liên kết giới thiệu!",
  "invite_via_link": "Mời bạn bè của bạn qua liên kết giới thiệu",
  "direct_affiliate": "Liên kết trực tiếp",
  "save_photo": "Lưu vào ảnh",
  "share_my_code": "Chia sẻ mã của tôi",
  "qr_url_copied": "Đã sao chép URL mã QR!",
  "referral": "Giới thiệu"
};