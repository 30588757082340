import axios from 'axios';
import { globalState, logout } from '../store/globalState';
import { StatusEnum } from '../enum/status';

function getAuthToken() {
    return localStorage.getItem('token');
}

function getBaseURL() {
    return window.location.origin; // This will get the current domain
}


const apiClient = axios.create({
    baseURL: getBaseURL(),
    withCredentials: false,
});

const apiClientBypass = axios.create({
    baseURL: getBaseURL(),
    withCredentials: false,
});

const apiClientNoBase = axios.create();

// Add a request interceptor
apiClientBypass.interceptors.request.use(function (config) {

    const token = getAuthToken();
    config.headers['key'] = token ? token : '';
    config.headers['Accept'] = 'application/json';
    config.headers['Content-Type'] = 'application/json';
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// Add a request interceptor
apiClient.interceptors.request.use(function (config) {
    const token = getAuthToken();
    globalState.isLoading = true; // Start loading
    config.headers['key'] = token ? token : '';
    config.headers['Accept'] = 'application/json';
    config.headers['Content-Type'] = 'application/json';
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});



apiClient.interceptors.response.use(
    
    response => {
        // Stop loading
        globalState.isLoading = false;
        return response;
    },
    error => {
        // Stop loading on error
        globalState.isLoading = false;

        if (error.response.status === 401) {
            logout();
        }

        return Promise.reject(error);
    }
);

// Convert a date to a UTC string
function toUTCString(date, hours = 16, minutes = 0, seconds = 0) {
    const dateObj = new Date(date);
    dateObj.setUTCHours(hours, minutes, seconds, 0); // Set to 16:00:00 UTC
    return dateObj.toISOString().replace(/\.\d{3}Z$/, ''); // Removes milliseconds and 'Z'
}

export default {
    getIPAddress() {
        // Using ip-api service to get IP address
        // This service provides a lot more data, but here we'll just use the IP
        return apiClientNoBase.get('http://ip-api.com/json/')
            .then(response => {
                if (response.data && response.data.query) {
                    // Returning only the IP address part
                    return response.data.query;
                } else {
                    throw new Error('IP address not found in response');
                }
            })
            .catch(error => {
                console.error('Error fetching IP address:', error);
                throw error;
            });
    },
    
    getImage(filename) {
        return apiClient.get(`/api/getImageUrl/${filename}`);
    },
    // User Authentication
    login(credentials) {
        return apiClientBypass.post('/api/login', credentials);
    },
    logout() {
        return apiClientBypass.post('/api/logout');
    },
    checkPlayerSession() {
        return apiClientBypass.get('/api/checkPlayerSession');
    },

    //General Dropdown
    getCountryCallingCode() {
        return apiClientBypass.get('/api/getallcountrycallingcode');
    },

    getOdds({ typeId } = {}) {
        const params = new URLSearchParams();

        // Add parameters if they are provided
        if (typeId) params.append('typeId', typeId);
        return apiClient.get('/api/odds', { params });
    },

 //Currency Exchange Module
 getCurrencyExchangeList({ statusId, offset, limit, sort, order } = {}) {
    const params = new URLSearchParams();

    if (statusId) params.append('statusId', statusId);
    if (offset) params.append('offset', offset);
    if (limit) params.append('limit', limit);
    if (sort) params.append('sort', sort);
    if (order) params.append('order', order);
    return apiClient.get('/api/currencyExchangelist', { params });
},

    //Membership Upgrade Module
    getMembershipUpgradeList({ membershipId, playerUsername, offset, limit, sort, order } = {}) {
        const params = new URLSearchParams();

        if (membershipId) params.append('membershipId', membershipId);
        if (playerUsername) params.append('playerUsername', playerUsername);
        if (offset) params.append('offset', offset);
        if (limit) params.append('limit', limit);
        if (sort) params.append('sort', sort);
        if (order) params.append('order', order);
        return apiClient.get('/api/membershipupgradelist', { params });
    },
    //Membership Upgrade Module
    getMemberBetUpgradeList({ id, offset, limit, sort, order } = {}) {
        const params = new URLSearchParams();

        if (id) params.append('id', id);
        if (offset) params.append('offset', offset);
        if (limit) params.append('limit', limit);
        if (sort) params.append('sort', sort);
        if (order) params.append('order', order);
        return apiClient.get('/api/memberbetupgradelist', { params });
    },

    //Membership Module

    getMemberships({ statusId, offset, limit, sort, order } = {}) {
        const params = new URLSearchParams();

        if (statusId) params.append('statusId', statusId);
        if (offset) params.append('offset', offset);
        if (limit) params.append('limit', limit);
        if (sort) params.append('sort', sort);
        if (order) params.append('order', order);
        return apiClient.get('/api/membershiplist', { params });
    },
    getMembershipById({ id } = {}) {
        const params = new URLSearchParams();
        if (id) params.append('id', id);
        return apiClient.get(`/api/membershipbyId`, { params });
    },
    getMembershipPlayerById({ id } = {}) {
        const params = new URLSearchParams();
        if (id) params.append('id', id);
        return apiClient.get(`/api/membershipPlayerbyId`, { params });
    },


    //Member Module
    getMembers({ name, membershipid, username, statusId, offset, limit, sort, order } = {}) {
        const params = new URLSearchParams();

        if (name) params.append('name', name);
        if (membershipid) params.append('membershipid', membershipid);
        if (username) params.append('username', username);
        if (statusId) params.append('statusId', statusId);
        if (offset) params.append('offset', offset);
        if (limit) params.append('limit', limit);
        if (sort) params.append('sort', sort);
        if (order) params.append('order', order);
        return apiClient.get('/api/playerlist', { params });
    },
    getMemberById({ id } = {}) {
        const params = new URLSearchParams();
        if (id) params.append('id', id);
        return apiClient.get(`/api/playerbyId`, { params });
    },
    getMemberWalletById({ id } = {}) {
        const params = new URLSearchParams();
        if (id) params.append('id', id);
        return apiClient.get(`/api/playerwalletbyId`, { params });
    },
    getMemberWalletLogById({ id, offset, limit, sort, order } = {}) {

        const params = new URLSearchParams();
        if (id) params.append('id', id);
        if (offset) params.append('offset', offset);
        if (limit) params.append('limit', limit);
        if (sort) params.append('sort', sort);
        if (order) params.append('order', order);
        return apiClient.get(`/api/playerwalletlogbyId`, { params });
    },
    getPlayerInfoById({ id } = {}) {
        const params = new URLSearchParams();
        if (id) params.append('id', id);
        return apiClient.get(`/api/playerInfobyId`, { params });
    },
    getPlayerReferralInfoById({ id } = {}) {
        const params = new URLSearchParams();
        if (id) params.append('id', id);
        return apiClient.get(`/api/playerReferralInfobyId`, { params });
    },
    getPlayerReferreeListbyId({ id, offset, limit, sort, order } = {}) {

        const params = new URLSearchParams();
        if (id) params.append('id', id);
        if (offset) params.append('offset', offset);
        if (limit) params.append('limit', limit);
        if (sort) params.append('sort', sort);
        if (order) params.append('order', order);
        return apiClient.get(`/api/playerReferreeListbyId`, { params });
    },
    getPlayerCommissionListbyId({ id, offset, limit, sort, order } = {}) {

        const params = new URLSearchParams();
        if (id) params.append('id', id);
        if (offset) params.append('offset', offset);
        if (limit) params.append('limit', limit);
        if (sort) params.append('sort', sort);
        if (order) params.append('order', order);
        return apiClient.get(`/api/playerCommissionListbyId`, { params });
    },
    getPlayerTransactionListbyId({ id, offset, limit, sort, order } = {}) {

        const params = new URLSearchParams();
        if (id) params.append('id', id);
        if (offset) params.append('offset', offset);
        if (limit) params.append('limit', limit);
        if (sort) params.append('sort', sort);
        if (order) params.append('order', order);
        return apiClient.get(`/api/playerTransactionListbyId`, { params });
    },

    updateMemberStatusById({ id, status } = {}) {
        if (!id) throw new Error("ID is required to update player status.");

        const data = {
            status: status,
        };

        return apiClient.patch(`/api/player/${id}`, data);
    },

    updateMemberInformation({ id, name, countryCallingCodeId, contactNumber, email, dateOfBirth, membershipId, password, isOptForMarketing }) {
        if (!id) throw new Error("ID is required to update player.");

        let data = {
            name: name,
            countryCallingCodeId: countryCallingCodeId,
            contactNumber: contactNumber,

            membershipId: membershipId,
            isOptForMarketing: isOptForMarketing,
        };

        // Only add email and password to data if they are present
        if (email) data.email = email;
        if (password) data.password = password;
        if (dateOfBirth) data.dateOfBirth = dateOfBirth;
        return apiClient.patch(`/api/playerInfo/${id}`, data);
    },

    createMember(credentials) {
        return apiClientBypass.post(`/api/createplayer`, credentials);
    },

    createWalletAdjustment(credentials) {
        return apiClient.post(`/api/createWalletAdjustment`, credentials);
    },

    //Team Module
    getTeams({ name, divisionId, statusId, offset, limit, sort, order } = {}) {
        const params = new URLSearchParams();

        // Add parameters if they are provided
        if (name) params.append('name', name);
        if (statusId) params.append('statusId', statusId);
        if (divisionId) params.append('divisionId', divisionId);
        if (offset) params.append('offset', offset);
        if (limit) params.append('limit', limit);
        if (sort) params.append('sort', sort);
        if (order) params.append('order', order);
        return apiClient.get('/api/teamlist', { params });
    },
    getTeamById({ id } = {}) {
        const params = new URLSearchParams();
        if (id) params.append('id', id);
        return apiClient.get(`/api/teambyId`, { params });
    },

    //Event Module
    getEvents({ teamId, name, statusId, startEventAt, endEventAt, offset, limit, sort, order } = {}) {
        const params = new URLSearchParams();

        // Add parameters if they are provided
        if (teamId) params.append('teamId', teamId);
        if (name) params.append('name', name);
        if (statusId) params.append('statusId', statusId);
        if (offset) params.append('offset', offset);

        if (startEventAt) params.append('startEventAt', toUTCString(startEventAt));
        if (endEventAt) params.append('endEventAt', toUTCString(endEventAt));

        if (limit) params.append('limit', limit);
        if (sort) params.append('sort', sort);
        if (order) params.append('order', order);

        return apiClientBypass.get('/api/eventlist', { params });
    },
      //Event Module
      getHotEvents({ sort, order } = {}) {
        const params = new URLSearchParams();

      
        if (sort) params.append('sort', sort);
        if (order) params.append('order', order);

        return apiClientBypass.get('/api/eventlisthot', { params });
    },
    getEventById({ id } = {}) {
        const params = new URLSearchParams();
        if (id) params.append('id', id);
        return apiClientBypass.get(`/api/eventbyId`, { params });
    },


    //PaymentMethod Module
    getPaymentMethods({ statusId, offset, limit, sort, order } = {}) {
        const params = new URLSearchParams();

        if (statusId) params.append('statusId', statusId);
        if (offset) params.append('offset', offset);
        if (limit) params.append('limit', limit);
        if (sort) params.append('sort', sort);
        if (order) params.append('order', order);
        return apiClient.get('/api/paymentmethodlist', { params });
    },
    getPaymentMethodById({ id } = {}) {
        const params = new URLSearchParams();
        if (id) params.append('id', id);
        return apiClient.get(`/api/paymentmethodbyId`, { params });
    },

    //PaymentChannel Module
    getPaymentChannels({ paymentMethodId, statusId, offset, limit, sort, order } = {}) {
        const params = new URLSearchParams();

        if (paymentMethodId) params.append('paymentMethodId', paymentMethodId);
        if (statusId) params.append('statusId', statusId);
        if (offset) params.append('offset', offset);
        if (limit) params.append('limit', limit);
        if (sort) params.append('sort', sort);
        if (order) params.append('order', order);
        return apiClient.get('/api/paymentchannellist', { params });
    },
    getPaymentChannelById({ id } = {}) {
        const params = new URLSearchParams();
        if (id) params.append('id', id);
        return apiClient.get(`/api/paymentchannelbyId`, { params });
    },

    //PaymentChannelAccount Module
    getPaymentChannelAccounts({ accountNumber, paymentChannelId, paymentMethodId, offset, limit, sort, order } = {}) {
        const params = new URLSearchParams();

        if (accountNumber) params.append('accountNumber', accountNumber);
        if (paymentChannelId) params.append('paymentChannelId', paymentChannelId);
        if (paymentMethodId) params.append('paymentMethodId', paymentMethodId);
        params.append('statusId', StatusEnum.Active);
        if (offset) params.append('offset', offset);
        if (limit) params.append('limit', limit);
        if (sort) params.append('sort', sort);
        if (order) params.append('order', order);
        return apiClient.get('/api/paymentchannelaccountlist', { params });
    },
    getPaymentChannelAccountById({ id } = {}) {
        const params = new URLSearchParams();
        if (id) params.append('id', id);
        return apiClient.get(`/api/paymentchannelaccountbyId`, { params });
    },

    //DepositTransaction Module
    getDepositTransactions({ playerUsername, paymentMethodId, statusId, offset, limit, sort, order } = {}) {
        const params = new URLSearchParams();

        if (playerUsername) params.append('playerUsername', playerUsername);
        if (paymentMethodId) params.append('paymentMethodId', paymentMethodId);
        if (statusId) params.append('statusId', statusId);
        if (offset) params.append('offset', offset);
        if (limit) params.append('limit', limit);
        if (sort) params.append('sort', sort);
        if (order) params.append('order', order);
        return apiClient.get('/api/deposittransactionlist', { params });
    },
    getDepositTransactionById({ id } = {}) {
        const params = new URLSearchParams();
        if (id) params.append('id', id);
        return apiClient.get(`/api/deposittransactionbyId`, { params });
    },
    createDepositTransaction(credentials) {
        return apiClient.post(`/api/createDepositTransaction`, credentials);
    },

    //withdrawalTransaction Module
    getWithdrawalTransactions({ playerUsername, paymentMethodId, statusId, offset, limit, sort, order } = {}) {
        const params = new URLSearchParams();

        if (playerUsername) params.append('playerUsername', playerUsername);
        if (paymentMethodId) params.append('paymentMethodId', paymentMethodId);
        if (statusId) params.append('statusId', statusId);
        if (offset) params.append('offset', offset);
        if (limit) params.append('limit', limit);
        if (sort) params.append('sort', sort);
        if (order) params.append('order', order);
        return apiClient.get('/api/withdrawaltransactionlist', { params });
    },
    getWithdrawalTransactionById({ id } = {}) {
        const params = new URLSearchParams();
        if (id) params.append('id', id);
        return apiClient.get(`/api/withdrawaltransactionbyId`, { params });
    },
    createWithdrawalTransaction(credentials) {
        return apiClient.post(`/api/createwithdrawalTransaction`, credentials);
    },

    //PlayerBankAccount Module
    getPlayerBankAccounts({ playerId, statusId, offset, limit, sort, order } = {}) {
        const params = new URLSearchParams();

        if (playerId) params.append('playerId', playerId);
        if (statusId) params.append('statusId', statusId);
        if (offset) params.append('offset', offset);
        if (limit) params.append('limit', limit);
        if (sort) params.append('sort', sort);
        if (order) params.append('order', order);
        return apiClient.get('/api/playerbankaccountlist', { params });
    },
    getPlayerBankAccountById({ id } = {}) {
        const params = new URLSearchParams();
        if (id) params.append('id', id);
        return apiClient.get(`/api/playerbankaccountbyId`, { params });
    },
    updatePlayerBankAccountStatusById({ id, status } = {}) {
        if (!id) throw new Error("ID is required to update player bank account status.");

        const data = {
            status: status
        };

        return apiClient.patch(`/api/playerbankaccount/${id}`, data);
    },
    updatePlayerBankAccountInformation({ id, accountName, accountNumber, paymentChannelId }) {
        if (!id) throw new Error("ID is required to update player bank account.");
        const data = {
            id: id,
            accountName: accountName,
            accountNumber: accountNumber,
            paymentChannelId: paymentChannelId,
        };

        return apiClient.patch(`/api/playerbankaccountInfo/${id}`, data);

    },
    createPlayerBankAccount(credentials) {
        return apiClient.post(`/api/createPlayerBankAccount`, credentials);
    },

    //TransferTransaction Module
    getTransferTransactions({ playerUsername, offset, limit, sort, order } = {}) {
        const params = new URLSearchParams();

        if (playerUsername) params.append('playerUsername', playerUsername);
        if (offset) params.append('offset', offset);
        if (limit) params.append('limit', limit);
        if (sort) params.append('sort', sort);
        if (order) params.append('order', order);
        return apiClient.get('/api/transfertransactionlist', { params });
    },
    getTransferTransactionById({ id } = {}) {
        const params = new URLSearchParams();
        if (id) params.append('id', id);
        return apiClient.get(`/api/transfertransactionbyId`, { params });
    },
    createTransferTransaction(credentials) {
        return apiClient.post(`/api/createTransferTransaction`, credentials);
    },


    //MemberBet Module
    getMemberBets({ playerUsername, teamId, statusId, startKickOffAt, endKickOffAt, startCreatedAt, endCreatedAt, offset, limit, sort, order } = {}) {
        const params = new URLSearchParams();

        if (playerUsername) params.append('playerUsername', playerUsername);
        if (statusId) params.append('statusId', statusId);
        if (teamId) params.append('teamName', teamId);
        if (startKickOffAt) params.append('startKickOffAt', startKickOffAt);
        if (endKickOffAt) params.append('endKickOffAt', endKickOffAt);
        if (startCreatedAt) params.append('startCreatedAt', startCreatedAt);
        if (endCreatedAt) params.append('endCreatedAt', endCreatedAt);
        if (offset) params.append('offset', offset);
        if (limit) params.append('limit', limit);
        if (sort) params.append('sort', sort);
        if (order) params.append('order', order);
        return apiClient.get('/api/memberbetlist', { params });
    },
    getMemberBetById({ id } = {}) {
        const params = new URLSearchParams();
        if (id) params.append('id', id);
        return apiClient.get(`/api/memberbetbyId`, { params });
    },
    updateMemberBetStatusById({ id, amount, eventOddId } = {}) {
        if (!id) throw new Error("ID is required to update member bet transaction status.");

        const data = {
            amount: amount,
            eventOddId: eventOddId,
        };

        return apiClient.patch(`/api/memberbet/${id}`, data);
    },
    createMemberBet(credentials) {
        return apiClient.post(`/api/createMemberBet`, credentials);
    },

};
