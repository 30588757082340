<template>
    <div class="dynamic-content">
        <div
            class="area sportsbook-section pt-16 pb-32 pt-24-tablet pb-64-tablet pt-32-desktop pb-80-desktop px-24-desktop">
            <div v-if="isShow" class="custom-caret big" :title="$t('show_correct_score')"
                :class="{ 'caret-transform': state.isCorrectScoreShow }"
                @click="state.isCorrectScoreShow = !state.isCorrectScoreShow">
            </div>


            <div v-if="isShow" class="block cards-sets " :class="{ 'score-container': state.isCorrectScoreShow }">

                <div class="profile-section-title">{{ $t('correct_score') }}
                </div>

                <CorrectScore @hasItem="handleHasitem" @empty="handleEmptyitem" v-if="state.isCorrectScoreShow" />
            </div>
            <div class="block cards-sets score-container ">
                <div class="profile-section-title">{{ $t('all_market') }}</div>
                <div class="content-cashier mywallets  mb-16-desktop">

                    <div class="side-cashier-content mt-16 mr-16-desktop">
                        <div v-for="(items, date) in  groupedItems " :key="date" @click="onDateClick(date)">
                            <div class="dropdown-header">
                                <div class="top">
                                    <span class="custom-calendar"></span>
                                    <div class="text balance">{{ date }}</div>
                                    <div class="custom-caret" :class="{ 'caret-transform': state.selectedDate == date }">
                                    </div>
                                </div>

                            </div>

                            <div class="content-box-parent" v-if="state.selectedDate == date" @click.stop>
                                <div class="content-box" :class="{ 'active': state.eventSelected == item.id }"
                                    @click="handleEventClick(item)" v-for=" item  in  items " :key="item.id">
                                    <div class="text-date">{{ convertToTimeZoneAndFormat(item.eventAt) }}</div>
                                    <div class="text" v-if="globalState.isChinese">{{ item.chineseHomeTeamName }} vs {{ item.chineseAwayTeamName }}</div>
                                    <div class="text" v-else>{{ item.homeTeamName }} vs {{ item.awayTeamName }}</div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="cashier-iframe-body bet-controner">

                        <div class="table-placeholder  align-items-center mr-0-desktop ml-0-desktop">
                            <table class="responsive-table full-width ml-0 mr-0" v-if="oddsHalfTime.length > 0">
                                <thead class="color-primary-1 background-color-other-1">
                                    <tr>
                                        <th class="table-header background-color-primary-3 color-other-1 bold p-12"
                                            colspan="4">
                                            {{ $t('ht_correct_score') }}

                                        </th>
                                    </tr>

                                </thead>
                                <tbody>
                                    <tr class="hide-on-mobile dynamic-table-row">
                                        <td class=" first-column"><strong class="p-12"> {{
                                            $t('score') }}</strong></td>
                                        <td class=""><strong class="p-12">{{ $t('profit') }}</strong></td>
                                        <td class=""><strong class="p-12">{{ $t('available_volume')
                                        }}</strong></td>
                                        <td class=""></td>
                                    </tr>
                                    <tr class="dynamic-table-row" v-for="item in oddsHalfTime" :key="item.id">
                                        <td class="responsive-td-display auto-height d-flex d-none-desktop flex-column align-items-flex-start background-color-primary-11 justify-content-center p-16"
                                            id="date-time-data" data-title="Date/Time">
                                            <div class="responsive-columns d-flex flex-column full-width">
                                                <div
                                                    class="flex-row-one-data d-flex align-items-center justify-content-flex-start">
                                                    <strong>{{ state.eventSelectedInfo.teams }}</strong>
                                                    <span
                                                        class="DECLINED ml-auto d-flex justify-content-center align-items-center">
                                                        <button class="button button-primary bet-button" @click="bet(item)">
                                                            {{ $t('bet_now') }}
                                                        </button></span>
                                                </div>

                                                <div
                                                    class="flex-row-one d-flex align-items-center justify-content-flex-start">
                                                    <span class="light-text color-primary-5">{{ $t('date') }}</span>
                                                    <span class="light-text color-primary-5 hide-on-mobile">{{
                                                        $t('transaction_id')
                                                    }}</span>

                                                </div>
                                                <div
                                                    class="flex-row-one-data d-flex align-items-center justify-content-flex-start">
                                                    <strong>{{ addHoursAndReformat(state.eventSelectedInfo.eventAt)
                                                    }}</strong>

                                                </div>


                                                <div
                                                    class="flex-row-no-gap d-flex align-items-center justify-content-flex-start mt-16">
                                                    <span class="light-text color-primary-5">{{ $t('score') }}</span>
                                                    <span class="light-text color-primary-5">{{ $t('profit') }}</span>
                                                    <span class="light-text color-primary-5">{{ $t('available_volume')
                                                    }}</span>
                                                </div>
                                                <div
                                                    class="flex-row-no-gap d-flex align-items-center justify-content-flex-start">
                                                    <span> <strong>{{ item.oddName }}</strong></span>
                                                    <span class="profit"> <strong>{{ item.profit }}%</strong></span>
                                                    <span> <strong>
                                                            {{ formatNumber(item.fakeVolume) }} {{ $t('currency_code')
                                                            }}
                                                        </strong></span>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="hide-on-mobile mobile-details">
                                            <div class=" m-12 number">{{ item.oddName }}
                                            </div>
                                        </td>
                                        <td class="hide-on-mobile  mobile-details">
                                            <div class=" m-12 number profit">{{ item.profit }}%
                                            </div>
                                        </td>
                                        <td class="hide-on-mobile mobile-details">
                                            <div class=" m-12 number">{{ formatNumber(item.fakeVolume) }}
                                            </div>
                                        </td>
                                        <td class="hide-on-mobile mobile-details">
                                            <button class="button button-primary bet-button" @click="bet(item)">
                                                {{ $t('bet_now') }}
                                            </button>
                                        </td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="table-placeholder  align-items-center  mr-0-desktop ml-0-desktop">
                            <table class="responsive-table full-width ml-0 mr-0" v-if="oddsFullTime.length > 0">
                                <thead class="color-primary-1 background-color-other-1">
                                    <tr>
                                        <th class="table-header background-color-primary-3 color-other-1 bold p-12"
                                            colspan="4">
                                            {{ $t('ft_correct_score') }}

                                        </th>
                                    </tr>

                                </thead>
                                <tbody>
                                    <tr class="hide-on-mobile dynamic-table-row">
                                        <td class=" first-column"><strong class="p-12"> {{
                                            $t('score') }}</strong></td>
                                        <td class=""><strong class="p-12">{{ $t('profit') }}</strong></td>
                                        <td class=""><strong class="p-12">{{ $t('available_volume')
                                        }}</strong></td>
                                        <td class=""></td>
                                    </tr>

                                    <tr class="dynamic-table-row" v-for="item in oddsFullTime" :key="item.id">
                                        <td class="responsive-td-display auto-height d-flex d-none-desktop flex-column align-items-flex-start background-color-primary-11 justify-content-center p-16"
                                            id="date-time-data" data-title="Date/Time">
                                            <div class="responsive-columns d-flex flex-column full-width">
                                                <div
                                                    class="flex-row-one-data d-flex align-items-center justify-content-flex-start">
                                                    <strong>{{ state.eventSelectedInfo.teams }}</strong>
                                                    <span
                                                        class="DECLINED ml-auto d-flex justify-content-center align-items-center">
                                                        <button class="button button-primary bet-button" @click="bet(item)">
                                                            {{ $t('bet_now') }}
                                                        </button></span>
                                                </div>

                                                <div
                                                    class="flex-row-one d-flex align-items-center justify-content-flex-start">
                                                    <span class="light-text color-primary-5">{{ $t('date') }}</span>
                                                    <span class="light-text color-primary-5 hide-on-mobile">{{
                                                        $t('transaction_id')
                                                    }}</span>

                                                </div>
                                                <div
                                                    class="flex-row-one-data d-flex align-items-center justify-content-flex-start">
                                                    <strong>{{ convertToTimeZoneAndFormat(state.eventSelectedInfo.eventAt)
                                                    }}</strong>

                                                </div>


                                                <div
                                                    class="flex-row-no-gap d-flex align-items-center justify-content-flex-start mt-16">
                                                    <span class="light-text color-primary-5">{{ $t('score') }}</span>
                                                    <span class="light-text color-primary-5">{{ $t('profit') }}</span>
                                                    <span class="light-text color-primary-5">{{ $t('available_volume')
                                                    }}</span>
                                                </div>
                                                <div
                                                    class="flex-row-no-gap d-flex align-items-center justify-content-flex-start">
                                                    <span> <strong>{{ item.oddName }}</strong></span>
                                                    <span class="profit"> <strong>{{ item.profit }}%</strong></span>
                                                    <span> <strong>
                                                            {{ formatNumber(item.fakeVolume) }} {{ $t('currency_code')
                                                            }}
                                                        </strong></span>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="hide-on-mobile mobile-details">
                                            <div class=" m-12 number">{{ item.oddName }}
                                            </div>
                                        </td>
                                        <td class="hide-on-mobile mobile-details">
                                            <div class=" m-12 number profit">{{ item.profit }}%
                                            </div>
                                        </td>
                                        <td class="hide-on-mobile mobile-details">
                                            <div class=" m-12 number">{{ formatNumber(item.fakeVolume) }}
                                            </div>
                                        </td>
                                        <td class="hide-on-mobile mobile-details">
                                            <button class="button button-primary bet-button" @click="bet(item)">
                                                {{ $t('bet_now') }}
                                            </button>
                                        </td>

                                    </tr>

                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>


            </div>
        </div>

    </div>
</template>
  

<script setup>
import { ref, reactive, computed, onMounted, inject, onUnmounted } from 'vue';
import apiServices from '../services/apiServices';
import CorrectScore from '../components/CorrectScore.vue';
import { convertToTimeZoneAndFormat, formatNumber } from '../services/generalFunction';
import { globalState } from '../store/globalState';
import { eventBus } from '../services/eventBus';
import { notificationSymbol } from '../tools/symbol';
import { EventStatusEnum } from '../enum/eventStatus';


const notify = inject(notificationSymbol);
// Reactive state
const state = reactive({
    items: [],
    currentIndex: 0,
    selectedDate: null,
    eventSelected: 0,
    eventSelectedInfo: null,
    eventDetails: [],
    isCorrectScoreShow: true
});


const isShow = ref(true)

const sorting = reactive({
    sortBy: 'eventAt',
    sortOrder: 'desc'
});
// Fetch events on mounted
onMounted(async () => {
    fetchData();

    const handleLanguageChange = (newLang) => {
        console.log(`Language changed to: ${newLang}`);
        if (newLang === 'zh-CN' || newLang === 'zh-TW' || localStorage.getItem('lang') === 'zh-CN' || localStorage.getItem('lang') === 'zh-TW'){
            globalState.isChinese = true;
        }else{
            globalState.isChinese = false;
        }
    };

    eventBus.on('languageChanged', handleLanguageChange);

    // Clean up the event listener when the component is unmounted
    onUnmounted(() => {
        eventBus.off('languageChanged', handleLanguageChange);
    });
});

const fetchData = async () => {
    try {
        const params = {
            statusId: EventStatusEnum.Pending
        };
        if (sorting.sortBy) {
            params.sort = sorting.sortBy;
            params.order = sorting.sortOrder;
        }
        const response = await apiServices.getEvents(params);
        state.items = response.data.events;

    } catch (error) {
        // Handle error case
        console.error(error);
    }

}

const groupedItems = computed(() => {
    const groups = {};
    state.items.forEach(item => {
        // Convert eventAt to target timezone and format
        let eventDate = new Date(item.eventAt);
        // Adding 8 hours to the date
        eventDate.setHours(eventDate.getHours());

        const newDateStr = addHoursAndReformat(eventDate);

        // Group items by the extracted day
        if (!groups[newDateStr]) {
            groups[newDateStr] = [];
        }
        groups[newDateStr].push(item);
    });
    return groups;
});

async function handleEventClick(event) {
    const response = await apiServices.getEventById({ id: event.id });
    state.eventSelected = event.id;
    state.eventSelectedInfo = event;
    state.eventDetails = response.data.eventOdds;
}


function onDateClick(date) {
    if (state.selectedDate == date) {
        state.selectedDate = null;
        return;
    }
    state.selectedDate = date;
}

// Computed array for oddTypeId = 1
const oddsHalfTime = computed(() => {
    return state.eventDetails.filter(odd => odd.oddTypeId === 1);
});

// Computed array for oddTypeId = 2
const oddsFullTime = computed(() => {
    return state.eventDetails.filter(odd => odd.oddTypeId === 2);
});

function addHoursAndReformat(dateStr, hoursToAdd = 8) {
    const eventDate = new Date(dateStr);
    eventDate.setHours(eventDate.getHours() + hoursToAdd);

    // Format the date as dd-mm-YYYY
    const day = String(eventDate.getDate()).padStart(2, '0');
    const month = String(eventDate.getMonth() + 1).padStart(2, '0'); // January is 0
    const year = eventDate.getFullYear();

    return `${day}-${month}-${year}`;
}


const handleHasitem = () => {
    isShow.value = true;
}

const handleEmptyitem = () => {
    isShow.value = false;
}



const bet = (match) => {
    if (!globalState.isAuth) {
        notify("login_first", "warning")
        globalState.isLoginShow = true;
        return
    } else {
        fetchData();
        globalState.eventOddsDetailSelect = {
            eventName: state.eventSelectedInfo.teams,
            eventOddsId: match.id,
            kickOffDate: state.eventSelectedInfo.eventAt,
            oddsName: match.oddName,
            profit: match.profit
        }

        globalState.isShowPlaceBet = true;
    }
}

</script>

<style scoped>
#matches ul {
    padding: 0 1em;
}

.profile-section-title {
    margin-top: 0.8em;
    margin-bottom: 10px;
}

.profile-section-title:after {
    content: "";
    background-color: var(--secondary-3);
    display: flex;
    width: 24px;
    height: 2px;
    margin-top: var(--spacing-12);
    z-index: 9;
}

.carousel-container {
    overflow-x: auto;
    white-space: nowrap;
    scrollbar-width: none;
    /* For Firefox */
    -ms-overflow-style: none;
    /* For Internet Explorer and Edge */

    /* For Chrome, Safari, and Opera */
    &::-webkit-scrollbar {
        display: none;
    }
}

.swiper-wrapper {
    display: inline-flex;
    padding-bottom: 10px !important;
}

.score-container {
    height: 280px;
    transition: all ease .5s;
    margin-top: 1.2em;
}

.content-cashier .side-cashier-content .dropdown-header {
    display: flex;
    background-color: var(--primary-2);
    justify-content: center;
    align-items: center;
    padding: 1em;
    margin: 10px;
    height: 25px;
    cursor: pointer;
    margin-left: 0;
    min-width: 260px;
}

.content-cashier .side-cashier-content .dropdown-header .top {
    justify-content: space-between;
}

@media (min-width: 767.5px) {
    .content-cashier .side-cashier-content .dropdown-header {
        border-radius: 8px;
    }

    .content-cashier {
        display: flex;
    }
}

.content-box {
    padding: 18px;
    border-radius: 16px;
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 2px 4px 10px 0 rgba(0, 0, 0, 0.1);
    font-weight: 600;
    background-repeat: no-repeat;
    background-position: right top;
    background-size: contain;
    transition: all ease .5s;
    margin-bottom: 10px;
    margin: 10px;
    margin-left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 290px;
    cursor: pointer;
}

.content-box:hover {
    background-color: var(--secondary-4) !important;
}

.active {
    background-color: var(--secondary-4) !important;
}

.content-box-parent {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.caret-transform {
    transform: rotate(180deg)
}

.text-date {
    font-size: 11px;
    margin-bottom: 20px;
}

@media (min-width: 767.5px) {
    .content .content-cashier {
        max-width: unset !important;
    }

    .content .content-cashier .cashier-iframe-body {
        display: flex !important;
    }
}

.content .content-cashier .cashier-iframe-body {
    display: block;
}

.bet-controner {
    flex-direction: row !important;
    align-items: flex-start;
    justify-content: space-around;
    margin-top: 25px;
    padding-bottom: 10%;
}


.big {
    width: 18px;
    height: 18px;
    -webkit-mask-size: 18px;
    mask-size: 18px;
    float: right;
    background-color: var(--secondary-3);
    position: absolute;
    right: 27px;
    top: 10%;
    cursor: pointer;
}

.responsive-table tbody tr td {
    border: none !important;
}

.responsive-table {

    width: 450px !important;
}

.profit {
    color: var(--system-5);
}
</style>