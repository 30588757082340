export default {
  "login": "เข้าสู่ระบบ",
  "logout": "ออกจากระบบ",
  "register": "ลงทะเบียน",
  "are_u_member": "คุณเป็นสมาชิกหรือเปล่า?",
  "home": "บ้าน",
  "sports": "กีฬา",
  "livecasino": "คาสิโนสด",
  "casino": "คาสิโน",
  "kenolotto": "คีโนและล็อตโต้",
  "promotions": "โปรโมชั่น",
  "vip": "วีไอพี",
  "darkmode": "โหมดมืด",
  "lightmode": "โหมดแสง",
  "username": "ชื่อผู้ใช้",
  "password": "รหัสผ่าน",
  "input_username": "ใส่ชื่อผู้ใช้ที่ลงทะเบียนของคุณ",
  "input_password": "พิมพ์รหัสผ่านของคุณ",
  "keep_login": "ให้ฉันลงชื่อเข้าใช้ต่อไป",
  "create_new_account": "สร้างบัญชีใหม่!",
  "forgot_login_details": "ลืมรายละเอียดการเข้าสู่ระบบของคุณ?",
  "not_yet_member": "ยังไม่ได้เป็นสมาชิก?",
  "register_to": "ลงทะเบียนไปที่",
  "reg_msg_1": "  โปรดทราบว่าเราไม่อนุญาตให้สมาชิกเป็นเจ้าของบัญชีมากกว่าหนึ่ง (1) บัญชี",
  "already_have_account": " มีบัญชีอยู่แล้ว?",
  "login_here": "เข้าสู่ระบบที่นี่!",
  "name": "ชื่อ",
  "how_address_u": "เราจะตอบคุณอย่างไร?",
  "username_validation": "ตัวอักษรและตัวเลขยาว 3-16 ตัว",
  "password_validation": "ความยาว 6-30 ตัวอักษร",
  "email": "อีเมล",
  "cancel": "ยกเลิก",
  "update_account_details": "อัปเดตรายละเอียดบัญชีของคุณ",
  "bank_account_holder_name": "ชื่อเจ้าของบัญชีธนาคาร",
  "add_bank_account": "เพิ่มบัญชีธนาคารใหม่",
  "bank_name": "ชื่อธนาคาร",
  "bank_account_number": "หมายเลขบัญชีธนาคาร",
  "add_bank_verification_msg": "เมื่อคลิกบันทึกบัญชี ฉันยืนยันว่าข้อมูลบัญชีธนาคารที่ให้ไว้นั้นถูกต้อง",
  "save_account": "บันทึกบัญชี",
  "total_balances": "ยอดคงเหลือทั้งหมด",
  "date_time": "วันเวลา",
  "account_profile": "โปรไฟล์บัญชี",
  "update_bank_details": " อัปเดตรายละเอียดธนาคาร",
  "cashier": "แคชเชียร์",
  "profile": "ประวัติโดยย่อ",
  "history": "ประวัติศาสตร์",
  "deposit": "เงินฝาก",
  "withdrawal": "การถอนเงิน",
  "slidding_announcement": "แพลตฟอร์มการเดิมพันต่อต้านคลื่น 365WON เปิดตัวอย่างเป็นทางการ! ลงทุนอย่างชาญฉลาดแล้วความมั่งคั่งของคุณจะไม่จำกัด! เป็นผู้นำอนาคตใหม่ของการเดิมพันกีฬา",
  "refer_friend": "แนะนำเพื่อน",
  "transfer": "โอนย้าย",
  "statement": "คำแถลง",
  "match_result": "ผลการแข่งขัน",
  "bet_record": "บันทึกการเดิมพัน",
  "transaction_history": "การทำธุรกรรม",
  "running_bets": "การเดิมพันที่กำลังดำเนินอยู่",
  "no_results": "ไม่มีผลลัพธ์",
  "latest_statement": "แถลงการณ์ล่าสุด",
  "total_bet_amount": "จำนวนเงินเดิมพันทั้งหมด",
  "total_earned_profit": "กำไรที่ได้รับทั้งหมด",
  "transaction_type": "ประเภทธุรกรรม",
  "all": "ทั้งหมด",
  "groupcommission": "ค่าคอมมิชชั่นกลุ่ม",
  "profitfrombet": "กำไรจากการเดิมพัน",
  "adjustment": "การปรับ",
  "voidwithdrawal": "การถอนเป็นโมฆะ",
  "bet": "เดิมพัน",
  "voidbet": "เดิมพันเป็นโมฆะ",
  "referralhold": "การพักการอ้างอิง",
  "referralreturn": "การส่งคืนผู้อ้างอิง",
  "firstbethold": "การวางเดิมพันครั้งแรก",
  "firstbetreturn": "ผลตอบแทนเดิมพันครั้งแรก",
  "pending": "รอดำเนินการ",
  "processing": "กำลังประมวลผล",
  "approved": "ที่ได้รับการอนุมัติ",
  "rejected": "ถูกปฏิเสธ",
  "transaction_status": "สถานะการทำธุรกรรม",
  "select_date_from": "เลือกวันที่จาก",
  "to": "ถึง",
  "date_to_invalid": "Date From จะต้องอยู่ก่อนหน้า Date To",
  "date": "วันที่",
  "transaction_id": "รหัสธุรกรรม",
  "transaction_date": "วันที่ทำธุรกรรม",
  "kick_off_date": "เวลาคิกออฟ",
  "match": "จับคู่",
  "score": "คะแนน",
  "amount": "จำนวน",
  "profit": "กำไร",
  "projected_profit": "ประมาณการกำไร",
  "status": "สถานะ",
  "submit": "ส่ง",
  "odds": "ราคาต่อรอง",
  "personal_details": "ข้อมูลส่วนตัว",
  "nickname": "ชื่อเล่น",
  "date_of_birth": "วันเกิด",
  "date_of_birth_msg": "การระบุวันเกิดของคุณเป็นทางเลือก แต่แนะนำเพื่อวัตถุประสงค์ในการส่งเสริมการขาย",
  "mobile": "มือถือ",
  "change_my_password": "เปลี่ยนรหัสผ่านของฉัน",
  "old_password": "รหัสผ่านเก่า",
  "enter_ur_old_password": "ป้อนรหัสผ่านเก่าของคุณ",
  "new_password": "รหัสผ่านใหม่",
  "currency_code": "USD",
  "withdrawable": "ถอนออกได้",
  "hey": "เฮ้",
  "ur_membership_level_is": "ระดับสมาชิกของคุณคือ",
  "method": "วิธี",
  "type": "พิมพ์",
  "deposit_step_2": "เลือกบัญชีธนาคาร 365 วอนที่จะฝาก",
  "deposit_step_3": "เมื่อการโอนเงินเสร็จสมบูรณ์ กรุณากรอกแบบฟอร์มด้านล่าง",
  "deposit_step_1": "เลือกวิธีการชำระเงินที่จะฝาก",
  "bank_account": "บัญชีธนาคาร",
  "bank": "ธนาคาร",
  "recent_transaction": "การทำธุรกรรมล่าสุด",
  "upload": "ที่อัพโหลด",
  "receipt": "ใบเสร็จ",
  "deposit_submittted": "ส่งการฝากเงินแล้ว",
  "file_upload_validation": "ขนาดไฟล์ไม่ควรเกิน 5MB",
  "copied_to_clipboard": "คัดลอกไปยังคลิปบอร์ดแล้ว!",
  "failed_to_copy": "คัดลอกไปยังคลิปบอร์ดไม่สำเร็จ",
  "withdrawal_submittted": "ส่งการถอนเงินแล้ว",
  "withdrawal_step_1": "ถอนเงินไปยังธนาคารที่คุณต้องการ",
  "transfer_step_1": "โอนให้เพื่อนของคุณ",
  "player_not_found": "ไม่มีผู้เล่น!",
  "transfer_submitted": "โอนแล้ว",
  "register_success": "การลงทะเบียนเสร็จสมบูรณ์แล้ว !",
  "invalid_email": "ที่อยู่อีเมลที่ไม่ถูกต้อง",
  "register_failed": "การลงทะเบียนไม่สำเร็จ !",
  "login_failed": "ข้อมูลรับรองการเข้าสู่ระบบไม่ถูกต้อง",
  "login_success": "เข้าสู่ระบบสำเร็จ !",
  "confirm_password": "ยืนยันรหัสผ่าน",
  "password_validate_failed": "การตรวจสอบรหัสผ่านล้มเหลว",
  "update_member_success": "อัพเดทข้อมูลสมาชิกสำเร็จ",
  "confirm_match_new": "ยืนยันรหัสผ่านจะต้องตรงกับรหัสผ่านใหม่",
  "update_member_failed": "อัปเดตข้อมูลสมาชิกล้มเหลว",
  "session_expired": "เซสชันของคุณหมดอายุแล้ว กรุณาเข้าสู่ระบบอีกครั้ง",
  "update_player_bank_success": "บัญชีธนาคารได้รับการปรับปรุงแล้ว",
  "create_player_bank_success": "สร้างบัญชีธนาคารแล้ว",
  "correct_score": "คะแนนที่ถูกต้อง",
  "bet_now": "เดิมพันตอนนี้",
  "see_all": "ดูทั้งหมด",
  "quick_easy": "มันง่ายและรวดเร็ว",
  "join_now": "เข้าร่วม 365 วอน",
  "fast_deposit_time": "ปลอดภัยด้วยเวลาการฝากที่รวดเร็วที่สุด",
  "start_ur_journey": "เริ่มต้นการเดินทางเพื่อชัยชนะของคุณตอนนี้!",
  "play": "เล่น",
  "all_market": "ตลาดทั้งหมด",
  "ft_correct_score": "คะแนน FT ที่ถูกต้อง",
  "ht_correct_score": "HT คะแนนที่ถูกต้อง",
  "available_volume": "ปริมาณที่มีอยู่",
  "show_correct_score": "แสดงคะแนนที่ถูกต้อง",
  "total_volume": "ปริมาณรวม",
  "hot": "ร้อน",
  "login_first": "กรุณาเข้าสู่ระบบก่อน",
  "affiliate": "พันธมิตร",
  "about_us": "เกี่ยวกับเรา",
  "commission": "คณะกรรมการ",
  "other_options": "ตัวเลือกอื่น",
  "bet_amount": "จำนวนเงินเดิมพัน",
  "profit_amount": "จำนวนกำไร",
  "u_are_betting": "คุณกำลังเดิมพัน",
  "bet_success": "เดิมพันสำเร็จ",
  "commission_transaction": "ธุรกรรมคอมมิชชั่น",
  "aff_login_id": "รหัสเข้าสู่ระบบพันธมิตร",
  "comm_type": "ประเภทคอมมิชชั่น",
  "commission_rate": "อัตราค่าคอมมิชชั่น",
  "total_commission_earned": "ค่าคอมมิชชั่นที่ได้รับทั้งหมด",
  "affiiate_list": "รายชื่อพันธมิตร",
  "member_id": "รหัสสมาชิก",
  "fullname": "ชื่อเต็ม",
  "membership_level": "ระดับสมาชิก",
  "reg_date": "วันที่ลงทะเบียน",
  "direct_affiliate_list": "รายชื่อพันธมิตรโดยตรง",
  "total_num_of_aff_grp": "จำนวนกลุ่มพันธมิตรทั้งหมด",
  "total_num_of_direct_aff": "จำนวนพันธมิตรโดยตรงทั้งหมด",
  "new_affiliate": "พันธมิตรใหม่",
  "total_group_size": "ขนาดกลุ่มทั้งหมด",
  "full_time": "เต็มเวลา",
  "half_time": "ครึ่งแรก",
  "jackpot_amount": "10,000",
  "deposit_amount": "จำนวนเงินฝาก",
  "deposit_currency": "สกุลเงินฝาก",
  "withdrawal_amount": "จำนวนเงินที่ถอน",
  "withdrawal_currency": "สกุลเงินถอน",
  "referral_link_copied": "คัดลอกลิงก์ผู้อ้างอิงแล้ว !",
  "invite_via_link": "เชิญเพื่อนของคุณผ่านลิงก์ผู้อ้างอิง",
  "direct_affiliate": "พันธมิตรโดยตรง",
  "save_photo": "บันทึกลงในรูปถ่าย",
  "share_my_code": "แบ่งปันรหัสของฉัน",
  "qr_url_copied": "คัดลอก URL โค้ด QR แล้ว !",
  "referral": "การอ้างอิง"
};