<template>
    <nav class="cashier-navigation">
        <a class="navigation-item" :class="{ active: $route.path === '/deposit' || $route.path === '/withdrawal'|| $route.path === '/transfer' }" @click="this.$redirectTo('/deposit')">
            <div class="navigation-item-content">
                <div class="icon mask-cashier-svg"></div>
                <span>{{$t('cashier')}}</span>
            </div>
        </a>

        <a class="navigation-item" :class="{ active: $route.path === '/profile' || $route.path === '/bankdetails' }" @click="this.$redirectTo('/profile')">
            <div class="navigation-item-content">
                <div class="unread-messages-container-cashier-navigation">
                    <span id="unread-messages-cashier-navigation-counter"></span>
                </div>
                <div class="icon mask-profile-svg"></div>
                <span>{{$t('profile')}}</span>
            </div>
        </a>
        <a class="navigation-item" :class="{ active: $route.path === '/history' }"
            @click="this.$redirectTo('/history')">
            <div class="navigation-item-content">
                <div class="icon mask-history-svg"></div>
                <span>{{$t('history')}}</span>
            </div>
        </a>
    </nav>
</template>
