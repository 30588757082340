export const TransactionStatusEnum = {
    Pending: 1,
    Processing: 2,
    Approved: 3,
    Rejected: 4,
  };
  


export function getTransactionStatusLabel(typeId) {
    switch (typeId) {
        case TransactionStatusEnum.Pending:
            return 'Pending';
        case TransactionStatusEnum.Processing:
            return 'Processing';
        case TransactionStatusEnum.Approved:
            return 'Approved';
        case TransactionStatusEnum.Rejected:
            return 'Rejected';
       
        default:
            return '-';
    }
}