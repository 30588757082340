<template>
    <input id="side-menu-toggle" class="side-menu-toggle" type="checkbox" style="display: none;">
    <label for="side-menu-toggle" class="layout-side-menu-overlay ml-12 track-events"
        data-tracking-name="Hamburger" @click="globalState.isShowSideMenu = true"></label>
    <div class="side-menu" :class="{'side-menu-show' : globalState.isShowSideMenu}" @click.stop>
        <div id="burger-header-container" class="side-menu-header mt-16 mb-32 mx-16">
            <label for="side-menu-toggle">
                <div id="burger-close-icon" class="close-icon" @click="globalState.isShowSideMenu = false"></div>
            </label>

            <div class="d-flex d-row unauthenticated align-items-center">

                <DarkMode :is-mobile="true" />

                <div class="seperator-line ml-12 mr-12 up-to-tablet"></div>
                <div class="lang-messages-wrapper">

                    <div class="lang-selector ffhover loaded">
                        <LanguageSelection />
                    </div>

                </div>
            </div>
        </div>

        <div class="dynamic-content" style="background: unset !important;">

            <div class="area" data-type="default" data-name="mMenu" v-if="!globalState.isAuth" style="padding-bottom: 0;">
                <div class="d-flex flex-column align-items-center mb-32">


                    <img src="../assets/image/logo/mlogo-mobile_cn.svg" class="icon-logo mb-16" alt="家">

                    <a class="button md button-primary px-56 mb-16 track-events no-decoration" @click="showRegHandler">
                        <span>
                            <span style="vertical-align: inherit;">
                                <span style="vertical-align: inherit;"> {{ $t('register') }}</span>
                            </span>
                        </span>
                    </a>
                    <div class="header-login d-flex flex-row color-other-1">
                        <span class="text sm">
                            <span style="vertical-align: inherit;">
                                <span style="vertical-align: inherit;">
                                    {{ $t('are_u_member') }}&nbsp;
                                </span>
                            </span>
                        </span>


                        <a class="track-events text sm underline" data-name="Login - EN" @click="showLoginHandler">
                            <span>
                                <span style="vertical-align: inherit;">
                                    <span style="vertical-align: inherit;">{{ $t('login') }}</span>
                                </span>
                            </span>
                        </a>
                    </div>

                </div>
            </div>
            <div class="side-cashier-balance-header ml-16 mb-16" v-if="globalState.isAuth">
                <span class="text md bold color-other-1"> {{ $t('account_profile') }}</span>
            </div>
            <div class="side-menu-balance-container" v-if="globalState.isAuth">

                <div class="cashier-balances-container ml-16 ">
                    <div class="side-balance-info full-height" id="balanceInfo">
                        <a class="mr-8">
                            <div class="balance-item" id="burger-balance-total-container">
                                <div class="d-flex flex-column mt-32 mb-16 ml-16">
                                    <span class="text xs color-primary-6 mb-4">
                                        {{ $t('total_balances') }}
                                    </span>

                                    <div>
                                        <span class="currencyCode text md bold color-secondary-3"> {{ $t('currency_code') }}
                                            &nbsp;</span>
                                        <span id="balance-total-burger" data-autofill-total="balance"
                                            class="text md bold color-secondary-3"> {{ globalState.balance }}</span>
                                    </div>
                                </div>

                                <div class="icon icon-total-balance mt-16 mr-16"></div>
                            </div>
                        </a>



                    </div>
                </div>

            </div>

            <div class="area" >
                <div class="side-menu-tile-nav-element color-other-1">
                    <div class="tile-nav-element">
                        <a @click="$redirectTo('/')" id="menu-item-index" name="Home - CN"
                            class="selected track-events-sidemenu-sidemenu d-flex burger-menu-tile-home">
                            <div class="side-menu-tile d-flex center burger-menu-tile-home">
                                <div class="d-flex center mt-16 mb-8 mask burger-menu-tile-home mask-home-svg">
                                </div>
                                <span class="text uppercase bold xs center burger-menu-tile-home">
                                    <span style="vertical-align: inherit;">
                                        <span style="vertical-align: inherit;">
                                            {{ $t('home') }}
                                        </span>
                                    </span>
                                </span>

                            </div>
                        </a>
                    </div>
                    <div class="tile-nav-element">
                        <a @click="$redirectTo('/sports')" id="menu-item-sportsbook" name="Sports - CN"
                            class=" track-events-sidemenu d-flex burger-menu-tile-sports">

                            <span class="tag sm red absolute ml-64 mt-8 burger-menu-tile-trading">
                                <span style="vertical-align: inherit;">
                                    <span style="vertical-align: inherit;">
                                        {{ $t('hot') }}
                                    </span>
                                </span>
                            </span>
                            <div class="side-menu-tile d-flex center burger-menu-tile-sports">
                                <div class="d-flex center mt-16 mb-8 mask burger-menu-tile-sports mask-sport-svg">
                                </div>
                                <span class="text uppercase bold xs center burger-menu-tile-sports">
                                    <span style="vertical-align: inherit;">
                                        <span style="vertical-align: inherit;">
                                            {{ $t('sports') }}
                                        </span>
                                    </span>
                                </span>
                            </div>
                        </a>
                    </div>
                    <div class="tile-nav-element"  v-if="globalState.isAuth">
                        <a @click="$redirectTo('/deposit')" id="menu-item-deposit" name="deposit - CN"
                            class=" track-events-sidemenu d-flex burger-menu-tile-sports">


                            <div class="side-menu-tile d-flex center burger-menu-tile-sports">
                                <div class="d-flex center mt-16 mb-8 mask burger-menu-tile-sports mask-deposit-svg">
                                </div>
                                <span class="text uppercase bold xs center burger-menu-tile-sports">
                                    <span style="vertical-align: inherit;">
                                        <span style="vertical-align: inherit;">
                                            {{ $t('deposit') }}
                                        </span>
                                    </span>
                                </span>
                            </div>
                        </a>
                    </div>
                    <div class="tile-nav-element"  v-if="globalState.isAuth">
                        <a @click="$redirectTo('/withdrawal')" id="menu-item-withdrawal" name="Sports - CN"
                            class=" track-events-sidemenu d-flex burger-menu-tile-sports">


                            <div class="side-menu-tile d-flex center burger-menu-tile-sports">
                                <div class="d-flex center mt-16 mb-8 mask burger-menu-tile-sports mask-withdrawal-svg">
                                </div>
                                <span class="text uppercase bold xs center burger-menu-tile-sports">
                                    <span style="vertical-align: inherit;">
                                        <span style="vertical-align: inherit;">
                                            {{ $t('withdrawal') }}
                                        </span>
                                    </span>
                                </span>
                            </div>
                        </a>
                    </div>
                    <div class="tile-nav-element"  v-if="globalState.isAuth">
                        <a @click="$redirectTo('/transfer')" id="menu-item-transfer" name="Sports - CN"
                            class=" track-events-sidemenu d-flex burger-menu-tile-sports">


                            <div class="side-menu-tile d-flex center burger-menu-tile-sports">
                                <div class="d-flex center mt-16 mb-8 mask burger-menu-tile-sports mask-transfer-svg">
                                </div>
                                <span class="text uppercase bold xs center burger-menu-tile-sports">
                                    <span style="vertical-align: inherit;">
                                        <span style="vertical-align: inherit;">
                                            {{ $t('transfer') }}
                                        </span>
                                    </span>
                                </span>
                            </div>
                        </a>
                    </div>
                    <div class="tile-nav-element"  v-if="globalState.isAuth">
                        <a @click="$redirectTo('/commission')" id="menu-item-rewards" class=" track-events-sidemenu d-flex">
                            <div class="side-menu-tile d-flex center">
                                <div class="d-flex center mt-16 mb-8 mask mask-reward-svg">
                                </div> <span class="text uppercase bold xs center">
                                    {{ $t('commission') }}
                                </span>
                            </div>
                        </a>
                    </div>
                    <div class="tile-nav-element-wide"  v-if="globalState.isAuth">
                        <a @click="$redirectTo('/profile')" id="menu-item-vip" name="VIP - EN"
                            class=" track-events-sidemenu burger-menu-tile-vip">


                            <div class="side-menu-tile d-flex m-auto align-items-center burger-menu-tile-vip">
                                <div class="d-flex center mt-12 mb-12 wide-mask burger-menu-tile-vip mask-profile-svg">
                                </div> <span class="text uppercase bold xs center burger-menu-tile-vip">
                                    {{ $t('profile') }}
                                </span>

                            </div>

                        </a>

                    </div>
                    <div class="tile-nav-element-wide"  v-if="globalState.isAuth">
                        <a @click="$redirectTo('/history')" id="menu-item-vip" name="VIP - EN"
                            class=" track-events-sidemenu burger-menu-tile-vip">


                            <div class="side-menu-tile d-flex m-auto align-items-center burger-menu-tile-vip">
                                <div class="d-flex center mt-12 mb-12 wide-mask burger-menu-tile-vip mask-history-svg">
                                </div> <span class="text uppercase bold xs center burger-menu-tile-vip">
                                    {{ $t('history') }}
                                </span>

                            </div>

                        </a>

                    </div>



                </div>
            </div>
            <div class="area mb-48" data-type="default" data-name="mMenu">
                <div class="d-flex flex-column mx-16 color-other-1">
                    <span class="text bold md mb-16">
                        <span style="vertical-align: inherit;">
                            <span style="vertical-align: inherit;">
                                {{ $t('other_options') }}
                            </span>
                        </span>
                    </span>
                    <div class="mb-4" v-if="globalState.isAuth">
                        <a @click="$redirectTo('/referafriend')" id="menu-item-raf" data-component-name="RAF - CN" name="RAF - CN"
                            class=" track-events d-flex flex-row align-items-center side-menu-links">
                            <div class="mx-8 my-12 mask-20 background-color-primary-6 mask-raf-svg" style="">
                            </div>
                            <span class="text bold sm uppercase">
                                <span style="vertical-align: inherit;">
                                    <span style="vertical-align: inherit;">
                                        {{ $t('referral') }}
                                    </span>
                                </span>
                            </span>

                        </a>

                    </div>
                    <div class="mb-4" v-if="globalState.isAuth">
                        <a @click="$redirectTo('/affiliate')" id="menu-item-raf" data-component-name="RAF - CN" name="RAF - CN"
                            class=" track-events d-flex flex-row align-items-center side-menu-links">
                            <div class="mx-8 my-12 mask-20 background-color-primary-6 mask-raf-svg" style="">
                            </div>
                            <span class="text bold sm uppercase">
                                <span style="vertical-align: inherit;">
                                    <span style="vertical-align: inherit;">
                                        {{ $t('affiliate') }}
                                    </span>
                                </span>
                            </span>

                        </a>

                    </div>

                    <div class="mb-4">
                        <a @click="$redirectTo('/aboutus')" id="menu-item-about" data-component-name="About M88 - CN" name="About M88 - CN"
                            class=" track-events d-flex flex-row align-items-center side-menu-links">


                            <div class="mx-8 my-12 mask-20 background-color-primary-6 mask-about-svg">
                            </div>


                            <span class="text bold sm uppercase">
                                <span style="vertical-align: inherit;">
                                    <span style="vertical-align: inherit;">
                                        {{ $t('about_us') }}
                                    </span>
                                </span>
                            </span>

                        </a>

                    </div>



                    <div class="mt-4" v-if="globalState.isAuth">

                        <a @click="logoutHandler" id="menu-item-logout" name="Logout - EN"
                            class=" track-events d-flex flex-row align-items-center side-menu-links">
                            <div class="mx-8 my-12 mask-20 background-color-primary-6 mask-logout-svg">
                            </div>
                            <span class="text bold sm uppercase">
                                {{ $t('logout') }}
                            </span>

                        </a>

                    </div>
                </div>
            </div>



        </div>
    </div>
</template>

<script setup>
import LanguageSelection from './LanguageSelection.vue';
import DarkMode from './DarkMode.vue';
import { logout, globalState } from '../store/globalState';

const logoutHandler = () => {
    logout();
};

const showLoginHandler = () => {
    globalState.isShowSideMenu = false
    globalState.isLoginShow = true;
};

const showRegHandler = () => {
    globalState.isShowSideMenu = false
    globalState.isRegShow = true;
};
</script>