<template>
    <div class="tabbed-links cashier mt-16-desktop" id="tabbed-links-cashier">
        <a @click="this.$redirectTo('/deposit')" id="tab-deposit" class="tabbed-links__item "
            :class="{ selected: $route.path === '/deposit' }">
            <div class="icon-background"></div>
            {{ $t('deposit') }}
        </a>
        <a @click="this.$redirectTo('/withdrawal')" id="tab-withdrawal" class="tabbed-links__item "
            :class="{ selected: $route.path === '/withdrawal' }">
            <div class="icon-background"></div>
            {{ $t('withdrawal') }}
        </a> 
        <a @click="this.$redirectTo('/transfer')" id="tab-transfer" class="tabbed-links__item "
            :class="{ selected: $route.path === '/transfer' }">
            <div class="icon-background"></div>
            {{ $t('transfer') }}
        </a>
    </div>
</template>