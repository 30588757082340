<template>
    <div class="register-popup">
        <div class="modal-overlay modal-register active" v-if="globalState.isAffRegShow" @click="handleOverlayClick">
            <div class="modal-content" style="">
                <div class="left-register-content">
                    <div class="promotions-area">
                        <img class="promotion-banner" src="../assets/image/register/register_EN.jpg" alt="">
                        <div class="content-wrapper">
                            <div class="info-box">
                                <img class="m88" src="../assets/image/logo/mlogo-desktop.svg" alt="">
                                <div class="promotions-text-container">

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="right-register-content">
                    <div class="promotions-area-mobile">
                        <div class="tile">
                            <img class="promotion-banner" src="../assets/image/register/register-mobile_EN.jpg" alt="">
                            <div class="info-box">
                                <img class="m88" src="../assets/image/logo/mlogo-desktop.svg" alt="">

                            </div>
                        </div>
                    </div>


                    <div class="register-form-wrapper">
                        <div class="register-top-info">
                            <div class="register-top-info-row1">
                                <div class="register-to-text">
                                    {{ $t('register_to') }} <span class="text-gold">365 WON!</span>
                                </div>
                                <!--LANGUAGE PICKER START-->
                                <div class="country-picker">
                                    <div id="RegisterLanguageDropdown"
                                        class="lang-selector lang-selector-nocurrency ffhover loaded">
                                        <LanguageSelection />
                                    </div>


                                </div>
                                <!--LANGUAGE PICKER END-->
                            </div>
                            <!-- <div class="text md one-account-text mt-16">
                                {{ $t('reg_msg_1') }}
                            </div> -->

                        </div>
                        <form id="register-form" @submit="register" style="padding-top: 2em;">

                            <div class="input-area input-label-left mb-16">
                                <div class="input-label" id="registerPopup-name"> {{ $t('name') }}</div>
                                <div class="input-wrapper">
                                    <div class="input">
                                        <input type="text" :placeholder="$t('how_address_u')" v-model="regData.name">
                                        <div class="icon icon-right"></div>
                                    </div>
                                    <div class="input-bottom-text text md"></div>

                                </div>
                            </div>

                            <div class="input-area input-label-left mb-16">
                                <div class="input-label" id="registerPopup-username">{{ $t('username') }}</div>
                                <div class="input-wrapper">
                                    <div class="input"
                                        :class="{ 'error': !validateUsername(regData.username) && regData.username, 'valid': validateUsername(regData.username) }">
                                        <input type="text" :placeholder="$t('username_validation')"
                                            v-model="regData.username" id="regName" name="regName" autocomplete="off">
                                        <div class="icon icon-right"></div>
                                    </div>
                                    <div class="input-bottom-text text md"
                                        v-if="!validateUsername(regData.username) && regData.username">
                                        <label class="error">{{ $t('username_validation') }}</label>
                                    </div>
                                </div>

                            </div>

                            <div class="input-area input-label-left mb-16">
                                <div class="input-label" id="registerPopup-password">{{ $t('password') }}</div>
                                <div class="input-wrapper">
                                    <div class="input has-two-icons-right"
                                        :class="{ 'error': !validatePassword(regData.password) && regData.password, 'valid': validatePassword(regData.password) }">
                                        <input :type="showPassword ? 'text' : 'password'" autocomplete="new-password"
                                            :placeholder="$t('password_validation')" id="regPassword" name="regPassword"
                                            v-model="regData.password">
                                        <div class="icon icon-right"></div>
                                        <div :class="showPassword ? 'icon icon-right icon-eye-show toggled' : 'icon icon-right icon-eye-show'"
                                            class="pw-toggle pointer" @click="showPassword = !showPassword"></div>
                                    </div>
                                    <div class="input-bottom-text text md"
                                        v-if="!validatePassword(regData.password) && regData.password">
                                        <label class="error">{{ $t('password_validation') }}</label>
                                    </div>
                                </div>

                            </div>

                            <div class="input-area input-label-left mb-16">
                                <div class="input-label" id="registerPopup-email">{{ $t('email') }}</div>
                                <div class="input-wrapper">
                                    <div class="input"
                                        :class="{ 'error': !validateEmail(regData.email) && regData.email, 'valid': validateEmail(regData.email) }">
                                        <input type="email" class="EmailValidation" placeholder="Example@gmail.com"
                                            v-model="regData.email">
                                        <div class="icon icon-right"></div>
                                    </div>
                                    <div class="input-bottom-text text md"
                                        v-if="!validateEmail(regData.email) && regData.email">
                                        <label class="error" for="UserName">{{ $t('invalid_email') }}</label>
                                    </div>
                                </div>

                            </div>

                            <div class="mobile-number-wrapper mb-16 mt-16 flex-column">
                                <div class="d-flex flex-row">
                                    <div class="input-area mr-8 input-label-left input-phone-country">
                                        <div class="input-label select-box-label" id="registerPopup-mobilephone">{{
                                            $t('mobile') }}</div>
                                        <div class="input-wrapper mobile-number-input">

                                            <CountryCallingCodeSelection
                                                @country-calling-code-update="handleCountryCallingCodeUpdate" />

                                            <div class="input-bottom-text"></div>
                                        </div>
                                        <input name="PhonePrefix" value="" hidden="" type="text" data-val="true"
                                            data-val-required="Required field." id="PhonePrefix" aria-invalid="false">
                                    </div>

                                    <div class="input-area input-phone-number">
                                        <div class="input">
                                            <input type="text" placeholder="Type number here" id="MobilePhone"
                                                maxlength="12" name="MobilePhone" value="" v-model="regData.contactNumber">
                                            <div class="icon icon-right"></div>
                                        </div>
                                    </div>
                                </div>

                                <div class="phone-prefix-validation text md d-flex flex-row">
                                    <div class="input-label-spacing mr-16"></div>
                                    <label id="PhonePrefix-error" class="error" for="PhonePrefix"></label>
                                </div>

                                <div class="phone-number-validation text md d-flex flex-row">
                                    <div class="input-label-spacing mr-16">

                                    </div>
                                </div>
                            </div>
                            <div class="input-area input-label-left mb-16">
                                <div class="input-label" id="registerPopup-name"> {{ $t('affiliate') }}</div>
                                <div class="input-wrapper">
                                    <div class="input">
                                        <input type="text" disabled :placeholder="$t('how_address_u')" name="regReferral"
                                            :value="globalState.user.referralCode">
                                        <div class="icon icon-right"></div>
                                    </div>
                                    <div class="input-bottom-text text md"></div>

                                </div>
                            </div>

                            <div class="input-bottom-text text md"> <label style="  padding: 0 20px;
    color: var(--system-5);" class="error">{{ registerError }}</label>
                            </div>
                            <div class="text md register-terms-link mt-16">
                                <span>By registering, you agree to 365Won's&nbsp;</span>
                                <span class="text md underline" style="cursor: pointer">Terms
                                    of Use</span>
                                &nbsp;
                                <span>and&nbsp;</span>
                                <span class="text md underline" style="cursor: pointer">Privacy
                                    Policy.</span>
                                &nbsp;
                            </div>

                            <div class="register-form-buttons">
                                <div class="message-area"></div>
                                <div class="buttons-wrapper">
                                    <button class="button lg button-primary" type="button" @click="register"
                                        id="register-button">
                                        <div class="button-loading-icon left d-none">
                                            <span class="loader">
                                                <span class="left"></span>
                                                <span class="right"></span></span>
                                        </div>{{ $t('register') }}
                                    </button>
                                    <button type="button" class="button lg button-inner-light"
                                        id="registration-cancel-button" @click="closePopup">{{ $t('cancel')
                                        }}</button>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>


        </div>
    </div>
</template>
<script setup>
import { inject, ref, reactive, watch, computed } from 'vue';
import { notificationSymbol } from '../tools/symbol';
import LanguageSelection from '../components/LanguageSelection.vue';
import CountryCallingCodeSelection from '../components/DropdownCollection/CountryCallingCodeSelection.vue';
import apiServices from '../services/apiServices';
import axios from 'axios';

import { globalState } from '../store/globalState';
import {eventBus} from '../services/eventBus';



const notify = inject(notificationSymbol);
const showPassword = ref(false);
const regData = reactive({
    name: '',
    countryCallingCodeId: 0,
    contactNumber: '',
    email: '',
    dateOfBirth: '',
    membershipId: 0,
    password: '',
    username: '',
    referrerReferralCode: '',
});

const passwordError = ref('');
const emailError = ref('');
const usernameError = ref('');
const registerError = ref('');

const validateEmail = (email) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
};

const validatePassword = (password) => {
    return password.length >= 6;
};

// Watch for changes in the username and remove spaces
watch(() => regData.username, (newValue) => {
  // Remove spaces from the username
  regData.username = newValue.replace(/\s+/g, '');
});

const validateUsername = (username) => {
    return username.length >= 4;
};

const isFormValid = computed(() => {
    return (
        validateEmail(regData.email) &&
        validatePassword(regData.password) &&
        validateUsername(regData.username)
    );
});

const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
        const context = this;
        const args = arguments;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func.apply(context, args), delay);
    }
};

const register = debounce(async () => {
    if (!isFormValid.value) {
        if (!validateEmail(regData.email)) {
            emailError.value = 'Invalid email address';
        }
        if (!validatePassword(regData.password)) {
            passwordError.value = 'Password must be at least 6 characters';
        }
        if (!validateUsername(regData.username)) {
            usernameError.value = 'Username must be at least 4 characters';
        }
        return;
    }

    emailError.value = '';
    passwordError.value = '';
    usernameError.value = '';

    // const ipResponse = await apiServices.getIPAddress();
    // const ip = ipResponse;
    const ipResponse = await axios.get('https://ipinfo.io/ip');
          const ip = ipResponse.data.trim();
    const domain = window.location.hostname;
    const userData = {
        name: regData.name,
        countryCallingCodeId: regData.countryCallingCodeId,
        contactNumber: regData.contactNumber,
        email: regData.email,
        dateOfBirth: '',
        membershipId: 0,
        password: regData.password,
        isOptForMarketing: false,
        username: regData.username,
        isCreatedByBackoffice: false,
        isCreateForOtherPlayer: true,
        referrerReferralCode: globalState.user.referralCode,
        registrationIp: ip,
        registrationDomain: domain
    };
    try {
        const response = await apiServices.createMember(userData);
        if (response.status === 200) {
            notify('register_success', 'success');
            eventBus.emit('refreshAffMember');
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
            closePopup();
        } else {
            console.error('error:', response);
        }
    }

    catch (error) {
        registerError.value = error.response.data.message
        notify('register_failed', 'error');
    }

}, 1000);


const handleOverlayClick = (event) => {
    if (event.target.classList.contains('modal-overlay')) {
        closePopup();
    }
};

const closePopup = () => {
    globalState.isAffRegShow = false;
};


const handleCountryCallingCodeUpdate = (countryCallingCodeId) => {
    regData.countryCallingCodeId = countryCallingCodeId;
};


watch(() => regData.email, (newVal) => {
    if (validateEmail(newVal)) {
        emailError.value = '';
    }
});

watch(() => regData.password, (newVal) => {
    if (validatePassword(newVal)) {
        passwordError.value = '';
    }
});

watch(() => regData.username, (newVal) => {
    if (validateUsername(newVal)) {
        usernameError.value = '';
    }
});
</script>


<style>
html[data-theme="default"] .rebate-text {
    color: var(--primary-mode-1) !important;
}

html[data-theme="dark"] .rebate-text {
    color: var(--accordion-mode-1) !important;
}

/* custom css for register banner */
@media(min-width: 767.5px) {
    .modal-overlay.modal-register .modal-content .right-register-content .promotions-area-mobile .tile .info-box {
        display: grid !important;
        padding-top: 15px;
    }
}

@media (min-width: 767.5px) {
    .modal-overlay.modal-register .modal-content .right-register-content .register-form-wrapper #register-form .register-form-buttons .buttons-wrapper {
        flex-direction: unset !important;
    }
}
</style>