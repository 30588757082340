<template>
    <div class="secondary-menu">
        <div class="secondary-menu-content">
            <time id="info-line-clock" class="time" :datetime="new Date().toISOString()">
                {{ gmt8Time }}
            </time>
            <div class="news">
                <div class="news-content-wrapper">
                    <div id="info-line-news-slider" class="news-content"
                        style="animation: 30s linear 0s infinite normal none running marquee;">
                        <span class="news-item pointer">{{ $t('slidding_announcement') }}</span>
                    </div>
                </div>
            </div>

            <form id="info-line-form" method="get" style="display: none;" action="/Common/GetNews"></form>
            <div class="side-items">


                <div id="raf-secondary-menu-text" class="item track-events require-authentication" data-tracking-name="RAF"
                    data-url="/referafriend" @click="referFriendNavigate">



                    <div id="raf-secondary-menu-mask" class="mask"
                        style="mask: url('/images/icon/icon-raf.svg') no-repeat center; mask-size: 12px; width: 12px; height: 12px">
                    </div>


                    <span class="bold">
                       {{ $t('refer_friend') }}
                    </span>

                </div>




                <div class="lang-selector ffhover loaded">
                    <LanguageSelection />
                </div>


            </div>
        </div>
    </div>
</template>

<script>
import { inject } from 'vue';
import LanguageSelection from './LanguageSelection.vue'
import { globalState } from '../store/globalState';
import redirectTo from '../services/generalFunction';
import { notificationSymbol } from '../tools/symbol';

export default {
    data() {
        return {
            gmt8Time: '',
            notify: null,
        };
    },
    components: {
        LanguageSelection
    },
    methods: {
        updateGMT8Time() {
            this.gmt8Time = this.calculateTimeInGMT8();
        },
        calculateTimeInGMT8() {
            const now = new Date();
            const utcOffset = 8 * 60;
            const gmt8Time = new Date(now.getTime() + utcOffset * 60000);

            const options = {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                timeZoneName: 'short',
            };

            const formattedTime = gmt8Time.toLocaleString(undefined, options);
            return formattedTime;
        },
        referFriendNavigate(){
            if (!globalState.isAuth) {
                this.notify("login_first", "warning")
                globalState.isLoginShow = true;
                return
            }else {
                redirectTo('/referafriend');
            }
        },
    },
    created() {
        setInterval(this.updateGMT8Time, 1000);
        this.notify = inject(notificationSymbol);
    },
};
</script>