<template>
    <div
        class="input-area d-flex flex-column mr-32-tablet mb-16 mb-0-tablet align-items-center align-items-flex-end-tablet">
        <div class="input d-flex flex-column flex-row-tablet has-two-icons-right">
            <div class="input-label input-label-long" id="transactionHistory-selectfrom">{{ this.$t('select_date_from') }}:
            </div>
            <div class="input-wrapper">
                <div class="input input-date-start "
                    :class="validations.startDate.isValid ? 'valid' : (isInitial ? '' : 'error')">
                    <input v-model="startDate" type="date" @blur="validateStartDate">
                    <div class="icons-wrapper">
                        <span class="icon icon-right icon-right-two"></span>
                        <span class="icon icon-right icon-calendar"></span>
                    </div>
                </div>
                <div class="input-bottom-text">
                    <label class="error">{{ (validations.startDate.message ? this.$t(validations.startDate.message) : '') }}</label>


                </div>
            </div>
        </div>
    </div>
    <div class="input-area d-flex flex-column mb-16 mb-0-tablet align-items-center align-items-flex-end-tablet">
        <div class="input d-flex flex-column flex-row-tablet has-two-icons-right">
            <div class="input-label" id="transactionHistory-selectto">{{ this.$t('to') }}:</div>
            <div class="input-wrapper">
                <div class="input input-date-end"
                    :class="validations.endDate.isValid ? 'valid' : (isInitial ? '' : 'error')">
                    <input v-model="endDate" type="date" @blur="validateEndDate">
                    <div class="icons-wrapper">
                        <span class="icon icon-right icon-right-two"></span>
                        <span class="icon icon-right icon-calendar"></span>
                    </div>
                </div>
                <div class="input-bottom-text">

                    <label class="error">{{ (validations.endDate.message ?this.$t(validations.endDate.message) : '') }}</label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isInitial: true,
            startDate: '',
            endDate: '',
            validations: {
                startDate: {
                    isValid: false,
                    message: ''
                },
                endDate: {
                    isValid: false,
                    message: ''
                }
            }
        }
    },
    emits: ['dateRangeChanged'],
    methods: {
        validateStartDate() {
            this.isInitial = false;
            this.validations.startDate.isValid = true;
            this.validations.startDate.message = '';
            this.emitDateRange();
        },
        validateEndDate() {

            this.isInitial = false;
            if (this.startDate >= this.endDate) {
                this.validations.endDate.isValid = false;
                this.validations.endDate.message = 'date_to_invalid';
            } else {
                this.validations.endDate.isValid = true;
                this.validations.endDate.message = '';
                this.emitDateRange();
            }
        },
        emitDateRange() {
            this.$emit('dateRangeChanged', { startDate: this.startDate, endDate: this.endDate });
        }
    }
};
</script>

<style scoped>
.input-area .input .icon.icon-calendar {
    background-color: unset !important;
}

body .input-area {
    justify-content: unset;
}

.input-area .input-bottom-text label.error {
    white-space: nowrap;
}
</style>