export default {
  "login": "登录",
  "logout": "登出",
  "register": "注册",
  "are_u_member": "你是会员吗？",
  "home": "主页",
  "sports": "体育",
  "livecasino": "线上赌场",
  "casino": "赌场",
  "kenolotto": "基诺和乐透",
  "promotions": "优惠",
  "vip": "贵宾",
  "darkmode": "深色模式",
  "lightmode": "浅色模式",
  "username": "用户名",
  "password": "密码",
  "input_username": "输入用户名",
  "input_password": "输入密码",
  "keep_login": "保持登录状态",
  "create_new_account": "注册新用户",
  "forgot_login_details": "忘记登录详情？",
  "not_yet_member": "还不是会员？",
  "register_to": "注册",
  "reg_msg_1": "请注意，我们不允许会员拥有超过一 (1) 个帐户。",
  "already_have_account": "已经有帐户？",
  "login_here": "在此登入！",
  "name": "姓名",
  "how_address_u": "怎么称呼你？",
  "username_validation": "长度为 3-16 个字母数字字符",
  "password_validation": "长度为 6-30 个字符",
  "email": "电子邮件",
  "cancel": "取消",
  "update_account_details": "更新您的帐户详细信息",
  "bank_account_holder_name": "银行帐户持有人姓名",
  "add_bank_account": "新增银行帐户",
  "bank_name": "银行名",
  "bank_account_number": "银行帐号",
  "add_bank_verification_msg": "透过点击“储存帐户”，我确认所提供的银行帐户资讯是正确的。",
  "save_account": "储存帐户",
  "total_balances": "总余额",
  "date_time": "时间",
  "account_profile": "帐户资料",
  "update_bank_details": "更新银行详细信息",
  "cashier": "交易",
  "profile": "简介",
  "history": "历史",
  "deposit": "存款",
  "withdrawal": "提款",
  "slidding_announcement": "365WON反波胆体育赛事平台正式上线！投资有道，财富无限！引领体育竞彩新未来",
  "refer_friend": "推荐朋友",
  "transfer": "转入",
  "statement": "报表",
  "match_result": "比赛结果",
  "bet_record": "投注记录",
  "transaction_history": "交易记录",
  "running_bets": "进行中投注",
  "no_results": "未找到结果",
  "latest_statement": "最新报表",
  "total_bet_amount": "总投注金额",
  "total_earned_profit": "利润总额",
  "transaction_type": "交易类型",
  "all": "全部",
  "groupcommission": "集团佣金",
  "profitfrombet": "投注获利",
  "adjustment": "调整",
  "voidwithdrawal": "无效提款",
  "bet": "赌注",
  "voidbet": "无效赌注",
  "referralhold": "推荐保证金",
  "referralreturn": "推荐回报",
  "firstbethold": "首注保留",
  "firstbetreturn": "首注返还",
  "pending": "待办的",
  "processing": "处理中",
  "approved": "批准",
  "rejected": "驳回",
  "transaction_status": "交易状态",
  "select_date_from": "选择日期",
  "to": "到",
  "date_to_invalid": "起始日期必须早于终止日期。",
  "date": "日期",
  "transaction_id": "交易ID",
  "transaction_date": "交易日期",
  "kick_off_date": "开球时间",
  "match": "比赛",
  "score": "分数",
  "amount": "数额",
  "profit": "利润",
  "projected_profit": "预计利润",
  "status": "状态",
  "submit": "提交",
  "odds": "赔率",
  "personal_details": "个人资料",
  "nickname": "昵称",
  "date_of_birth": "出生日期",
  "date_of_birth_msg": "提供您的生日是可选的，但建议出于促销目的",
  "mobile": "手机号码",
  "change_my_password": "修改我的密码",
  "old_password": "旧密码",
  "enter_ur_old_password": "输入您的旧密码",
  "new_password": "新密码",
  "currency_code": "USD",
  "withdrawable": "可提款",
  "hey": "你好",
  "ur_membership_level_is": "您的会员等级是",
  "method": "方法",
  "type": "种类",
  "deposit_step_2": "选择存入365 Won 的银行帐户",
  "deposit_step_3": "转帐完成后，请填写以下表格",
  "deposit_step_1": "选择要存入的付款方式",
  "bank_account": "银行帐户持有人姓名",
  "bank": "银行",
  "recent_transaction": "最近交易",
  "upload": "上传",
  "receipt": "收据",
  "deposit_submittted": "存款已提交",
  "file_upload_validation": "文件大小不得超过 5MB",
  "copied_to_clipboard": "已复制到剪贴簿！",
  "failed_to_copy": "无法复制到剪贴簿。",
  "withdrawal_submittted": "提款已提交",
  "withdrawal_step_1": "提款至您指定的银行",
  "transfer_step_1": "转给你的朋友",
  "player_not_found": "玩家不存在！",
  "transfer_submitted": "转帐已提交",
  "register_success": "注册已完成！",
  "invalid_email": "无效的邮件地址",
  "register_failed": "注册失败 ！",
  "login_failed": "登入凭证无效",
  "login_success": "登入成功！",
  "confirm_password": "确认密码",
  "password_validate_failed": "密码验证失败",
  "update_member_success": "更新会员资讯成功",
  "confirm_match_new": "确认密码必须与新密码匹配",
  "update_member_failed": "更新会员资讯失败",
  "session_expired": "已过期。请重新登入。",
  "update_player_bank_success": "银行帐户已更新。",
  "create_player_bank_success": "银行帐户已建立。",
  "correct_score": "波胆",
  "bet_now": "立即投注",
  "see_all": "看全部",
  "quick_easy": "又快又简单",
  "join_now": "加入 365 Won",
  "fast_deposit_time": "以最快的存款时间确保安全",
  "start_ur_journey": "现在就开始你的致胜之旅吧！",
  "play": "玩",
  "all_market": "所有市场",
  "ft_correct_score": "FT正确分数",
  "ht_correct_score": "HT正确分数",
  "available_volume": "可用量",
  "show_correct_score": "显示波胆",
  "total_volume": "总成交量",
  "hot": "热门",
  "login_first": "请先登入",
  "affiliate": "推荐人",
  "about_us": "关于我们",
  "commission": "佣金",
  "other_options": "其他选项",
  "bet_amount": "投注金额",
  "profit_amount": "利润金额",
  "u_are_betting": "你正在投注",
  "bet_success": "投注成功",
  "commission_transaction": "佣金交易",
  "aff_login_id": "会员登入 ID",
  "comm_type": "佣金类型",
  "commission_rate": "佣金率",
  "total_commission_earned": "赚取的佣金总额",
  "affiiate_list": "会员列表",
  "member_id": "会员ID",
  "fullname": "全名",
  "membership_level": "会员等级",
  "reg_date": "注册日期",
  "direct_affiliate_list": "直接附属机构列表",
  "total_num_of_aff_grp": "附属团体总数",
  "total_num_of_direct_aff": "直接附属公司总数",
  "new_affiliate": "新会员",
  "total_group_size": "团体总规模",
  "full_time": "全场",
  "half_time": "半场",
  "jackpot_amount": "10000",
  "deposit_amount": "存款金額",
  "deposit_currency": "存款貨幣",
  "withdrawal_amount": "提款金額",
  "withdrawal_currency": "提款貨幣",
  "referral_link_copied": "推荐链接已复制！",
  "invite_via_link": "通过推荐链接邀请您的朋友",
  "direct_affiliate": "直接附属机构",
  "save_photo": "保存到照片",
  "share_my_code": "分享我的代码",
  "qr_url_copied": "二维码网址已复制！",
  "referral": "推荐"
};