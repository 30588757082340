<template>
    <div class="content-box full-width border-box">
        <!-- <div class="d-flex card-container">
            <div class="mcard match-sched-card 9347714 market-T product-msports track-events">

                <div>
                    <span> {{ $t('total_bet_amount') }}</span>
                    <div class="total-amount">{{ formatNumber(totalBetAmount) }}</div>
                </div>
            </div>
            <div class="mcard match-sched-card 9347714 market-T product-msports track-events">

                <div>
                    <span> {{ $t('total_earned_profit') }}</span>
                    <div class="total-amount">{{ formatNumber(totalProfitAmount) }}</div>
                </div>
            </div>
        </div> -->
        <div class="rewards-cards">
            <div class="rewards-cards-content">

                <div class="card radius-left">
                    <div class="text sm title uppercase title-mobile-view" data-qa-type="message"
                        data-qa-context="LoyaltyCurrentVipLevel">
                        {{ $t('ur_membership_level_is') }}
                    </div>
                    <div class="text md uppercase bold text-mobile-view">
                        {{ globalState.user.membershipName }} </div>
                </div>

                <div class="card">
                    <div class="text sm title uppercase title-mobile-view" data-qa-type="message"
                        data-qa-context="LoyaltyTotalVIPPoints">
                        {{ $t('total_bet_amount') }}
                    </div>
                    <div id="vipPoints" class="text md uppercase bold text-mobile-view">
                        {{ $t('currency_code') }} &nbsp;{{ formatNumber(totalBetAmount) }}
                    </div>
                </div>

                <div class="card radius-right">
                    <div class="text sm title uppercase title-mobile-view" data-qa-type="message"
                        data-qa-context="LoyaltyRedeemableCashTitle">
                        {{ $t('total_earned_profit') }}
                    </div>

                    <div id="vipCashable" class="text md uppercase bold uppercase text-mobile-view">
                        {{ $t('currency_code') }} &nbsp;
                        {{ formatNumber(totalProfitAmount) }} </div>

                    <div>
                        <input type="hidden" id="redemption-rate-raw" value="13">
                    </div>
                </div>

            </div>
        </div>
        <div id="HistoryFundTransferPlaceholder"
            class="table-placeholder  align-items-center  mt-32 mr-0-desktop ml-0-desktop" style="overflow-x: auto;">
            <table class="responsive-table full-width ml-0 mr-0">
                <thead class="color-primary-1 background-color-other-1">
                    <tr>
                        <th class="table-header background-color-primary-3 color-other-1 bold p-12" colspan="10">
                            {{ $t('latest_statement') }}

                        </th>
                    </tr>

                </thead>
                <tbody>
                    <tr class="hide-on-mobiledynamic-table-row">
                        <td class="hide-on-mobile first-column"><strong class="p-12"> {{
                            $t('transaction_id') }}</strong></td>
                        <td class="hide-on-mobile"><strong class="p-12">{{ $t('kick_off_date') }}</strong></td>
                        <td class="hide-on-mobile"><strong class="p-12">{{ $t('match') }}</strong></td>
                        <td class="hide-on-mobile"><strong class="p-12">{{ $t('score') }}</strong></td>
                        <td class="hide-on-mobile"><strong class="p-12">{{ $t('amount') }}</strong></td>
                        <td class="hide-on-mobile"><strong class="p-12">{{ $t('profit') }}</strong></td>
                    </tr>

                    <tr class="dynamic-table-row" v-for="item in items" :key="item.id">
                        <td class="responsive-td-display auto-height d-flex d-none-desktop flex-column align-items-flex-start background-color-primary-11 justify-content-center p-16"
                            id="date-time-data" data-title="Date/Time">
                            <div class="responsive-columns d-flex flex-column full-width">
                                <div class="flex-row-one d-flex align-items-center justify-content-flex-start">
                                    <span class="light-text color-primary-5">{{ $t('kick_off_date') }}</span>
                                    <span class="light-text color-primary-5 hide-on-mobile">{{ $t('transaction_id')
                                    }}</span>

                                </div>
                                <div class="flex-row-one-data d-flex align-items-center justify-content-flex-start">
                                    <strong>{{ convertToTimeZoneAndFormat(item.eventAt)  }}</strong>
                                    <strong class="hide-on-mobile">{{ item.id }}</strong>

                                </div>
                                <div
                                    class="flex-row-on-mobile up-to-tablet align-items-center justify-content-flex-start mt-16">
                                    <span class="light-text color-primary-5">{{ $t('match') }}</span>
                                </div>
                                <div class="flex-row-on-mobile up-to-tablet align-items-center justify-content-flex-start">
                                    <strong  v-if="globalState.isChinese" >  {{ item.chineseHomeTeamName }} &nbsp; VS
                                        &nbsp;{{ item.chineseAwayTeamName }}</strong>

                                        <strong v-else  >  {{ item.homeTeamName }} &nbsp; VS
                                        &nbsp;{{ item.awayTeamName }}</strong>
                                </div>

                                <div class="flex-row-no-gap d-flex align-items-center justify-content-flex-start mt-16">
                                    <span class="light-text color-primary-5">{{ $t('score') }}</span>
                                    <span class="light-text color-primary-5">{{ $t('amount') }}</span>
                                    <span class="light-text color-primary-5">{{ $t('profit') }}</span>
                                </div>
                                <div class="flex-row-no-gap-data d-flex align-items-center justify-content-flex-start">
                                    <strong>{{ item.betScore }}</strong>
                                    <strong> {{ $t('currency_code') }} &nbsp;{{ formatNumber(item.amount) }}</strong>
                                    <strong>
                                        {{ $t('currency_code') }}&nbsp; {{ formatNumber(item.profitAmount) }}
                                    </strong>
                                </div>
                            </div>
                        </td>



                        <td class="hide-on-mobile mobile-details" :data-title="$t('transaction_id')">
                            <div class="history-datetime-transactionId-td m-12 number">{{ item.id }}</div>
                        </td>
                        <td class="hide-on-mobile  hide-in-details" id="date-time-data" data-title="Date/Time">
                            <div class="m-12"><span class="date"><strong>{{ convertToTimeZoneAndFormat(item.eventAt) }}</strong></span>
                            </div>
                        </td>

                        <td class="hide-on-mobile mobile-details" data-title="Match">
                            <div  v-if="globalState.isChinese" class="history-datetime-transactionId-td m-12 ">
                                        {{ item.chineseHomeTeamName }} &nbsp; VS
                                        &nbsp;{{ item.chineseAwayTeamName }}
                                    </div>

                                    <div v-else class="history-datetime-transactionId-td m-12 ">
                                        {{ item.homeTeamName }} &nbsp; VS
                                        &nbsp;{{ item.awayTeamName }}
                                    </div>
                        </td>
                        <td class="hide-on-mobile mobile-details" data-title="Score">
                            <div class="history-datetime-transactionId-td m-12 number">{{ item.betScore }} 
                            </div>
                        </td>
                        <td class="hide-on-mobile mobile-details" data-title="Amount">
                            <div class="history-datetime-transactionId-td m-12 number"> {{ $t('currency_code') }} &nbsp;{{ formatNumber(item.amount) }}
                            </div>
                        </td>

                        <td class="hide-on-mobile mobile-details" data-title="Profit">
                            <div class="history-datetime-transactionId-td m-12 number">{{ $t('currency_code') }}&nbsp; {{ formatNumber(item.profitAmount) }} 
                            </div>
                        </td>
                    </tr>


                    <tr v-if="items.length === 0">
                        <td data-title="No records" colspan="107" class="text-align-center display-reverted">
                            {{ $t('no_results') }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>
</template>
<script setup>
import {  onMounted, ref, onUnmounted } from 'vue';
import { globalState } from '../store/globalState';
import { eventBus } from '../services/eventBus';
import '../assets/css/page/fundtransfer.css';
import apiServices from '../services/apiServices';
import { EventStatusEnum } from '../enum/eventStatus';
import { convertToTimeZoneAndFormat, formatNumber } from '../services/generalFunction';

const totalBetAmount = ref(0);
const totalProfitAmount = ref(0);
const items = ref([]);

const animateNumber = (finalNumber, duration, targetProperty) => {
    let currentNumber = 0;
    const stepTime = 50;
    const totalSteps = duration / stepTime;
    const increment = finalNumber / totalSteps;
    const interval = setInterval(() => {
        currentNumber += increment;
        if (currentNumber >= finalNumber) {
            clearInterval(interval);
            targetProperty.value = finalNumber;
        } else {
            targetProperty.value = Math.round(currentNumber);
        }
    }, stepTime);
};

const calculateTotalBetAmount = () => {
    return items.value.reduce((total, item) => total + parseFloat(item.amount), 0);
};

const calculateTotalEarnedProfit = () => {
    return items.value.reduce((total, item) => total + parseFloat(item.profitAmount), 0);
};



onMounted(async () => {
    try {

        getStatement();
        const handleLanguageChange = (newLang) => {
            console.log(`Language changed to: ${newLang}`);
            if (newLang === 'zh-CN' || newLang === 'zh-TW' || localStorage.getItem('lang') === 'zh-CN' || localStorage.getItem('lang') === 'zh-TW'){
                globalState.isChinese = true;
            } else {
                globalState.isChinese = false;
            }
        };

        eventBus.on('languageChanged', handleLanguageChange);

        // Clean up the event listener when the component is unmounted
        onUnmounted(() => {
            eventBus.off('languageChanged', handleLanguageChange);
        });
    } catch (error) {
        // Handle error case
        console.error(error);
    }
});
const getStatement = async () => {
    const params = {
        playerUsername: globalState.user.username,
        statusId: EventStatusEnum.Completed,
        sort:'eventAt',
        order:'desc'
    };

    const response = await apiServices.getMemberBets(params);
    items.value = response.data.playerBets;

    const totalBet = calculateTotalBetAmount();
    const totalProfit = calculateTotalEarnedProfit();

    animateNumber(totalBet, 1000, totalBetAmount);
    animateNumber(totalProfit, 1000, totalProfitAmount);
}
</script>
<style scoped>
.input-area .input .icon.icon-calendar {
    background-color: unset !important;
}

.content .content-wrapper .responsive-table tbody tr td .date,
.content .content-wrapper .responsive-table tbody tr td .number {
    white-space: nowrap;
}

.table-placeholder {
    display: block !important;
}

.content .content-wrapper .responsive-table tbody tr td {
    white-space: nowrap;
}

.match-sched-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 5px 20px;
    margin-bottom: 20px;
    text-align: center;
}

.match-sched-card span {
    margin: 0;
    font-size: 13px;
    float: left;
    padding-top: 5px;
    margin-left: -8px;
}

.total-amount {
    font-size: 26px;
    float: right;
    padding: 10px;
    margin-top: 1.4em;
}

.card-container {
    justify-content: flex-start;
    gap: 20px;
}

.content .content-wrapper .content-box,
::v-deep(.select-box__input-text) {
    background-color: var(--other-1)  !important; 
}

::v-deep(.select-box) {
    color: var(--primary-3)  !important; 
}
::v-deep(.input-area .input input, .input-area .input select),
::v-deep(.input-label),
.bank-method-tabber .active a span:before,
.bank-method-tabber a,
.profile-section-title {
    color: var(--primary-3)  !important; 
}
</style>